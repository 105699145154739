import React from "react";
import {
  Dimensions,
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
} from "react-native";
import * as MaterialCommunityIcons from "react-icons/md";
import * as GoIcons from "react-icons/go";
import { PrimaryText } from "./Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { ExportToCsv } from "export-to-csv";
import { COLORS } from "constants/Colors";

const { width, height } = Dimensions.get("window");

export function Header(props) {
  const {
    title,
    onBack,
    backText,
    onRight,
    rightText,
    rightDisabled,
    exportData,
    edit = false,
  } = props;

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    filename: `${title} ${new Date().toLocaleDateString()}`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);

  function exportCSV() {
    exportData && exportData?.length > 0 && csvExporter.generateCsv(exportData);
  }

  return (
    <View style={styles.container}>
      {onBack ? (
        <TouchableOpacity
          onPress={onBack}
          // style={{flex: 1, justifyContent:'center', paddingLeft: 4, opacity: rightDisabled ? 0.4 : 1}}
        >
          {backText ? (
            <Text style={{ fontSize: 20 }}>{backText}</Text>
          ) : (
            <View
              style={{
                backgroundColor: COLORS.primary.lightBlue,
                borderRadius: 4,
                justifyContent: "center",
                alignItems: "center",
                height: 30,
                width: 30,
              }}
            >
              <MaterialCommunityIcons.MdChevronLeft
                color={COLORS.primary.white}
                size={30}
              />
            </View>
          )}
        </TouchableOpacity>
      ) : (
        <View style={{ width: 40 }} />
      )}

      <View
        style={{
          borderBottomWidth: COLORS.primary.darkGray,
          borderBottomWidth: 0.7,
          paddingHorizontal: 10,
          paddingBottom: 4,
          flexShrink: 1,
        }}
      >
        <PrimaryText
          fontSize={16}
          fontWeight={700}
          style={{
            color: COLORS.primary.darkGray,
            textAlign: "center",
            flex: 1,
          }}
          numberOfLines={1}
        >
          {title}
        </PrimaryText>
      </View>

      {!!onRight ? (
        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity disabled={rightDisabled} onPress={onRight}>
            {rightText ? (
              <View
                style={{
                  backgroundColor:
                    rightText === "Delete"
                      ? COLORS.primary.error
                      : COLORS.primary.lightBlue,
                  borderRadius: 4,
                  paddingHorizontal: 6,
                  height: 30,
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  opacity: rightDisabled ? 0.4 : 1,
                }}
              >
                <PrimaryText
                  fontSize={16}
                  fontWeight={400}
                  style={{
                    color: COLORS.primary.white,
                  }}
                >
                  {rightText}
                </PrimaryText>
              </View>
            ) : (
              <View
                style={{
                  backgroundColor: COLORS.primary.red,
                  borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  height: 30,
                  width: 30,
                }}
              >
                <GoIcons.GoPencil
                  name="pencil"
                  color={COLORS.primary.white}
                  size={20}
                />
              </View>
            )}
          </TouchableOpacity>
          {edit && (
            <TouchableOpacity onPress={edit} style={{ marginLeft: 4 }}>
              <View
                style={{
                  backgroundColor: COLORS.primary.gray,
                  borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  height: 30,
                  width: 30,
                }}
              >
                <MaterialCommunityIcons.MdEdit
                  color={COLORS.primary.white}
                  size={22}
                />
              </View>
            </TouchableOpacity>
          )}
        </View>
      ) : (
        <View style={{ width: 40 }} />
      )}

      {exportData && (
        <TouchableOpacity
          // disabled={rightDisabled}
          onPress={() => exportCSV()}
          style={styles.exportMenu}
        >
          <FaIcons.FaFileDownload color={COLORS.primary.white} size={20} />
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 36,
    flexDirection: "row",
    backgroundColor: COLORS.primary.white,
    marginBottom: 12,
    alignItems: "center",
    justifyContent: "space-between",
  },
  exportMenu: {
    position: "absolute",
    right: 12,
    backgroundColor: COLORS.primary.blue,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    width: 30,
  },
});
