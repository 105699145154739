import { firestore } from "utils/firebase";

export const getSkills = async (setSkills) => {
    firestore
      .collection('skills')
      .orderBy('lowercaseName', 'asc')
      .get()
      .then((querySnapshot) => {
        let skills = [];
        querySnapshot.forEach((doc) => {
          skills.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setSkills(skills);
      })
      .catch((error) => {
        console.log('getSkills err', error);
      });
};

export const getOurSkills = (currentUser, allSkills, setOurSkills) => {
    let temp = [];

    currentUser.skills &&
    currentUser.skills.length &&
    currentUser.skills.map((x) => {
        let skill = allSkills.find((y) => y.id === x);
        skill && temp.push(skill);
    });
    
    setOurSkills(temp);
}