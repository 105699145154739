import { Box, IconButton } from "@mui/material";
import React from "react";
import { FiX } from "react-icons/fi";

export default function BlurBGModal({
  children,
  sx,
  onClose,
  pages,
  currentPage,
  ...props
}) {
  return (
    <Box
      sx={{
        flex: 1,
        width: "100%",
        position: "absolute",
        top: -20,
        borderRadius: "25px",
        paddingY: 20,
        backdropFilter: "blur(3px)",
        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
        bgcolor: "rgba(250, 250, 250, 0.9)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: 1,
        pt: "32px",
        ...sx,
      }}
      {...props}
    >
      {children}
      {onClose &&
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 24,
            right: 24,
          }}
        >
          <FiX />
        </IconButton>
      }

      <Pagination total={pages} active={currentPage} />
    </Box>
  );
}

const Pagination = ({ total, active }) => (
  <Box display="flex" alignItems="center" position="absolute" bottom={26}>
    {[...new Array(total).keys()].map((item) => (
      <Box
        bgcolor={active === item ? "neutral.600" : "neutral.350"}
        width={active === item ? 16 : 12}
        height={active === item ? 16 : 12}
        borderRadius={8}
        key={item}
        mx={8}
      />
    ))}
  </Box>
);
