import styled from "@emotion/styled";
import { Box, OutlinedInput } from "@mui/material";
import ErrorMessage from "components/Common/ErrorMessage";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";

const StyledOutlinedInput = styled(OutlinedInput, {
	shouldForwardProp: (prop) => prop !== "inputStyle"
})(({ multiline, title, inputStyle }) => ({
	height: multiline ? 102 : 30,
	fontSize: 14,
	color: COLORS.primary.darkBlue,
	borderRadius: 15,
	marginTop: title ? 20 : 0,
	backgroundColor: COLORS.primary.white,
	...inputStyle,
	"& > .MuiInputBase-input": {
		paddingTop: 0,
		paddingBottom: 0,
		height: 30
	}
}));

export default function MuiTextInput({
	label: title,
	placeholder,
	required,
	onChange,
	value,
	multiline,
	inputStyle = {},
	boxProps = {},
	rows = 4,
	labelFontSize = 16,
	labelFontWeight = 900,
	labelStyle = {},
	errorMessage,
	name
}) {
	return (
		<Box
			mt={20}
			{...boxProps}
		>
			{title && (
				<PrimaryText
					fontSize={labelFontSize}
					fontWeight={labelFontWeight}
					style={labelStyle}
				>
					{title}
					{required && <span style={{ color: COLORS.primary.error }}>*</span>}
				</PrimaryText>
			)}
			<StyledOutlinedInput
				fullWidth={true}
				onChange={onChange}
				{...{
					placeholder,
					required,
					value,
					multiline,
					rows,
					name,
					inputStyle,
					title
				}}
			/>

			<ErrorMessage errorMessage={errorMessage} />
		</Box>
	);
}
