import React, { useState, createContext, useEffect, useContext } from 'react';
import { getOurSkills, getSkills } from 'services/skills-service';
import { useAuth } from './AuthContext';

export const SkillsContext = createContext();

export const SkillsProvider = ({ children }) => {
  const authContext = useAuth();
  const { currentUser } = authContext;

  const [allSkills, setAllSkills] = useState([]);
  const [ourSkills, setOurSkills] = useState([]);

  useEffect(() => {
    getSkills(setAllSkills);
  }, []);

  useEffect(() => {
    if (currentUser && allSkills.length > 0) {
      getOurSkills(currentUser, allSkills, setOurSkills)
    }
  }, [currentUser, allSkills]);

  return (
    <SkillsContext.Provider
      value={{
        allSkills,
        setAllSkills,
        ourSkills,
        setOurSkills,
      }}
    >
      {children}
    </SkillsContext.Provider>
  );
};

export const useSkills = () => useContext(SkillsContext);
