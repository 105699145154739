import { Box } from "@mui/material";
import { CustomInput } from "components/Inputs/CustomInput";
import React, { useRef } from "react";
import { View } from "react-native-web";

export default function ColorPickerInput({ value, onChange }) {
	const pickerRef = useRef(null);

	// open color picker from text input
	const openColorPicker = () => {
		pickerRef.current.click();
	};

	return (
		<View
			style={{
				marginRight: 30,
				display: "flex",
				flexDirection: "row",
				alignItems: "center"
			}}
		>
			<Box
				width={45}
				height={45}
				borderRadius="5px"
				bgcolor={value}
				boxShadow="0 4px 10px 0 #00000010"
				mr={16}
			/>
			<CustomInput
				value={value}
				onFocus={openColorPicker}
				editable={false}
			/>

			<input
				ref={pickerRef}
				type="color"
				onChange={onChange}
				value={value}
				style={{
					width: 1,
					height: 1,
					overflow: "hidden",
					visibility: "hidden"
				}}
			/>
		</View>
	);
}
