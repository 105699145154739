import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Box } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { Calendar } from "assets/svgs";
import ErrorMessage from "components/Common/ErrorMessage";
import moment from "moment";

export default function MuiDatePicker({
	label,
	required,
	boxProps,
	value,
	onChange,
	errorMessage
}) {
	return (
		<Box
			display="flex"
			flexDirection="column"
			{...boxProps}
		>
			<PrimaryText
				fontSize={16}
				fontWeight={900}
				style={{ marginBottom: 12 }}
			>
				{label}
				{required && <span style={{ color: COLORS.primary.error }}>*</span>}
			</PrimaryText>

			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DatePicker
					format="MM/DD/YYYY"
					slotProps={{
						textField: {
							InputProps: {
								sx: {
									height: 30,
									borderRadius: "15px",
									fontSize: 14
								}
							}
						}
					}}
					slots={{
						openPickerIcon: () => <Calendar fill={COLORS.primary.darkBlue} />
					}}
					value={value && moment(value).isValid() ? moment(value) : null}
					onChange={(date) => onChange(moment(date).toDate())}
					disablePast
				/>
			</LocalizationProvider>

			<ErrorMessage errorMessage={errorMessage} />
		</Box>
	);
}
