import { Box } from "@mui/material";
import { COLORS } from "constants/Colors";
import React from "react";
import { ActivityIndicator } from "react-native-web";

export default function LoadingStateless() {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			padding={20}
			width="100%"
		>
			<ActivityIndicator
				size="large"
				color={COLORS.primary.lightBlue}
			/>
		</Box>
	);
}
