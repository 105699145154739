import React from "react";
import { View, StyleSheet } from "react-native";
import { Navigate, Route, Routes } from "react-router-dom";
import { Links } from "constants/Links";
import { InviteLanding } from "screens/Business/Invite/0-InviteLanding";
import { InvitePasscode } from "screens/Business/Invite/1-InvitePasscode";
import { InviteNewOrExistingAccount } from "screens/Business/Invite/2-InviteNewOrExistingAccount";
import InviteNewAccount from "screens/Business/Invite/3-InviteNewAccount";
import { InviteExistingAccount } from "screens/Business/Invite/4-InviteExistingAccount";
import { useWindowDimensions } from "react-native-web";

export default function InviteNavigator() {
  const { height } = useWindowDimensions();

  return (
    <View
      style={{
        width: "100%",
        height,
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 24,
        paddingTop: 12,
      }}
    >
      <Routes>
        <Route path={Links.BusinessInviteLanding} element={<InviteLanding />} />
        <Route path={Links.InvitePasscode} element={<InvitePasscode />} />
        <Route
          path={Links.InviteNewOrExistingAccount}
          element={<InviteNewOrExistingAccount />}
        />
        <Route path={Links.InviteNewAccount} element={<InviteNewAccount />} />
        <Route
          path={Links.InviteExistingAccount}
          element={<InviteExistingAccount />}
        />
        <Route
          path={"/*"}
          element={<Navigate replace to={Links.BusinessInviteLanding} />}
        />
      </Routes>
    </View>
  );
}

const styles = StyleSheet.create({});
