import React, { useEffect, useState } from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View, ActivityIndicator } from "react-native";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { useOrganization } from "context/OrganizationContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { CustomInput } from "components/Inputs/CustomInput";
import { Button } from "components/Common/Button";
import * as FiIcons from "react-icons/fi";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { BusinessCampaignCard } from "components/Cards/Business/BusinessCampaignCard";
import { useBusinessCampaigns } from "context/Business/BusinessCampaignsContext";
import { FullModal } from "components/Modals/FullModal";
import { CampaignInfoForm } from "components/Campaigns/CampaignInfoForm";
import {
	archiveCampaign,
	createCampaign
} from "services/businesses/business-campaign-service";
import { ConfirmModal } from "components/Modals/Confirmation";
import { sortRows } from "utils/sorting";
import MuiTablePagination from "components/Common/MuiTablePagination";
import StartingCampaings from "components/Modals/StartingCampaings";
import { useBusiness } from "context/Business/BusinessContext";

const options = [
	{ value: "active", label: "Active" },
	{ value: "archived", label: "Archived" }
];

export const BusinessCampaigns = () => {
	const navigate = useNavigate();
	const { ourCampaigns, selectedCampaign, setSelectedCampaign } =
		useBusinessCampaigns();
	const { paymentMethods } = useBusiness();
	const { selectedOrg } = useOrganization();

	const defaultDetails = {
		about: "",
		causes: [],
		endDate: null,
		goal: 5000,
		name: "",
		orgId: selectedOrg?.id,
		raised: 0,
		startDate: null,
		status: 0,
		maxMatchPerEmployee: "",
		matchPercentage: ""
	};

	const [search, setSearch] = useState("");
	const [selectedView, setSelectedView] = useState(options[0]?.value);
	const [filtered, setFiltered] = useState([]);

	const [showNewCampaign, setShowNewCampaign] = useState(false);
	const [createCampaignDisabled, setCreateCampaignDisabled] = useState(true);
	const [details, setDetails] = useState(defaultDetails);
	const [selectedCauses, setSelectedCauses] = useState([]);
	const [newCampaignCoverPhotos, setNewCampaignCoverPhotos] = useState([]);

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const [loading, setLoading] = useState(false);

	const [sorted, setSorted] = useState({
		sortby: "startDate",
		ascending: true
	});

	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);

	const [isStartingModal, setIsStartingModal] = useState(false);

	useEffect(() => {
		setIsStartingModal(
			!selectedOrg?.stripeCustomerId || paymentMethods?.length === 0
		);
	}, [selectedOrg?.stripeCustomerId, paymentMethods]);

	useEffect(() => {
		setCreateCampaignDisabled(
			!details.name ||
				!details.startDate ||
				!details.endDate ||
				!details.goal ||
				!details.about ||
				newCampaignCoverPhotos?.length < 1
		);
	}, [details, newCampaignCoverPhotos]);

	useEffect(() => {
		if (search) {
			setFiltered(
				ourCampaigns?.filter(
					(campaign) =>
						campaign.name.toLowerCase().includes(search.toLowerCase()) &&
						!!campaign.archived === (selectedView === "archived")
				)
			);
		} else {
			setFiltered(
				ourCampaigns?.filter(
					(campaign) => !!campaign.archived === (selectedView === "archived")
				)
			);
		}
	}, [search, ourCampaigns, selectedView]);

	function campaignSelected(campaign) {
		setSelectedCampaign(campaign);
		navigate(Links.BusinessCampaignDetails);
	}

	function saveNewCampaign() {
		setLoading(true);
		createCampaign(details, newCampaignCoverPhotos, onSuccessSave);
	}

	function onSuccessSave() {
		setShowNewCampaign(false);
		setLoading(false);
		setDetails(defaultDetails);
		setNewCampaignCoverPhotos([]);
	}

	function archive() {
		archiveCampaign(selectedCampaign.id, !selectedCampaign?.archived, () => {
			setShowConfirmModal(false);
			setSelectedCampaign(null);
		});
	}

	useEffect(() => {
		if (!sorted || !filtered?.length) return;

		const sortedRows = sortRows({
			tableRows: filtered,
			...sorted
		});
		setFiltered(sortedRows);
	}, [sorted, ourCampaigns]);

	const currentTableData = () => {
		const firstPageIndex = currentPage * rowsPerPage;
		const lastPageIndex = firstPageIndex + rowsPerPage;
		return filtered.slice(firstPageIndex, lastPageIndex);
	};

	if (loading) {
		return (
			<View
				style={[styles.container, { paddingTop: "80%", alignItems: "center" }]}
			>
				<ActivityIndicator
					animating={loading}
					size={"large"}
					style={styles.activity}
					color={COLORS.primary.blue}
				/>
			</View>
		);
	}

	return (
		<View style={{ flex: 1, width: "100%" }}>
			<ShadowContainer style={styles.container}>
				<PrimaryText
					fontSize={32}
					fontWeight={900}
					style={styles.title}
				>
					Campaigns
				</PrimaryText>
				<View
					style={{
						zIndex: 999,
						flexDirection: "row",
						width: "100%",
						alignItems: "center",
						justifyContent: "space-between",
						marginVertical: 12
					}}
				>
					<View
						style={{ flexDirection: "row", alignItems: "center", flexGrow: 1 }}
					>
						<CustomInput
							placeholder={"Search"}
							style={{ flexGrow: 1, maxWidth: 400 }}
							onChangeText={setSearch}
							value={search}
							backgroundColor={COLORS.primary.white}
						/>

						<PrimaryText
							fontSize={14}
							fontWeight={900}
							style={{
								color: COLORS.primary.darkGray,
								marginHorizontal: 4,
								marginLeft: 24
							}}
						>
							View
						</PrimaryText>
						<CustomPicker
							value={selectedView}
							onChange={(value) => setSelectedView(value)}
							options={options}
							style={{ maxWidth: 170, marginLeft: 3 }}
							backgroundColor={COLORS.primary.white}
						/>
					</View>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center"
						}}
					>
						<Button
							title={"Create New Campaign"}
							onPress={() => setShowNewCampaign(true)}
							iconLeft={
								<FiIcons.FiPlusCircle
									size={18}
									color={COLORS.primary.white}
									style={{ marginRight: 6 }}
								/>
							}
						/>
					</View>
				</View>
				<Table
					className="table"
					style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
				>
					<TableHead>
						<TableRow>
							{[
								{ label: "NAME", sortby: "name", align: "left" },
								{ label: "BACKERS", sortby: "backers" },
								{ label: "PROGRESS", sortby: "raised" },
								{ label: "DATES", sortby: "startDate" }
							].map(({ label, sortby, align }, index) => (
								<TableCell
									style={{ textAlign: align }}
									key={index}
								>
									<TableSortLabel
										active={sortby === sorted?.sortby}
										direction={sorted?.ascending ? "asc" : "desc"}
										onClick={() =>
											setSorted((prev) => ({
												sortby,
												ascending:
													prev?.sortby === sortby ? !prev?.ascending : true
											}))
										}
									>
										{label}
									</TableSortLabel>
								</TableCell>
							))}

							<TableCell>STATUS</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{currentTableData() &&
							currentTableData().map((el, i) => {
								return (
									<BusinessCampaignCard
										item={el}
										key={i}
										onPress={() => campaignSelected(el)}
										onArchive={() => {
											setSelectedCampaign(el);
											setShowConfirmModal(true);
										}}
									/>
								);
							})}
					</TableBody>
				</Table>

				<MuiTablePagination
					count={filtered?.length}
					rowsPerPage={rowsPerPage}
					page={currentPage}
					setCurrentPage={setCurrentPage}
					onRowsPerPageChange={(e) => {
						setRowsPerPage(e.target.value);
						setCurrentPage(0);
					}}
				/>
			</ShadowContainer>

			{/** New Campaign */}
			{showNewCampaign && (
				<FullModal
					title={"Create New Campaign"}
					setShowModal={setShowNewCampaign}
				>
					<CampaignInfoForm
						details={details}
						setDetails={setDetails}
						coverPhotos={newCampaignCoverPhotos}
						setCoverPhotos={setNewCampaignCoverPhotos}
						address={!details?.virtual}
						selectedCauses={selectedCauses}
						setSelectedCauses={setSelectedCauses}
					/>

					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							paddingHorizontal: 36
						}}
					>
						<Button
							title={"Cancel"}
							onPress={() => setShowNewCampaign(false)}
							style={{
								backgroundColor: COLORS.primary.offWhite,
								borderColor: COLORS.primary.darkGray,
								borderWidth: 1,
								text: {
									color: COLORS.primary.darkGray
								}
							}}
						/>
						<Button
							title={"Create Campaign"}
							onPress={saveNewCampaign}
							style={{
								opacity: createCampaignDisabled ? 0.5 : 1
							}}
							disabled={createCampaignDisabled}
						/>
					</View>
				</FullModal>
			)}

			{showConfirmModal && (
				<ConfirmModal
					title={`Are you sure you want to \n${
						selectedCampaign?.archived ? "unarchive" : "archive"
					} ${selectedCampaign?.name}`}
					// message={"Are you sure?"}
					onConfirm={() => archive()}
					onCancel={() => setShowConfirmModal(false)}
				/>
			)}

			<StartingCampaings
				open={isStartingModal}
				onPress={() => navigate(Links.GeneralSettings)}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	activity: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		bottom: 0,
		zIndex: 999
	},
	container: {
		// flex: 1,
		height: "100%",
		width: "100%"
	},
	headerContainer: {
		flexDirection: "row"
	},
	infoContainer: {
		backgroundColor: COLORS.primary.white,
		marginHorizontal: 12,
		borderRadius: 12,
		paddingHorizontal: 20,
		paddingVertical: 12,
		alignItems: "center",
		justifyContent: "center"
	},
	info: {
		alignItems: "center",
		justifyContent: "center",
		marginHorizontal: 24
	},
	title: {
		color: COLORS.primary.darkBlue
	},
	URL: {
		fontSize: 20,
		fontWeight: 800,
		color: COLORS.primary.lightBlue
	},

	//new campaign
	settingsRow: {
		// flexDirection: "row",
		paddingVertical: 8,
		paddingHorizontal: 20,
		alignItems: "flex-start",
		justifyContent: "space-between"
	}
});
