import { Box } from "@mui/material";
import { CheckCircle, Files } from "assets/svgs";
import SelectCauses from "components/CausesSkills/SelectCauses";
import ErrorMessage from "components/Common/ErrorMessage";
import MuiButton from "components/Common/MuiButton";
import MuiRadioGroup from "components/Common/MuiRadioGroup";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React, { useEffect } from "react";
import { getFileMetadata } from "services/events-service";

export default function CausesAndSkills({ details, handleChange, errors }) {
	useEffect(() => {
		const isFireStorageUrl =
			typeof details?.waiver === "string"
				? details?.waiver?.startsWith("https://firebasestorage.googleapis.com")
				: false;

		if (details?.uploadWaiver === "Yes" && isFireStorageUrl) {
			getFileMetadata(details?.waiver)
				.then((metadata) => {
					const waiver = {
						name: metadata.name,
						size: metadata.size,
						fileUrl: details?.waiver
					};

					handleChange("waiver", waiver);
				})
				.catch((err) => console.log(err));
		}
	}, [details?.waiver, details?.uploadWaiver, handleChange]);

	return (
		<Box px={40}>
			<PrimaryText
				fontSize={16}
				fontWeight={900}
			>
				Select Causes{" "}
				<PrimaryText fontWeight={400}>
					(Limit 5)<span style={{ color: COLORS.primary.error }}>*</span>
				</PrimaryText>
			</PrimaryText>

			<SelectCauses
				selectedCauses={details?.categories}
				onChange={(categories) => handleChange("categories", categories)}
				errorMessage={errors?.categories}
			/>

			<MuiRadioGroup
				label="Upload Waiver"
				options={["Yes", "No"]}
				value={details?.uploadWaiver}
				onChange={(e) => handleChange("uploadWaiver", e)}
			/>

			<Box
				display="flex"
				alignItems="center"
				gap={15}
			>
				<MuiButton
					title={
						details?.waiverType === "Default"
							? "Default Selected"
							: "Use Default"
					}
					variant="contained"
					disabled={details?.uploadWaiver !== "Yes"}
					onPress={() => handleChange("waiverType", "Default")}
					startIcon={
						details?.waiverType === "Default" && (
							<CheckCircle fill={COLORS.primary.white} />
						)
					}
					sx={{
						height: 30,
						fontSize: 14,
						fontWeight: 700
					}}
				/>

				<MuiButton
					title={details?.waiver ? "Uploaded" : "Use Custom"}
					variant="outlined"
					color="secondary"
					disabled={details?.uploadWaiver !== "Yes"}
					startIcon={
						details?.waiver && (
							<CheckCircle
								fill={COLORS.primary.darkBlue}
								opacity={details?.uploadWaiver !== "Yes" ? 0.2 : 1}
							/>
						)
					}
					sx={{
						height: 30,
						fontSize: 14,
						fontWeight: 700
					}}
					type="file"
					accept=".pdf"
					onChange={(file) => handleChange("waiver", file)}
				/>
			</Box>

			{console.log(details?.waiver)}

			{details?.uploadWaiver === "Yes" && details?.waiver && (
				<Box
					display="flex"
					alignItems="center"
					borderTop={`0.5px solid ${COLORS.primary.lightGray}`}
					pt={14}
					mt={19}
					width="fit-content"
				>
					<Files fill={COLORS.primary.darkBlue} />
					<PrimaryText
						fontSize={18}
						style={{ marginLeft: 20, marginRight: 30 }}
					>
						{details?.waiver?.name}
					</PrimaryText>
					<PrimaryText fontSize={12}>
						{Math.ceil(details?.waiver?.size / 1000)} kb
					</PrimaryText>
				</Box>
			)}

			<ErrorMessage
				errorMessage={
					details?.uploadWaiver === "Yes" && details?.waiver
						? errors.waiver
						: null
				}
				marginTop={20}
			/>
		</Box>
	);
}
