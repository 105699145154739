import React, { useState, createContext, useEffect, useContext } from "react";
import { getCauses, getOurCauses } from "services/causes-service";
import { useAuth } from "./AuthContext";

export const CausesContext = createContext();

export const CausesProvider = ({ children }) => {

  const authContext = useAuth()
  const {currentUser} = authContext

  const [allCauses, setAllCauses] = useState([])
  const [ourCauses, setOurCauses] = useState([])

  useEffect(() => {
    getCauses(setAllCauses)
  }, [])

  useEffect(() => {
    if (currentUser && allCauses.length>0) {
      getOurCauses(currentUser, allCauses, setOurCauses)
    }
  }, [currentUser, allCauses])

  return (
    <CausesContext.Provider value={{ 
      allCauses, setAllCauses,
      ourCauses, setOurCauses
      }}
    >
      {children}
    </CausesContext.Provider>
  );
};

export const useCauses = () => useContext(CausesContext);
