import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";

export function Tabs(props) {
  const { tabs, activeTab, setActiveTab } = props;
  return (
    <View style={styles.tabs}>
      {tabs.map((t, i) => {
        return (
          <TouchableOpacity
            key={i}
            style={[styles.tabsButton, t === activeTab && styles.tabActive]}
            onPress={() => setActiveTab(t)}
          >
            <PrimaryText
              fontSize={14}
              fontWeight={700}
              style={[styles.tabText, t === activeTab && styles.tabTextActive]}
            >
              {t}
            </PrimaryText>
          </TouchableOpacity>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  tabs: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  tabsButton: {
    width: 220,
    height: 36,
    backgroundColor: COLORS.primary.offWhite,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    marginRight: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  tabActive: {
    backgroundColor: COLORS.primary.darkBlue,
  },
  tabText: {
    textAlign: "center",
    color: COLORS.primary.darkBlue,
    fontWeight: "400",
    fontSize: 13,
  },
  tabTextActive: {
    color: COLORS.primary.white,
    fontWeight: "800",
  },
});
