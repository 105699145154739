import React from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { Modal } from "@mui/material";

export function ConfirmModal2({
	title,
	message,
	onConfirm,
	onConfirmText,
	onCancel,
	open
}) {
	return (
		<>
			<Modal
				open={open}
				onClose={onCancel}
			>
				<View style={styles.container}>
					<View style={styles.body}>
						<PrimaryText
							fontSize={24}
							fontWeight={800}
							style={{ color: COLORS.primary.darkGray, textAlign: "center", marginBottom: 24 }}
						>
							{title}
						</PrimaryText>
						{message && (
							<PrimaryText
								fontSize={14}
								fontWeight={400}
								style={{
									color: COLORS.primary.darkGray,
									marginBottom: 24,
									textAlign: "center",
								}}
							>
								{message}
							</PrimaryText>
						)}

						<View style={styles.buttonContainer}>
							{onCancel && (
								<TouchableOpacity style={styles.cancelButton} onPress={onCancel}>
									<PrimaryText
										fontSize={14}
										fontWeight={400}
										style={{ color: COLORS.primary.darkGray, textAlign: "center" }}
									>
										{"No"}
									</PrimaryText>
								</TouchableOpacity>
							)}

							<TouchableOpacity style={styles.deleteButton} onPress={onConfirm}>
								<PrimaryText
									fontSize={14}
									fontWeight={400}
									style={{ color: COLORS.primary.white, textAlign: "center" }}
								>
									{onConfirmText ? onConfirmText : "Yes"}
								</PrimaryText>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		position: "absolute",
		zIndex: 999,
		height: "100%",
		width: "100%",
		backgroundColor: "rgba(255,255,255,0.7)",
		alignItems: "center",
		justifyContent: "center",
	},
	body: {
		backgroundColor: COLORS.primary.white,
		// height: 300, width: 300,
		alignItems: "center",
		padding: 24,
		borderRadius: 15,

		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "space-around",
	},
	cancelButton: {
		paddingVertical: 12,
		marginHorizontal: 12,
		width: 100,
		backgroundColor: COLORS.primary.lightGray,
		borderRadius: 6,
	},
	deleteButton: {
		paddingVertical: 12,
		marginHorizontal: 12,
		width: 100,
		backgroundColor: COLORS.primary.error,
		borderRadius: 6,
	},
});
