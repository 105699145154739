import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { StyleSheet, View } from "react-native-web";
import { TOP_CAUSE_COLORS } from "constants/Data";

export const BusinessTopCausesCard = ({ item, index, name }) => {
  return (
    <View style={styles.card}>
      <PrimaryText
        fontSize={14}
        fontWeight={400}
        numberOfLines={1}
        style={{ flex: 1 }}
      >
        {name}
      </PrimaryText>
      <View style={{ flexDirection: "row", alignItems: "center ", width: '100%' }}>
        <View style={styles.progressBar}>
          {/* <div style={{background: "linear-gradient(270deg, #EE1D52 0%, #F55CA6 100%)", height: 20}} ></div> */}
          <View style={[styles.progress, {background: TOP_CAUSE_COLORS[index], width: `${Math.round(item?.percent)}%`}]} />
        </View>
        <PrimaryText
          fontSize={14}
          fontWeight={700}
          // style={{width: 30}}
        >
          {Math.round(item?.percent)}%
        </PrimaryText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    alignItems: "flex-start",
    marginVertical: 10,
    paddingLeft: 10
  },
  progressBar: {
    flex: 1,
    marginRight: 8,
    marginTop: 4,
    height: 20,
    borderRadius: 100,
    overflow: 'hidden'
  },
  progress: {
    height: '100%',
    borderRadius: 100,
  }
});
