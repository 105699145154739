import "react-tooltip/dist/react-tooltip.css";
import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { TouchableOpacity, View } from "react-native-web";
import MuiButton from "components/Common/MuiButton";
import MuiRadio from "components/Common/MuiRadio";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { FiEdit, FiInfo } from "react-icons/fi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Box } from "@mui/material";
import ErrorMessage from "components/Common/ErrorMessage";
import ColorPickerInput from "components/Inputs/ColorPickerInput";

export function QnAEdit({
	question,
	property,
	answer,
	onEdit,
	caption,
	onSave,
	onCancel,
	type,
	value,
	number,
	placeholder,
	options,
	editing,
	disabled = false,
	disabledText = "",
	campaign,
	required,
	maxWidth,
	errorMessage
}) {
	editing = editing === property;

	const [tempValue, setTempValue] = React.useState(
		value !== undefined ? value : answer
	);

	function onChange(e) {
		let value = e.target.value;

		console.log(value);

		if (number) {
			let trimmed = value.replace(/\D/g, "");
			if (property === "matchPercentage") {
				trimmed = trimmed.slice(0, 3);
				if (Number(trimmed) > 100) {
					trimmed = 100;
				}
			}

			setTempValue(Number(trimmed));
			return;
		}
		if (property === "ein") {
			let trimmed = value.length > 10 ? value.substring(0, 10) : value;
			if (trimmed?.length > 2) {
				trimmed = trimmed.replace("-", "");
				trimmed = trimmed.substring(0, 2) + "-" + trimmed.substring(2, 10);
			}

			return setTempValue(trimmed.toUpperCase());
		}
		if (property === "phone") {
			// console.log('phone', value)
			let trimmed = value.replace(/[^0-9+]/g, "");
			if (trimmed.length > 10) {
				trimmed = trimmed.substring(0, 10);
			}
			return setTempValue(trimmed);
		}
		setTempValue(value);
	}
	function onChangePicker(e) {
		setTempValue(e);
	}

	return (
		<>
			{!editing ? (
				<View
					style={{ marginBottom: 16, opacity: disabled ? 0.4 : 1, maxWidth }}
				>
					<PrimaryText
						fontSize={campaign ? 20 : 17}
						fontWeight={campaign ? 700 : 600}
						style={[
							{ marginBottom: 3 },
							campaign && {
								marginLeft: 8,
								marginTop: 4
							}
						]}
					>
						{question}
						{required && <span style={{ color: "red" }}>*</span>}
					</PrimaryText>
					<View style={{ flexDirection: "row", alignItems: "center" }}>
						{type === "color" && (
							<Box
								width={45}
								height={45}
								borderRadius="5px"
								bgcolor={value || answer}
								boxShadow="0 4px 10px 0 #00000010"
								mr={16}
							/>
						)}
						<PrimaryText
							fontSize={17}
							fontWeight={900}
							style={[{ marginRight: 16 }, campaign && { marginLeft: 8 }]}
						>
							{answer}
						</PrimaryText>
						<TouchableOpacity
							onPress={() => onEdit(property)}
							disabled={disabled}
						>
							{disabledText && disabled ? (
								<FiInfo
									size={17}
									data-tooltip-id={`tooltip-${question}`}
								/>
							) : (
								<FiEdit size={17} />
							)}
						</TouchableOpacity>
					</View>
				</View>
			) : (
				<View style={{ marginBottom: 16 }}>
					<PrimaryText
						fontSize={campaign ? 20 : 17}
						fontWeight={campaign ? 700 : 600}
						style={[{ marginBottom: 3 }, campaign && { marginTop: 4 }]}
					>
						{question}
						{required && <span style={{ color: "red" }}>*</span>}
					</PrimaryText>
					<PrimaryText
						fontSize={14}
						fontWeight={200}
						style={[{ marginTop: 0 }]}
					>
						{caption}
					</PrimaryText>
					<View
						style={{
							flexDirection: ["desc"].includes(type) ? "column" : "row",
							alignItems: ["desc"].includes(type) ? "flex-start" : "center",
							marginTop: 6
						}}
					>
						{type === "radio" && (
							<MuiRadio
								options={["Yes", "No"]}
								value={tempValue}
								onChange={onChange}
							/>
						)}

						{type === "text" && (
							<View style={{ marginRight: 30 }}>
								<CustomInput
									value={tempValue}
									onChange={onChange}
									placeholder={placeholder}
								/>
							</View>
						)}

						{type === "desc" && (
							<View style={{ marginRight: 30 }}>
								<CustomInput
									value={tempValue}
									onChange={onChange}
									placeholder={placeholder}
									multiline={true}
									style={{ width: 400 }}
								/>
							</View>
						)}

						{type === "color" && (
							<ColorPickerInput
								value={tempValue}
								onChange={onChange}
							/>
						)}

						{type === "picker" && (
							<View style={{ marginRight: 30 }}>
								<CustomPicker
									options={options}
									placeholder={placeholder}
									onChange={onChangePicker}
									value={tempValue}
								/>
							</View>
						)}

						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							mt={["desc"].includes(type) ? 12 : 0}
						>
							<MuiButton
								title="Save"
								onClick={() => onSave(property, tempValue)}
								variant="contained"
								sx={style.save}
							/>
							<MuiButton
								onClick={onCancel}
								title="Cancel"
								variant="outlined"
								sx={style.cancel}
							/>
						</Box>
					</View>

					<ErrorMessage
						errorMessage={errorMessage}
						marginTop={12}
					/>
				</View>
			)}

			<ReactTooltip
				id={`tooltip-${question}`}
				place="bottom"
				content={disabledText}
				style={{
					zIndex: 999,
					width: "200px",
					backgroundColor: "rgba(255,255,255,1)",
					color: "black",
					borderRadius: 12,
					boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
					arrow: {
						width: "20px"
					}
				}}
				opacity={1}
			/>
		</>
	);
}

const style = {
	cancel: { color: "neutral.700", pY: 6, fontSize: 14, fontWeight: 700 },
	save: { pY: 6, fontSize: 14, fontWeight: 700, mr: 20 }
};
