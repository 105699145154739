import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { dollarFormatter } from "utils/helpers";
import { Dimensions, StyleSheet, View } from "react-native-web";
import { COLORS } from "constants/Colors";
import { OrgPic } from "components/Pics/OrgPic";

const { width } = Dimensions.get("window");

export const BusinessTopNonprofitCard = ({ item, index }) => {
  const { organization = {} } = item;
  const { name = "", website = "" } = organization;
  

  return (
    <View style={styles.card}>
      <View style={styles.pic}>
        <OrgPic uri={organization?.profilePic} size={60} />
      </View>
      <PrimaryText
        fontSize={20}
        fontWeight={400}
        numberOfLines={1}
        style={{ flex: 1.4}}
      >
        {name}
      </PrimaryText>
      <PrimaryText
        fontSize={14}
        fontWeight={400}
        numberOfLines={1}
        style={{ flex: 1, color: COLORS.primary.lightBlue, height: 24 }}
      >
        {website}
      </PrimaryText>
      <PrimaryText
        fontSize={14}
        fontWeight={400}
        style={{marginTop: 12}}
      >
        {"Total Donations:"}
      </PrimaryText>
      <PrimaryText
        fontSize={24}
        fontWeight={900}
        style={{ color: COLORS.primary.green }}
      >
        {dollarFormatter.format(item?.amount)?.slice(0, -3)}
      </PrimaryText>
      <PrimaryText
        fontSize={14}
        fontWeight={400}
        style={{marginTop: 12}}
      >
        {"Employee Backers:"}
      </PrimaryText>
      <PrimaryText
        fontSize={24}
        fontWeight={900}
        style={{ color: COLORS.primary.lightBlue }}
      >
        {item?.backers || 0}
      </PrimaryText>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    alignItems: "center",
    marginHorizontal: 10,
    maxWidth: 150,
  },
  pic: {
    marginRight: 8,
    width: 42,
  },
});
