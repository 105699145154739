import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, { useEffect } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { useOrganization } from "context/OrganizationContext";
import { Links } from "constants/Links";
import { logout } from "services/auth-service";

export default function CreateAccount() {
  const navigate = useNavigate();
  const { currentAuth, onboarding } = useAuth();
  const { newOrg = {}, setNewOrg } = useOrganization();

  useEffect(() => {
    if (!onboarding) {
      navigate(Links.Landing);
    }
  }, [onboarding]);

  function typeSelected(type) {
    setNewOrg({ ...newOrg, type })
    currentAuth ? navigate(Links.CorporateAccount) : navigate(Links.PersonalLogin);
  }

  return (
    <View style={styles.container}>
      <img src={require("assets/rayze.png")} width={77.64} />

      <PrimaryText fontSize={54} fontWeight="900">
        Create Account
      </PrimaryText>

      <PrimaryText fontSize={32} style={{ color: COLORS.primary.gray }}>
        Which Account Type Are You Making?
      </PrimaryText>

      <View style={styles.centered}>
        <View>
          <PrimaryButton title="Nonprofit Account" onPress={() => typeSelected('nonprofit')} />

          <PrimaryText
            fontSize={17}
            style={{ color: COLORS.primary.gray, lineHeight: "150%" }}
          >
            Who is this account for?
            {"\n"}・Nonprofit companies
            {"\n"}・Accepting donations
            {"\n"}・Setting up volunteer events
          </PrimaryText>
        </View>

        <View style={styles.line} />

        <View>
          <PrimaryButton title="Corporate Account" onPress={() => typeSelected('business')} />

          <PrimaryText
            fontSize={17}
            style={{ color: COLORS.primary.gray, lineHeight: "150%" }}
          >
            Who is this account for?
            {"\n"}・For profit companies
            {"\n"}・Employee matching campaigns
            {"\n"}・Corporate donations
          </PrimaryText>
        </View>
      </View>

      {!currentAuth ? (
        <View style={styles.centered}>
          <PrimaryText fontSize={14} style={{ color: COLORS.primary.gray }}>
            Already have an organization account?{" "}
          </PrimaryText>
          <TouchableOpacity onPress={() => navigate(Links.PersonalLogin)}>
            <PrimaryText
              fontSize={14}
              style={{ color: COLORS.primary.lightBlue }}
            >
              Sign In
            </PrimaryText>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles.centered}>
          <PrimaryText fontSize={14} style={{ color: COLORS.primary.gray }}>
            Need to login to a different account?{" "}
          </PrimaryText>
          <TouchableOpacity onPress={() => logout()}>
            <PrimaryText
              fontSize={14}
              style={{ color: COLORS.primary.lightBlue }}
            >
              Sign Out
            </PrimaryText>
          </TouchableOpacity>
        </View>
      )
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    height: 351,
    width: 1,
    backgroundColor: "#737373",
    marginHorizontal: 135,
  },
  centered: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 60,
  },
});
