import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View, ActivityIndicator, TextInput } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowDimensions } from "react-native-web";
import { BREAKPOINTS } from "utils/breakpoints";
import TermsAndPrivacyText from "components/Common/TermsAndPrivacyText";

export function InvitePasscode() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { state = {} } = useLocation();
  const { invitation = {}, email } = state || {};
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [passcode, setPasscode] = useState(["", "", "", "", "", ""]);
  const [currentFocusedIndex, setCurrentFocusedIndex] = useState(0);
  const inputRefs = useRef([]);

  const [passcodeConfirmed, setPasscodeConfirmed] = useState(false);

  const onKeyDown = (e) => {
    const keyCode = e.key;
    if (!(keyCode >= 0 && keyCode <= 9)) {
      e.preventDefault();
    }
  };

  const onChange = (e, index) => {
    setPasscode((preValue) => {
      const newArray = [...preValue];

      if (parseInt(e.target.value)) {
        newArray[index] = parseInt(e.target.value);
      } else {
        newArray[index] = e.target.value;
      }

      return newArray;
    });
  };

  const onKeyUp = (e, index) => {
    if (parseInt(e.key) && index <= passcode.length - 2) {
      setCurrentFocusedIndex(index + 1);
      if (inputRefs && inputRefs.current && index === currentFocusedIndex) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const onFocus = (e, index) => {
    setCurrentFocusedIndex(index);
    e.target.focus();
  };

  useEffect(() => {
    //check valid email here
    checkPasscode();
  }, [passcode]);

  const checkPasscode = () => {
    setContinueDisabled(passcode.filter((x) => x === "").length > 0);
  };

  const continuePressed = async () => {
    setLoading(true);
    // const validPasscode = await checkInvitePasscode(invitation.id, passcode.join(""));
    //get passcode from local storage
    const storedCode = localStorage.getItem("invitePasscode");
    const validPasscode = storedCode === passcode.join("");
    setPasscodeConfirmed(validPasscode);
  };

  useEffect(() => {
    if (passcodeConfirmed) {
      setLoading(false);
      setTimeout(() => {
        navigate(Links.InviteNewOrExistingAccount, {
          state: { invitation, ...state },
        });
      }, 2000);
    }
  }, [passcodeConfirmed]);

  const styles = style({ width });

  return (
    <View style={styles.container}>
      <img
        src={require("assets/rayze.png")}
        width={width > BREAKPOINTS["sm"] ? 78 : 47}
      />

      <PrimaryText
        fontSize={width > BREAKPOINTS["sm"] ? 54 : 32}
        fontWeight="900"
        style={{ marginTop: 31 }}
      >
        Welcome to Rayze!
      </PrimaryText>

      <PrimaryText
        fontSize={width > BREAKPOINTS["sm"] ? 32 : 20}
        fontWeight={400}
        style={{
          textAlign: "center",
          color: COLORS.primary.gray,
          marginTop: 40,
          marginBottom: width > BREAKPOINTS["sm"] ? 152 : 122,
        }}
      >
        {passcodeConfirmed
          ? `Passcode Confirmed`
          : `A passcode has been sent to ${email}`}
      </PrimaryText>

      {!passcodeConfirmed ? (
        <>
          <View style={styles.inputContainer}>
            {passcode.map((item, index) => {
              return (
                <TextInput
                  key={index}
                  ref={(el) => el && (inputRefs.current[index] = el)}
                  value={item}
                  onChangeText={(text) => {
                    let newPasscode = [...passcode];
                    newPasscode[index] = text;
                    setPasscode(newPasscode);
                  }}
                  keyboardType="number-pad"
                  inputMode="numeric"
                  style={styles.passcodeInput}
                  maxLength={1}
                  onChange={(e) => onChange(e, index)}
                  onKeyUp={(e) => onKeyUp(e, index)}
                  onKeyDown={(e) => onKeyDown(e)}
                  onFocus={(e) => onFocus(e, index)}
                />
              );
            })}
          </View>

          <PrimaryButton
            title="Continue"
            onPress={continuePressed}
            disabled={continueDisabled}
            style={{
              marginTop: width > BREAKPOINTS["sm"] ? 84 : 66,
            }}
          />
        </>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width > BREAKPOINTS["sm"] ? "250" : "150"}
          height={width > BREAKPOINTS["sm"] ? "250" : "150"}
          viewBox="0 0 250 250"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M163.157 39.3069C144.69 31.0788 124.059 29.0405 104.34 33.4958C84.6202 37.9511 66.8693 48.6615 53.7342 64.0295C40.5992 79.3975 32.7837 98.5997 31.4536 118.772C30.1234 138.945 35.3497 159.007 46.3531 175.967C57.3565 192.927 73.5474 205.875 92.511 212.881C111.475 219.887 132.195 220.576 151.582 214.844C170.969 209.111 187.983 197.266 200.088 181.074C212.193 164.882 218.739 145.211 218.75 124.994V115.417C218.75 109.664 223.414 105 229.167 105C234.92 105 239.584 109.664 239.584 115.417V125C239.57 149.709 231.569 173.758 216.774 193.548C201.979 213.338 181.184 227.816 157.489 234.822C133.794 241.828 108.469 240.986 85.2911 232.423C62.1133 223.86 42.3245 208.035 28.8759 187.306C15.4273 166.578 9.0396 142.057 10.6654 117.402C12.2911 92.7461 21.8433 69.2767 37.8973 50.4936C53.9512 31.7105 75.6468 18.6201 99.7483 13.1747C123.85 7.72926 149.066 10.2206 171.636 20.2772C176.891 22.6186 179.253 28.7767 176.911 34.0316C174.57 39.2866 168.412 41.6484 163.157 39.3069Z"
            fill="#1DA1F2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M236.528 34.2973C240.598 38.3632 240.602 44.9587 236.536 49.0287L132.369 153.3C130.416 155.255 127.766 156.353 125.002 156.354C122.239 156.355 119.588 155.257 117.634 153.303L86.384 122.053C82.316 117.985 82.316 111.39 86.384 107.322C90.4519 103.254 97.0474 103.254 101.115 107.322L124.996 131.202L221.797 34.3047C225.863 30.2347 232.458 30.2314 236.528 34.2973Z"
            fill="#1DA1F2"
          />
        </svg>
      )}

      <TermsAndPrivacyText />

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={COLORS.primary.blue}
        />
      )}
    </View>
  );
}

const style = ({ width }) =>
  StyleSheet.create({
    container: {
      backgroundColor: COLORS.primary.white,
      justifyContent: width > BREAKPOINTS["sm"] ? "center" : "flex-start",
      alignItems: "center",
      height: "100%",
      paddingTop: width > BREAKPOINTS["sm"] ? 0 : 19,
    },
    loginText: {
      fontSize: 20,
      fontWeight: "700",
      textAlign: "center",
      color: COLORS.primary.lightBlue,
    },
    inputContainer: {
      flexDirection: "row",
    },
    passcodeInput: {
      width: 50,
      height: 50,
      marginHorizontal: 6,
      borderBottomWidth: 3,
      borderBottomColor: COLORS.primary.gray,
      alignItems: "center",
      textAlign: "center",
      fontSize: 24,
      fontWeight: "700",
      color: COLORS.primary.darkBlue,
      fontFamily: "Montserrat",
    },
    forgotPasswordBtn: {
      width: "100%",
      marginTop: 6,
    },
    forgotPassword: {
      color: COLORS.primary.lightBlue,
      fontSize: 14,
      marginBottom: 31,
      textAlign: "right",
    },
    separator: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 18,
    },
    line: {
      width: 75,
      height: 1,
      backgroundColor: COLORS.primary.gray,
    },
    separatorText: { marginHorizontal: 15, color: COLORS.primary.gray },
    termsText: {
      textAlign: "center",
      color: COLORS.primary.black,
      fontSize: 14,
      fontWeight: "400",
      flexWrap: "nowrap",
    },
    termsButtonText: {
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      color: COLORS.primary.lightBlue,
      flexWrap: "nowrap",
    },
  });
