import { StyleSheet, View } from "react-native";
import React from "react";
import Overlay from "components/Overlay";
import { PrimaryText } from "components/Common/PrimaryText";
import PrimaryButton from "components/Buttons/PrimaryButton";

export default function Welcome({ onPress }) {
  return (
    <Overlay contentContainerStyle={styles.container}>
      <View style={styles.centered}>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <img src={require("assets/misc/donation.png")} />
          <img src={require("assets/misc/comment.png")} />
          <img src={require("assets/misc/compass.png")} />

          <img src={require("assets/rayze.png")} width={106} />

          <img src={require("assets/misc/calendar.png")} />
          <img src={require("assets/misc/announcement.png")} />
          <img src={require("assets/misc/addUser.png")} />
        </View>

        <PrimaryText
          fontSize={24}
          fontWeight={900}
          style={{ marginTop: 17, marginBottom: 7 }}
        >
          Welcome
        </PrimaryText>

        <PrimaryText fontSize={16}>
          Donate, Volunteer, and Give Back
        </PrimaryText>

        <PrimaryButton
          title="Get Started"
          style={{ marginTop: 36, minWidth: 148 }}
          onPress={onPress}
        />
      </View>
    </Overlay>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  centered: {
    width: 592,
    height: 362,
    backgroundColor: "white",
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
  },
});
