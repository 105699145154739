import React from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View } from "react-native";
import { EventsCard } from "components/Cards/EventsCard";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEvents } from "context/EventContext";
import { useOrganization } from "context/OrganizationContext";

export const EventsTab = ({ setShowConfirmModal }) => {
  const { userType } = useOrganization();
  const { upcomingEvents } = useEvents();

  return (
    <View style={styles.tableContainer}>
      <Table className="table table2">
        <TableHead style={{backgroundColor: COLORS.primary.darkBlue}}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell style={{ textAlign: "left" }}>Event Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Going</TableCell>
            <TableCell>Interested</TableCell>
            {userType === "Leader" && <TableCell></TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {upcomingEvents &&
            upcomingEvents.map((el, i) => {
              return (
                <EventsCard
                  item={el}
                  key={i}
                  deleteEvent={
                    userType === "Leader" ? () => setShowConfirmModal(el) : null
                  }
                />
              );
            })}
        </TableBody>
      </Table>
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: {
    minHeight: 300,
    backgroundColor: COLORS.primary.offWhite,
  },
  headerContainer: {
    flexDirection: "row",
  },
  infoContainer: {
    backgroundColor: COLORS.primary.white,
    marginHorizontal: 12,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  title: {
    marginBottom: 12,
  },
});
