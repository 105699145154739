import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
	Image,
	Svg
} from "@react-pdf/renderer";
import { COLORS } from "constants/Colors";
import { getEventDate } from "utils/events-helper";
import { getFullLocation } from "utils/helpers";

Font.register({
	family: "Montserrat",
	fonts: [
		{ src: require("assets/fonts/Montserrat-Regular.ttf"), fontWeight: 400 },
		{ src: require("assets/fonts/Montserrat-Bold.ttf"), fontWeight: 700 },
		{ src: require("assets/fonts/Montserrat-ExtraBold.ttf"), fontWeight: 800 }
	]
});

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: COLORS.primary.white,
		padding: 30,
		paddingVertical: 12,
		justifyContent: "space-between"
	},
	section: {
		// margin: 10,
		// padding: 10,
		// flexGrow: 1,
		textAlign: "center",
		alignItems: "center"
	},
	sectionLeft: {
		// margin: 10,
		// padding: 10,
		// flexGrow: 1,
		textAlign: "left",
		alignItems: "flex-start"
	},
	name: {
		fontSize: 32,
		fontWeight: 800,
		fontFamily: "Montserrat"
	},
	website: {
		fontSize: 20,
		fontWeight: 400,
		fontFamily: "Montserrat"
	},
	about: {
		fontSize: 16,
		fontWeight: 400,
		fontFamily: "Montserrat"
	},
	title: {
		fontSize: 16,
		fontWeight: 600,
		fontFamily: "Montserrat"
	},
	titleWhite: {
		fontSize: 16,
		fontWeight: 600,
		fontFamily: "Montserrat",
		color: COLORS.primary.white
	},
	donate: {
		fontSize: 20,
		fontWeight: 700,
		fontFamily: "Montserrat"
	},

	aboutBg: {
		fontSize: 16,
		fontWeight: 400,
		fontFamily: "Montserrat",
		color: COLORS.primary.white
	},

	nameGradient: {
		fontSize: 32,
		fontWeight: 800,
		fontFamily: "Montserrat",
		color: COLORS.primary.white
	},
	websiteGradient: {
		fontSize: 20,
		fontWeight: 700,
		fontFamily: "Montserrat",
		color: COLORS.primary.white
	}
});

// Create Document Component
export const EventFlyers = (props) => {
	const { selectedFlyer, selectedOrg = {}, SvgDL, selectedEvent } = props;
	if (!SvgDL) return null;

	const PDF = () => {
		switch (selectedFlyer) {
			case "simple":
				return (
					<Document>
						<Page
							size="A4"
							style={styles.page}
						>
							<View style={styles.section}>
								<Image
									source={{ uri: selectedOrg?.coverPhoto }}
									style={{
										width: "50%",
										height: undefined,
										// marginTop: 12,
										aspectRatio: 16 / 9,
										borderRadius: 4
									}}
								/>
							</View>
							<View style={styles.section}>
								<Text style={styles.donate}>{selectedOrg?.name}</Text>
							</View>
							<View style={styles.section}>
								<Text style={styles.name}>{selectedEvent?.name}</Text>
							</View>

							<View style={styles.section}>
								<Text style={styles.title}>Date:</Text>
								<Text style={styles.about}>{getEventDate(selectedEvent)}</Text>
								<Text style={[styles.title, { marginTop: 12 }]}>Location:</Text>
								<Text style={styles.about}>
									{getFullLocation(selectedEvent)}
								</Text>
							</View>

							<View style={styles.section}>
								<Text style={styles.title}>About</Text>
								<Text style={styles.about}>{selectedEvent?.about}</Text>
							</View>
							<View style={styles.section}>
								<Text style={styles.donate}>Register or Donate</Text>
								<Svg
									width={130}
									height={130}
									style={{ marginTop: 24 }}
									viewBox={"0 0 33 33"}
								>
									{SvgDL}
								</Svg>
							</View>
							<View style={styles.section}>
								<Text style={styles.about}>In partnership with</Text>
								<Image
									source={require("assets/rayzeLogo.png")}
									style={{ width: 75, height: undefined, marginTop: 12 }}
								/>
							</View>
						</Page>
					</Document>
				);
			case "bg":
				return (
					<Document>
						<Page
							size="A4"
							style={[
								styles.page,
								{ padding: 70, paddingVertical: 34, paddingTop: 28 }
							]}
						>
							<Image
								source={require("assets/templates/flyerBg.png")}
								style={{
									position: "absolute",
									left: 0,
									right: 0,
									top: 0,
									bottom: 0
								}}
							/>
							<View style={[styles.sectionLeft, { marginBottom: 0 }]}>
								<Image
									source={{ uri: selectedOrg?.coverPhoto }}
									style={{
										maxWidth: "50%",
										height: undefined,
										marginTop: 12,
										aspectRatio: 16 / 9,
										borderRadius: 4
									}}
								/>
							</View>
							<View style={styles.sectionLeft}>
								<Text style={[styles.donate, { color: COLORS.primary.white }]}>
									{selectedOrg?.name}
								</Text>
							</View>
							<View style={styles.sectionLeft}>
								<Text style={[styles.name, { color: COLORS.primary.white }]}>
									{selectedEvent?.name}
								</Text>
							</View>

							<View style={styles.sectionLeft}>
								<Text style={styles.titleWhite}>Date:</Text>
								<Text style={styles.aboutBg}>
									{getEventDate(selectedEvent)}
								</Text>
								<Text style={[styles.titleWhite, { marginTop: 12 }]}>
									Location:
								</Text>
								<Text style={styles.aboutBg}>
									{getFullLocation(selectedEvent)}
								</Text>
							</View>

							<View style={styles.sectionLeft}>
								<Text style={styles.titleWhite}>About</Text>
								<Text style={styles.aboutBg}>{selectedEvent?.about}</Text>
							</View>
							<View style={styles.sectionLeft}>
								<Text style={[styles.donate, { color: COLORS.primary.white }]}>
									Register or Donate
								</Text>
								<View
									style={{
										backgroundColor: COLORS.primary.white,
										marginTop: 12,
										padding: 12
									}}
								>
									<Svg
										width={130}
										height={130}
										viewBox={"0 0 29 29"}
									>
										{SvgDL}
									</Svg>
								</View>
							</View>
							<View style={styles.sectionLeft}>
								<Text
									style={[
										styles.about,
										{
											color: COLORS.primary.white,
											fontWeight: 600,
											fontSize: 16
										}
									]}
								>
									In partnership with
								</Text>
								<Image
									source={require("assets/rayzeLogoHorizontalLight.png")}
									style={{ width: 147, height: undefined, marginTop: 12 }}
								/>
							</View>
						</Page>
					</Document>
				);
			case "gradient":
				return (
					<Document>
						<Page
							size="A4"
							style={styles.page}
						>
							<Image
								source={require("assets/templates/gradientBg.png")}
								style={{
									position: "absolute",
									left: 0,
									right: 0,
									top: 0,
									bottom: 0
								}}
							/>
							<View style={styles.section}>
								<Image
									source={{ uri: selectedOrg?.coverPhoto }}
									style={{
										width: "40%",
										height: undefined,
										// marginTop: 12,
										aspectRatio: 16 / 9
										// borderRadius: 4,
									}}
								/>
							</View>
							<View style={styles.section}>
								<Text style={[styles.donate, { color: COLORS.primary.white }]}>
									{selectedOrg?.name}
								</Text>
							</View>
							<View style={styles.section}>
								<Text style={styles.nameGradient}>{selectedEvent?.name}</Text>
							</View>
							<View
								style={{
									width: "70%",
									height: 4,
									backgroundColor: COLORS.primary.white,
									alignSelf: "center"
								}}
							/>

							<View
								style={{
									width: "100%",
									flexDirection: "row",
									justifyContent: "space-between"
								}}
							>
								<View
									style={[styles.sectionLeft, { flex: 3, marginRight: 24 }]}
								>
									<Text style={styles.titleWhite}>Date:</Text>
									<Text style={styles.aboutBg}>
										{getEventDate(selectedEvent)}
									</Text>
									<Text style={[styles.titleWhite, { marginTop: 12 }]}>
										Location:
									</Text>
									<Text style={styles.aboutBg}>
										{getFullLocation(selectedEvent)}
									</Text>

									<Text style={[styles.titleWhite, { marginTop: 12 }]}>
										About
									</Text>
									<Text style={[styles.aboutBg, { flex: 1 }]}>
										{selectedOrg?.about}
									</Text>
								</View>
								<View style={[styles.section, { flex: 2 }]}>
									<Text style={styles.websiteGradient}>Donate</Text>
									<View
										style={{
											backgroundColor: COLORS.primary.white,
											marginTop: 24,
											padding: 12
										}}
									>
										<Svg
											width={160}
											height={160}
											viewBox={"0 0 29 29"}
										>
											{SvgDL}
										</Svg>
									</View>
								</View>
							</View>
							<View style={styles.sectionLeft}>
								<Text style={[styles.titleWhite]}>In partnership with</Text>
								<Image
									source={require("assets/rayzeLogoHorizontalLight.png")}
									style={{ width: 202, height: undefined, marginVertical: 12 }}
								/>
							</View>
						</Page>
					</Document>
				);
			default:
				return (
					<Document>
						<Page
							size="A4"
							style={styles.page}
						>
							<View style={styles.section}>
								<Text>Section #3</Text>
							</View>
							<View style={styles.section}>
								<Text>Section #4</Text>
							</View>
						</Page>
					</Document>
				);
		}
	};

	return (
		// <PDFViewer style={{ width: 500, height: 800 }}>
		<PDF />
		// </PDFViewer>
	);
};
