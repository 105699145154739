import React from "react";
import { View, Dimensions, StyleSheet } from "react-native";
import { Navigate, Route, Routes } from "react-router-dom";
import { Links } from "constants/Links";
import Congrats from "screens/Auth/2e-Congrats";
import CorporateAccount from "screens/Auth/3-OrgAccount";
import CreateAccount from "screens/Auth/1-CreateAccount";
import PersonalInformation from "screens/Auth/2d-PersonalInformation";
import EnterPasscode from "screens/Auth/2c-EnterPasscode";
import VerifyPersonalAccount from "screens/Auth/2b-VerifyPersonalAccount";
import CreatePersonalAccount from "screens/Auth/2a-CreatePersonalAccount";
import { PersonalLogin } from "screens/Auth/2-PersonalLogin";
import AccountCreated from "screens/Auth/AccountCreated";
import VerifyNonProfit from "screens/Auth/VerifyNonProfit";
import CheckingCrossed from "screens/Auth/CheckingCrossed";
import AccountCreatedStep2 from "screens/Auth/AccountCreatedStep2";

const { height } = Dimensions.get("window");

export default function OnboardingNavigator() {
  return (
    <View
      style={{
        flex: 1,
        height: height,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 24,
        minWidth: 1024,
        paddingTop: 12,
      }}
    >
      <Routes>
        <Route path={Links.CreateAccount} element={<CreateAccount />} />
        <Route path={Links.PersonalLogin} element={<PersonalLogin />} />
        <Route
          path={Links.CreatePersonalAccount}
          element={<CreatePersonalAccount />}
        />
        <Route
          path={Links.VerifyPersonalAccount}
          element={<VerifyPersonalAccount />}
        />
        <Route path={Links.EnterPasscode} element={<EnterPasscode />} />
        <Route
          path={Links.PersonalInformation}
          element={<PersonalInformation />}
        />
        <Route path={Links.CorporateAccount} element={<CorporateAccount />} />
        <Route path={Links.Congrats} element={<Congrats />} />
        <Route path={Links.AccountCreated} element={<AccountCreated />} />
        <Route path={Links.VerifyNonProfit} element={<VerifyNonProfit />} />
        <Route path={Links.Verifying} element={<CheckingCrossed />} />
        <Route
          path={Links.AccountCreatedStep2}
          element={<AccountCreatedStep2 />}
        />
        <Route
          path={"/*"}
          element={<Navigate replace to={Links.CreateAccount} />}
        />
      </Routes>
    </View>
  );
}

const styles = StyleSheet.create({});
