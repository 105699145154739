import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { View } from "react-native-web";

export default function LinearProgressWithLabel(props) {
  const {
    value,
    height = 24,
    fontSize = 17,
    fontWeight = 400,
    leftText,
    rightText,
    leftValue,
    rightValue,
    leftTextStyle = {},
    rightTextStyle = {},
    leftValueStyle = {},
    rightValueStyle = {},
  } = props;
  return (
    <Box sx={{ display: "grid" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: leftText ? "space-between" : "flex-end",
          marginBottom: 2,
        }}
      >
        {leftText && (
          <PrimaryText
            style={{ textAlign: "right", color: "#737373", ...leftTextStyle }}
            fontSize={fontSize}
            fontWeight={fontWeight}
          >
            {leftText}
            <PrimaryText
              fontSize={fontSize}
              fontWeight={fontWeight}
              style={{ textAlign: "right", color: "#737373", ...leftValueStyle }}
            >
              {" "}
              {leftValue}
            </PrimaryText>
          </PrimaryText>
        )}
        {rightText && (
          <PrimaryText
            style={{ textAlign: "right", color: "#737373", ...rightTextStyle }}
            fontSize={fontSize}
            fontWeight={fontWeight}
          >
            {rightText}
            <PrimaryText
              fontSize={fontSize}
              fontWeight={fontWeight}
              style={{ textAlign: "right", color: "#737373", ...rightValueStyle }}
            >
              {" "}
              {rightValue}
            </PrimaryText>
          </PrimaryText>
        )}
      </View>

      <LinearProgress
        variant="determinate"
        value={value > 100 ? 100 : value}
        sx={{
          height: `${height}px`,
          borderRadius: height / 2,
          backgroundColor: "neutral.300",
          ".MuiLinearProgress-bar": {
            borderRadius: height / 2,
            backgroundColor: COLORS.primary.lightBlue,
          },
        }}
      />
    </Box>
  );
}
