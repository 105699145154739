import React from "react";
import { PrimaryText } from "./PrimaryText";
import { StyleSheet } from "react-native-web";
import { COLORS } from "constants/Colors";
import { ShadowContainer } from "components/Containers/ShadowContainer";

export default function MuiCard({ title, value, containerStyle }) {
	return (
		<ShadowContainer style={[styles.card, containerStyle]}>
			<PrimaryText
				style={styles.value}
				fontSize={24}
				fontWeight={600}
			>
				{value}
			</PrimaryText>
			<PrimaryText
				style={{ color: COLORS.primary.darkBlue }}
				fontSize={17}
				fontWeight={400}
			>
				{title}
			</PrimaryText>
		</ShadowContainer>
	);
}

const styles = StyleSheet.create({
	value: {
		color: COLORS.primary.lightBlue,
		marginBottom: 8
	},
	card: {
		marginHorizontal: 24,
		padding: 20
	}
});
