import { Alert, Snackbar } from "@mui/material";
import React from "react";

export default function MuiSnackbar({ open, onClose }) {
	return (
		<Snackbar
			open={!!open}
			onClose={onClose}
			autoHideDuration={1000}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right"
			}}
		>
			<Alert
				onClose={onClose}
				severity={open?.severity || "info"}
				variant="filled"
				sx={{ width: "100%" }}
			>
				{open?.message || open}
			</Alert>
		</Snackbar>
	);
}
