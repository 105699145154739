import React, { useState, createContext, useEffect, useContext } from "react";
import {
	getRecentDonations,
	listenForRecurringDonationChanges
} from "services/donations-service";
import { useOrganization } from "./OrganizationContext";

export const DonationsContext = createContext();

export const DonationsProvider = ({ children }) => {
	const { selectedOrg } = useOrganization();

	const [recentDonations, setRecentDonations] = useState([]);
	const [getDonations, setGetDonations] = useState(false);
	const [ourDonations, setOurDonations] = useState([]);
	const [ourRecurringDonations, setOurRecurringDonations] = useState([]);
	const [selectedDonation, setSelectedDonation] = useState(null);




	useEffect(() => {
		if (!selectedOrg) return;
		console.log(selectedOrg.id, " : org id");
		getRecentDonations(selectedOrg, setRecentDonations);

		const sub = listenForRecurringDonationChanges(
			selectedOrg,
			setOurRecurringDonations
		);

		return () => {
			sub();
		};
	}, [selectedOrg]);

	useEffect(() => {

		if (ourDonations.length === 0) return;
	

	  }, [ourDonations]);

	return (
		<DonationsContext.Provider
			value={{
				recentDonations,
				setGetDonations,
				ourDonations,
				ourRecurringDonations,
				selectedDonation,
				setSelectedDonation
			}}
		>
			{children}
		</DonationsContext.Provider>
	);
};

export const useDonations = () => useContext(DonationsContext);
