import React, { useEffect, useState } from "react"
import { StyleSheet, View, Image } from "react-native"
import { COLORS } from "constants/Colors"
import { useDisplay } from "context/DisplayContext"

const rayzeIcon = require("assets/misc/default_org_dp.png")

export const OrgPic = (props) => {
  const {darkMode} = useDisplay()
  const [imageUri, setImageUri] = useState(null)

  useEffect(() => {
    props.uri && setImageUri(props.uri)
  }, [props.uri, props.userId])

  const style = styles(darkMode)

  const borderSize = props.size-3
  const picSize = props.size-5

  return (
    // <LinearGradient
    //   style={[style.container,{
    //       height: props.size,
    //       width: props.size,
    //     }]}
    //   useAngle={true}
    //   angle={140}
    //   colors={['#FBDF4B', '#48A0EB', '#DC3956', '#FBDF4B']}
    // >
    <View style={[style.innerContainer, { width: borderSize, height: borderSize }]}>
      {/* <View style={style.img_bg_v}> */}
        <Image
          style={{ width: picSize, height: picSize }}
          resizeMode={"contain"}
          source={
            imageUri
            ? { uri: imageUri }
            : rayzeIcon
          }
        />
      {/* </View> */}
    </View>
  )
}

const styles = (darkMode) => StyleSheet.create({
  shadowed_v: {
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,

    // elevation: 5,
  },
  innerContainer: {
    alignItems: 'center', justifyContent: 'center',
    backgroundColor: darkMode ? COLORS.primary.darkBlue : COLORS.primary.white
  },
  img_bg_v: {
    width: "90%",
    aspectRatio: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#001935",
  },
})
