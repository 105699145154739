import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";

export default function Overlay({ children, style: customStyle, contentContainerStyle }) {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const width = windowSize[0]
  const height = windowSize[1]

  const style = styles(width, height)
  return (
    <View style={[style.container, customStyle]}>
      <View style={style.overlay} />
      <View style={contentContainerStyle}>{children}</View>
    </View>
  );
}

const styles = (width, height) => StyleSheet.create({
  container: {
    width,
    height,
    position: "fixed",
    zIndex: 999,
    top: 0,
    left: 0,
  },
  overlay: {
    width,
    height,
    backgroundColor: "#000",
    opacity: 0.25,
    position: "fixed",
    top: 0,
    left: 0,
  },
});
