import React from "react";
import { TablePagination } from "@mui/material";
import { FiChevronDown } from "react-icons/fi";

export default function MuiTablePagination({
	count,
	rowsPerPage,
	page,
	setCurrentPage,
	onRowsPerPageChange,
	disabled
}) {
	return (
		<TablePagination
			disabled={disabled}
			rowsPerPageOptions={[5, 10, 25]}
			component="div"
			count={count || 0}
			rowsPerPage={rowsPerPage}
			SelectProps={{
				IconComponent: () => (
					<FiChevronDown
						size={35}
						style={{ marginLeft: -20 }}
					/>
				)
			}}
			page={page}
			onPageChange={(_, page) => setCurrentPage(page)}
			onRowsPerPageChange={onRowsPerPageChange}
			showLastButton={true}
			showFirstButton={true}
		/>
	);
}
