import { StyleSheet, View } from "react-native";
import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";

export default function Congrats() {
  return (
    <View style={styles.container}>
      <img src={require("assets/rayzeLogo.png")} width={414} />

      <PrimaryText
        fontSize={54}
        fontWeight="900"
        style={{ textAlign: "center", marginTop: 50 }}
      >
        Congrats! You made your
        <br />
        personal account!
      </PrimaryText>

      <PrimaryText
        fontSize={32}
        style={{ color: COLORS.primary.gray, marginTop: 47 }}
      >
        Now, let’s get your corporate account set up
      </PrimaryText>

      <PrimaryButton
        to={Links.CorporateAccount}
        title="Continue"
        style={{ marginTop: 48 }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
