import React, { useState, useContext } from "react";
import { Header } from "components/Header";
import { COLORS } from "constants/Colors";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { capitalize, formattedDate, formattedPhoneNumber } from "utils";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native-web";
import { ProfilePic } from "components/Pics/ProfilePic";
import { PrimaryText } from "components/Common/PrimaryText";
import { getCityStateZip, getUsersName } from "utils/helpers";
import { Tabs } from "components/Tabs/Tabs";
import moment from "moment";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@mui/material";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { ShadowContainer } from "components/Containers/ShadowContainer";

const { height } = Dimensions.get("window");
const tabs = [
	"Activity Feed",
	"Organizations",
	"Actions",
	"Donations",
	"Reports"
];

export const UserDetail = (props) => {
	const navigate = useNavigate();
	const { selectedUser, causes } = useContext(UserContext);

	const [activeTab, setActiveTab] = useState("Activity Feed");

	const statistics = [
		[
			{
				title: "Inspiring",
				amount: selectedUser?.followers || 0
			}
		],
		[
			{
				title: "Inspirations",
				amount: selectedUser?.following || 0
			}
		]
	];

	const userDetails = [
		{ title: "Email", value: selectedUser.email },
		{ title: "Phone", value: formattedPhoneNumber(selectedUser.phone) },
		{ title: "Gender", value: capitalize(selectedUser.gender) },
		{
			title: "Location",
			value: selectedUser?.city
				? selectedUser?.city + ", " + selectedUser?.state
				: selectedUser?.zipCode
		},
		{
			title: "Birthday",
			value: moment(selectedUser?.birthday.toDate()).format("MMMM, YYYY")
		},
		{ title: "Join Date", value: formattedDate(selectedUser.createdAt) }
	];

	return (
		<ScrollView style={{ width: "100%", maxHeight: height - 80 }}>
			<View style={styles.container}>
				<Header
					title={getUsersName(selectedUser) + " - @" + selectedUser?.username}
					onBack={() => navigate(-1)}
				/>
				{/** ROW 1 */}
				<View style={[styles.info, { marginTop: 24 }]}>
					<ProfilePic
						uri={selectedUser?.profilePic}
						size={100}
					/>
					<View style={styles.stats}>
						{statistics?.map((row, i) => {
							return (
								<View
									style={{ justifyContent: "space-between" }}
									key={i}
								>
									{row?.map((item, i) => {
										const { title, amount } = item;
										return (
											<View
												style={styles.statContainer}
												key={i}
											>
												<PrimaryText
													fontSize={18}
													fontWeight={400}
													style={styles.header}
												>
													{title}
												</PrimaryText>
												<PrimaryText
													fontSize={24}
													fontWeight={700}
													style={styles.header}
												>
													{amount}
												</PrimaryText>
											</View>
										);
									})}
								</View>
							);
						})}
					</View>
				</View>

				{/** ROW 2 */}
				<View style={styles.info}>
					<View style={styles.infoContainer}>
						{userDetails?.map((u, i) => {
							const { title, value } = u;
							if (!value) return null;
							return (
								<View
									style={styles.infoTextRow}
									key={i}
								>
									<View
										style={{ marginRight: 12 }}
										className="text-right mr-4"
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											style={{ width: 180, textAlign: "right" }}
										>
											{title}
										</PrimaryText>
									</View>
									<PrimaryText
										fontSize={16}
										fontWeight={600}
										style={u?.capitalize && { textTransform: "capitalize" }}
									>
										{value}
									</PrimaryText>
								</View>
							);
						})}
					</View>

					<ScrollView style={styles.causesContainer}>
						<PrimaryText
							fontSize={16}
							fontWeight={600}
						>
							Causes
						</PrimaryText>
						<View style={styles.causes}>
							{causes &&
								causes?.map((item) => (
									<View
										style={styles.cause}
										key={item.id}
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											style={{ color: COLORS.primary.white }}
										>
											{item.name}
										</PrimaryText>
										{/* <FaIcons.FaTimes /> */}
									</View>
								))}
						</View>
					</ScrollView>
				</View>
			</View>

			<ShadowContainer style={styles.shadowContainer}>
				{/** HEADER */}
				<PrimaryText
					fontSize={32}
					fontWeight={900}
					style={styles.title}
				>
					Donations
				</PrimaryText>

				{/** TABLE */}
				<Table
					className="table"
					style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
				>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell style={{ textAlign: "left" }}>NAME</TableCell>
							<TableCell>EMAIL</TableCell>
							<TableCell>FREQUENCY</TableCell>
							<TableCell>AMOUNT</TableCell>
							<TableCell>DATE</TableCell>
						</TableRow>
					</TableHead>

					<TableBody></TableBody>
				</Table>

				<MuiTablePagination
					count={10}
					rowsPerPage={10}
					page={0}
					// setCurrentPage={setCurrentPage}
					// onRowsPerPageChange={(e) => {
					// 	setRowsPerPage(e.target.value);
					// 	setCurrentPage(0);
					// }}
				/>
			</ShadowContainer>

			{/* <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} /> */}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	activity: {
		flex: 1,
		width: "100%",
		backgroundColor: COLORS.primary.white
	},
	container: {
		flex: 1,
		width: "100%",
		paddingVertical: 20,
		backgroundColor: COLORS.primary.white
	},
	shadowContainer: {
		flex: 1,
		width: "100%",
		flexDirection: "column"
	},
	header: {
		color: COLORS.primary.darkBlue
	},
	cover_img_v: {
		width: "100%",
		aspectRatio: 16 / 9
	},
	statsPhoto: {
		width: "35%"
	},
	stats: {
		flex: 1,
		flexDirection: "row",
		padding: 27,
		justifyContent: "space-around",
		// justifyContent:'center',
		backgroundColor: COLORS.primary.offWhite,
		borderRadius: 12,
		marginLeft: 16
	},
	statContainer: {
		alignItems: "center"
	},
	info: {
		flexDirection: "row",
		marginBottom: 16,
		maxHeight: 300
	},
	infoContainer: {
		flex: 1,
		padding: 20,
		justifyContent: "space-around",
		backgroundColor: COLORS.primary.offWhite,
		borderRadius: 12
	},
	infoTextRow: {
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 6
	},
	causesContainer: {
		flex: 1,
		// height: 200,
		padding: 20,
		backgroundColor: COLORS.primary.offWhite,
		borderRadius: 12,
		marginLeft: 16
	},
	causes: {
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap",
		paddingBottom: 20,
		paddingTop: 12
	},
	cause: {
		flexDirection: "row",
		alignItems: "center",
		paddingVertical: 4,
		paddingHorizontal: 8,
		marginRight: 8,
		marginBottom: 10,
		borderRadius: 100,
		backgroundColor: COLORS.primary.lightBlue
	}
});
