import React from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Box } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { ChevronDown } from "assets/svgs";
import ErrorMessage from "components/Common/ErrorMessage";
import moment from "moment";

export default function MuiTimePicker({
	label,
	required,
	boxProps,
	labelFontSize = 16,
	labelFontWeight = 900,
	labelStyle = {},
	onChange,
	value,
	minTime,
	errorMessage,
	maxTime
}) {
	return (
		<Box
			display="flex"
			flexDirection="column"
			{...boxProps}
		>
			<PrimaryText
				fontSize={labelFontSize}
				fontWeight={labelFontWeight}
				style={{ marginBottom: 12, ...labelStyle }}
			>
				{label}
				{required && <span style={{ color: COLORS.primary.error }}>*</span>}
			</PrimaryText>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<TimePicker
					slotProps={{
						textField: {
							InputProps: {
								sx: {
									height: 30,
									borderRadius: "15px",
									fontSize: 14,
									bgcolor: COLORS.primary.white
								}
							}
						}
					}}
					slots={{
						openPickerIcon: () => <ChevronDown fill={COLORS.primary.darkBlue} />
					}}
					value={value && moment(value).isValid() ? moment(value) : null}
					onChange={(time) => onChange(moment(time).toDate())}
					closeOnSelect={false}
					minTime={
						minTime && moment(minTime).isValid() ? moment(minTime) : null
					}
					maxTime={
						maxTime && moment(maxTime).isValid() ? moment(maxTime) : null
					}
					minutesStep={5}
					// referenceDate={new Date("2022-01-01T12:00:00.000Z")}
				/>
			</LocalizationProvider>
			<ErrorMessage errorMessage={errorMessage} />
		</Box>
	);
}
