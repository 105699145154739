import { Box, IconButton, Modal } from "@mui/material";
import { Close } from "assets/svgs";
import { Button } from "components/Common/Button";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React, { useState } from "react";
import { LuChevronRight } from "react-icons/lu";
import { StyleSheet, TextInput } from "react-native-web";

const NUMBER_OF_EMPLOYEES = 83;
const RAYZE_AMOUNT = 10_801;

export default function RayzeEmployeeGiftModal({ isOpen, onClose, onNext }) {
	const [dollarAmount, setDollarAmount] = useState(0);

	const totalAmount = dollarAmount * NUMBER_OF_EMPLOYEES;

	const handleDollarChange = (e) => {
		let value = e.target.value;
		value = !value ? 0 : parseFloat(value);
		if (!isNaN(value)) setDollarAmount(value);
	};

	return (
		<Modal
			open={isOpen}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
			onClose={onClose}
		>
			<Box
				width={{ base: 730, lg: 800 }}
				pt={32}
				pb={48}
				bgcolor={COLORS.primary.white}
				display="flex"
				flexDirection="column"
				borderRadius={3}
				position="relative"
				px={40}
				boxSizing="border-box"
			>
				<IconButton
					sx={{ position: "absolute", top: 16, right: 20 }}
					onClick={onClose}
				>
					<Close fill={COLORS.primary.gray} />
				</IconButton>
				<PrimaryText
					fontSize={24}
					fontWeight={900}
					style={{ textAlign: "center" }}
				>
					Rayze Account Employee Gift
				</PrimaryText>

				<PrimaryText
					fontSize={16}
					color={COLORS.primary.neutral_500}
					style={{ marginTop: 10, marginBottom: 30, textAlign: "center" }}
				>
					Current Balance: ${RAYZE_AMOUNT.toLocaleString()}
				</PrimaryText>

				<CustomInput
					placeholder="Enter Dollar Amount"
					lebel="Amount Per Employee"
					required={true}
					onChange={(e) => handleDollarChange(e)}
					value={dollarAmount}
				/>

				<PrimaryText
					fontSize={16}
					fontWeight={900}
				>
					Total:
				</PrimaryText>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					mt={6}
				>
					<Box
						display="flex"
						flexDirection="row"
						alignItems="flex-end"
						gap={5}
					>
						<PrimaryText
							fontSize={32}
							fontWeight={900}
						>
							$300
						</PrimaryText>

						{!!totalAmount && (
							<PrimaryText
								fontSize={20}
								color={COLORS.primary.neutral_500}
								style={{ marginBottom: 5 }}
							>
								(${dollarAmount} per employee)
							</PrimaryText>
						)}
					</Box>

					<Button
						title="Next"
						onPress={() => onNext?.()}
						iconRight={
							<LuChevronRight
								color={COLORS.primary.white}
								size={20}
							/>
						}
						style={{
							paddingHorizontal: 24,
							paddingVertical: 12,
							text: {
								fontSize: 16,
								fontWeight: 900
							}
						}}
					/>
				</Box>
			</Box>
		</Modal>
	);
}

const CustomInput = ({ lebel, required, ...props }) => (
	<Box
		display="flex"
		flexDirection="column"
		mb={36}
	>
		<PrimaryText
			fontSize={16}
			fontWeight={900}
		>
			{lebel}
			{!!required && <span style={{ color: COLORS.primary.error }}>*</span>}
		</PrimaryText>
		<TextInput
			{...props}
			style={styles.textInput}
		/>
	</Box>
);

const styles = StyleSheet.create({
	textInput: {
		marginTop: 12,
		height: 30,
		width: 346,
		borderRadius: 15,
		borderWidth: 0.5,
		borderColor: COLORS.primary.neutral_400,
		fontSize: 14,
		paddingHorizontal: 14
	}
});
