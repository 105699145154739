// Sort rows by given heading
export const sortRows = ({ sortby, tableRows, ascending }) => {
	if (!sortby && !tableRows?.length) return;

	const isString = typeof tableRows[0][sortby] === "string";

	const rows = [...tableRows];

	rows?.sort((a, b) => {
		const firstValue = a[sortby] ?? 0,
			secondValue = b[sortby] ?? 0;

		// console.log({ firstValue, secondValue });

		if (ascending) {
			if (isString) {
				return firstValue.localeCompare(secondValue);
			}
			return firstValue < secondValue ? -1 : 1;
		}

		if (!ascending) {
			if (isString) {
				return secondValue.localeCompare(firstValue);
			}
			return firstValue > secondValue ? -1 : 1;
		}

		return 0;
	});

	return rows;
};
