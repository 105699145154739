import { firestore } from "utils/firebase";

export const listenForFundraiserChanges = (org, setFundraisers) => {
	return firestore
		.collection("fundraisers")
		.where("orgId", "==", org.id)
		.orderBy("lastUpdated", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let donations = [];

				querySnapshot.forEach((doc) => {
					donations.push({
						...doc.data(),
						id: doc.id
					});
				});

				console.log({ donations });

				let promises = donations.map((item, index) =>
					getUsersPromise(item, item.userId)
				);
				Promise.all(promises).then((users) => {
					console.log({ users });
					setFundraisers(users);
				});
			},
			(e) => {
				console.log("listenForRecurringDonationChanges err", e);
			}
		);
};

export const listenForDonationsToFundraiser = (org, userId, setDonations) => {
	return firestore
		.collection("donations")
		.where("fundraiserUserId", "==", userId + "-" + org.id)
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let donations = [];

				querySnapshot.forEach((doc) => {
					donations.push({
						...doc.data(),
						id: doc.id
					});
				});

				setDonations(donations);
			},
			(e) => {
				console.log("listenForDonationsToFundraiser err", e);
			}
		);
};

export const getUsersPromise = (obj, id) => {
	return new Promise((resolve, reject) => {
		firestore
			.collection("users")
			.doc(id)
			.get()
			.then((doc) => {
				//check if user active here
				if (doc.exists) {
					let user = {
						id: doc.id,
						...doc.data()
					};
					obj.user = user;
					resolve(obj);
				} else {
					resolve(obj);
				}
			});
	});
};
