import { Box, IconButton, Modal } from "@mui/material";
import { ArrowRight, CheckCircle, Close, Politics } from "assets/svgs";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import React, { useState } from "react";
import { StyleSheet, TextInput, TouchableOpacity } from "react-native-web";
import { depositFundsSuccess } from "services/businesses/business-wallet-service";
import { dollarFormatter } from "utils/helpers";

export default function DepositFundsModal({ isOpen, onClose, rayzeAccountBalance }) {

	const { rayzeAccount } = useBusinessDonations();

	const [paymentFrequency, setPaymentFrequency] = useState("one-time");
	const [amount, setAmount] = useState(0);

	function onAmountChange(text) {
		//change to number
		setAmount(Number(text.replace(/[^0-9.]/g, "")))
	}

	function depositFunds() {
		const success = depositFundsSuccess(rayzeAccount, amount)
		success.then(() => {
			onClose();
			setAmount(0);
		})
			.catch((e) => {
				console.log('error', e)
			})
	}

	return (
		<Modal
			open={isOpen}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
			onClose={onClose}
		>
			<Box
				width={500}
				pt={32}
				pb={48}
				bgcolor={COLORS.primary.white}
				display="flex"
				alignItems="center"
				flexDirection="column"
				borderRadius={3}
				position="relative"
			>
				<IconButton
					sx={{ position: "absolute", top: 16, right: 20 }}
					onClick={onClose}
				>
					<Close fill={COLORS.primary.gray} />
				</IconButton>
				<PrimaryText
					fontSize={24}
					fontWeight={900}
				>
					Deposit Funds
				</PrimaryText>

				<PrimaryText
					fontSize={16}
					color={COLORS.primary.neutral_500}
					style={{ marginTop: 10, marginBottom: 30 }}
				>
					Current Balance: {dollarFormatter.format(rayzeAccountBalance)}
				</PrimaryText>

				<PrimaryText
					fontSize={24}
					fontWeight={900}
				>
					Deposit Funds
				</PrimaryText>

				<Box
					display="flex"
					alignItems="flex-start"
				>
					<PrimaryText
						fontSize={32}
						fontWeight={900}
						style={{ marginTop: 14 }}
					>
						$
					</PrimaryText>
					<TextInput
						style={styles.textInput}
						value={amount}
						onChangeText={(text) => onAmountChange(text)}
					/>
				</Box>

				<PaymentFrequencyCard
					title="One-Time"
					caption="Single donation"
					amount={amount}
					onClick={() => setPaymentFrequency("one-time")}
					isActive={paymentFrequency === "one-time"}
				/>
				<PaymentFrequencyCard
					title="Monthly Recurring"
					caption="Per month, cancel anytime"
					type="recurring"
					amount={amount}
					onClick={() => setPaymentFrequency("recurring")}
					isActive={paymentFrequency === "recurring"}
				/>

				<TouchableOpacity
					style={{ flex: 1 }}
					onPress={depositFunds}
					disabled={amount <= 0}
				>
					<Box
						width={308}
						height={42}
						display="flex"
						flexDirection="row"
						alignItems="center"
						bgcolor={COLORS.primary.neutral_800}
						borderRadius={3}
						mt={32}
						justifyContent="center"
						position="relative"
						sx={{ cursor: "pointer", opacity: amount > 0 ? 1 : 0.5 }}
					>

						<Politics fill={COLORS.primary.neutral} />
						<PrimaryText
							fontSize={12}
							fontWeight={700}
							color={COLORS.primary.white}
							style={{ marginLeft: 8 }}
						>
							Bank Transfer
						</PrimaryText>

						<ArrowRight
							style={{
								position: "absolute",
								right: 0,
								padding: 10,
								paddingRight: 16
							}}
							fill={COLORS.primary.neutral}
						/>

					</Box>
				</TouchableOpacity>
			</Box>
		</Modal>
	);
}

const PaymentFrequencyCard = ({
	title,
	caption,
	amount,
	type,
	isActive,
	onClick
}) => (
	<Box
		display="flex"
		flexDirection="row"
		bgcolor={isActive ? COLORS.primary.lightestBlue : COLORS.primary.neutral}
		border={`1px solid ${isActive ? COLORS.primary.lightBlue : COLORS.primary.neutral_400
			}`}
		borderRadius={3}
		width={428}
		boxSizing="border-box"
		mt={20}
		py={18}
		pr={20}
		pl={isActive ? 24 : 56}
		onClick={onClick}
		justifyContent="space-between"
		sx={{ cursor: "pointer" }}
	>
		<Box
			display="flex"
			alignItems="center"
		>
			{isActive && (
				<CheckCircle
					fill={COLORS.primary.lightBlue}
					width={20}
					height={20}
				/>
			)}
			<Box
				display="flex"
				flexDirection="column"
				gap={4}
				ml={isActive ? 12 : 0}
			>
				<PrimaryText
					fontSize={14}
					fontWeight={700}
				>
					{title}
				</PrimaryText>
				<PrimaryText
					fontSize={10}
					fontWeight={300}
				>
					{caption}
				</PrimaryText>
			</Box>
		</Box>

		<Box
			display="flex"
			alignItems="flex-start"
		>
			<PrimaryText
				fontSize={8}
				fontWeight={700}
				style={{ marginTop: 1, marginRight: 2 }}
			>
				$
			</PrimaryText>
			<PrimaryText
				fontSize={14}
				fontWeight={700}
			>
				{amount}
				{type === "recurring" ? "/mo" : ""}
			</PrimaryText>
		</Box>
	</Box>
);

const styles = StyleSheet.create({
	textInput: {
		fontSize: 72,
		fontWeight: 900,
		width: 70
	}
});
