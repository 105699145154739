import React, { createContext, useState, useEffect, useContext } from "react";
import { useCauses } from "./CausesContext";
import { getOurCauses } from "services/causes-service";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { allCauses } = useCauses();

  const [selectedUser, setSelectedUser] = useState(null);
  const [causes, setCauses] = useState(null);
  const [skills, setSkills] = useState(null);

  //set selected user causes
  useEffect(() => {
    if (!selectedUser || !allCauses) return;
    getOurCauses(selectedUser, allCauses, setCauses);
  }, [selectedUser, allCauses]);

  return (
    <UserContext.Provider
      value={{
        selectedUser,
        setSelectedUser,
        causes,
        skills,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUsers = () => useContext(UserContext);
