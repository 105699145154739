import { Box } from "@mui/material";
import SaveAndCancelBtns from "components/Buttons/SaveAndCancelBtns";
import SelectCauses from "components/CausesSkills/SelectCauses";
import { PrimaryText } from "components/Common/PrimaryText";
import { FullModal } from "components/Modals/FullModal";
import { COLORS } from "constants/Colors";
import React, { useState } from "react";

export default function SelectCausesModal({
	isOpen,
	onCancel,
	onSave,
	causes
}) {
	const [selectedCauses, setselectedCauses] = useState(
		causes?.length ? causes : []
	);

	if (!isOpen) return null;

	return (
		<FullModal
			title="Select Causes"
			setShowModal={onCancel}
		>
			<Box px={40}>
				<PrimaryText
					fontSize={16}
					fontWeight={900}
				>
					Select Causes{" "}
					<PrimaryText fontWeight={400}>
						(Limit 5)<span style={{ color: COLORS.primary.error }}>*</span>
					</PrimaryText>
				</PrimaryText>

				<SelectCauses
					maxWidth={705}
					selectedCauses={selectedCauses}
					onChange={(e) => setselectedCauses(e)}
					maxLimit={5}
				/>

				<SaveAndCancelBtns
					boxProps={{
						flexDirection: "row-reverse",
						mt: 50
					}}
					saveProps={{ title: "Confirm" }}
					onCancel={onCancel}
					onSave={() => onSave(selectedCauses)}
				/>
			</Box>
		</FullModal>
	);
}
