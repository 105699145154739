import React from "react";
import { Radio, Stack, TableCell, TableRow } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { TouchableOpacity } from "react-native-web";
import { ProfilePic } from "components/Pics/ProfilePic";
import { getUsersName } from "utils/helpers";

export default function EmployeeSelectionCard(props) {
	const {
		user,
		selected,
		onPress
	} = props
	const {
		role,
		jobTitle
	} = user
	return (
		<TableRow
		onClick={() => onPress(user)}
		>
			
			<TableCell>
				
					<Stack
						direction="row"
						alignItems="center"
					>
						<ProfilePic size={36} userId={user.id} />
						<PrimaryText
							fontSize={20}
							fontWeight={900}
							style={{ marginLeft: 14 }}
						>
							{getUsersName(user)}
						</PrimaryText>
					</Stack>
				
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{role}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{jobTitle}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<Radio
					sx={{ color: COLORS.primary.lightBlue }}
					size="small"
					checked={selected}
				/>
			</TableCell>
			
		</TableRow>
	);
}
