import { StyleSheet, View, Linking } from "react-native";
import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import TextButton from "components/Buttons/TextButton";
import { FiWatch } from "react-icons/fi";
import { Links } from "constants/Links";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "context/LoadingContext";

const { gray, black, neutral_500 } = COLORS.primary;

export default function CheckingCrossed() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  setLoading(false);
  // console.log('3', state)
  return (
    <View style={styles.container}>
      <FiWatch size={92} color={neutral_500} />

      <PrimaryText
        fontSize={24}
        fontWeight="900"
        style={{ textAlign: "center", marginTop: 30 }}
      >
        We’re just checking to make sure you’ve
        <br />
        crossed your i’s and dotted your t’s.
      </PrimaryText>

      <PrimaryText
        fontSize={20}
        style={{ textAlign: "center", marginTop: 10, color: gray }}
      >
        You should receive an email from us
        <br />
        within 2-3 business days
      </PrimaryText>

      <PrimaryButton
        title="Continue"
        style={{ marginTop: 57 }}
        onPress={() => navigate(Links.AccountCreatedStep2, { state })}
      />

      <PrimaryText fontSize={17} style={{ color: black, marginTop: 117 }}>
        Questions? Contact us at{" "}
        <TextButton
          text="support@rayzeapp.com"
          onPress={() =>
            Linking.openURL("mailto:support@rayzeapp.com", "_blank").catch(
              (err) => alert(err.message)
            )
          }
        />
      </PrimaryText>

      {/* <PrimaryButton
        title="Back"
        variant="outlined"
        style={{ marginTop: 84 }}
      /> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: 500,
    height: 2,
    backgroundColor: gray,
    marginTop: 35,
  },
  uploadFileView: {
    width: 485,
    height: 112,
    borderRadius: 12,
    backgroundColor: COLORS.primary.neutral,
    marginTop: 14,
    justifyContent: "center",
    alignItems: "center",
  },
});
