import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowDimensions } from "react-native-web";
import { BREAKPOINTS } from "utils/breakpoints";
import TermsAndPrivacyText from "components/Common/TermsAndPrivacyText";

export function InviteNewOrExistingAccount() {
  const navigate = useNavigate();
  const { state = {} } = useLocation();
  const { invitation = {} } = state || {};
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();

  function setupNewAccount() {
    navigate(Links.InviteNewAccount, { state: { invitation, ...state } });
  }

  function linkWorkEmail() {
    navigate(Links.InviteExistingAccount, { state: { invitation, ...state } });
  }

  const styles = style({ width });

  return (
    <View style={styles.container}>
      <img
        src={require("assets/rayze.png")}
        width={width > BREAKPOINTS["sm"] ? 78 : 47}
      />

      <PrimaryText
        fontSize={width > BREAKPOINTS["sm"] ? 54 : 32}
        fontWeight="900"
        style={{ marginTop: 31 }}
      >
        Welcome to Rayze!
      </PrimaryText>

      <PrimaryText
        fontSize={width > BREAKPOINTS["sm"] ? 32 : 20}
        fontWeight={400}
        style={{
          textAlign: "center",
          color: COLORS.primary.gray,
          maxWidth: 846,
          marginTop: width > BREAKPOINTS["sm"] ? 20 : 26,
          marginBottom: 68,
        }}
      >
        Are you setting up a brand new account or linking your current account?
      </PrimaryText>

      <PrimaryButton
        title="Setup New Account"
        onPress={setupNewAccount}
        style={{ marginBottom: 0, maxWidth: 370, width: "100%" }}
      />

      <PrimaryText
        fontSize={16}
        fontWeight={400}
        style={{
          textAlign: "center",
          color: COLORS.primary.gray,
          marginTop: 15,
          maxWidth: 508,
          lineHeight: "150%",
        }}
      >
        This will setup a new account and will be linked to the email you
        verified. Your company will only have access to donations you wish to
        have matched and nothing more.
      </PrimaryText>

      <View style={styles.separator} />

      <PrimaryButton
        title="Link Work Email to Current Account"
        onPress={linkWorkEmail}
        style={{
          maxWidth: 370,
          width: "100%",
          paddingHorizontal: 0,
        }}
        variant="outlined"
        titleStyle={{ color: COLORS.primary.darkBlue }}
      />

      <PrimaryText
        fontSize={width > BREAKPOINTS["sm"] ? 16 : 12}
        fontWeight={800}
        style={{
          textAlign: "center",
          color: COLORS.primary.neutral_500,
        }}
      >
        Only for current Rayze Users
      </PrimaryText>

      <PrimaryText
        fontSize={width > BREAKPOINTS["sm"] ? 16 : 12}
        fontWeight={400}
        style={{
          textAlign: "center",
          color: COLORS.primary.gray,
          maxWidth: 594,
        }}
      >
        This will link your corporate email to your current Rayze account
        streamlining your ability to have donations matched. Your company will
        only have access to donations you wish to have matched and nothing more.
      </PrimaryText>

      <TermsAndPrivacyText style={{ marginTop: 75 }} />

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={COLORS.primary.blue}
        />
      )}
    </View>
  );
}

const style = ({ width }) =>
  StyleSheet.create({
    container: {
      backgroundColor: COLORS.primary.white,
      justifyContent: width > BREAKPOINTS["sm"] ? "center" : "flex-start",
      alignItems: "center",
      width: "100%",
      height: "100%",
      paddingTop: width > BREAKPOINTS["sm"] ? 0 : 19,
    },

    separator: {
      width: 145,
      height: 1,
      backgroundColor: COLORS.primary.neutral_500,
      marginVertical: 48,
    },
  });
