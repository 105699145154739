import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Dimensions,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native-web";
import { COLORS } from "constants/Colors";
import { useNavigate } from "react-router";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  Grid,
} from "@mui/material";
import * as FiIcons from "react-icons/fi";
import Misc from "assets/misc";
import { useBusinessNonprofits } from "context/Business/BusinessNonprofitsContext";
import { NavHeader } from "components/Headers/NavHeader";
import MuiButton from "components/Common/MuiButton";
import { DonateModal } from "components/Modals/DonateModal";

const { height } = Dimensions.get("window");

export const NonprofitDetail = () => {
  const navigate = useNavigate();
  const { selectedNonprofit } = useBusinessNonprofits();

  const [showDonateModal, setShowDonateModal] = useState(false);

  const orgDetails = [
    {
      title: "Website",
      value: selectedNonprofit?.website,
    },
    {
      title: "Public Address",
      value: `${selectedNonprofit?.address?.line1 ? selectedNonprofit?.address?.line1 + "\n" : ""
        }${selectedNonprofit?.address?.city}, ${selectedNonprofit?.address?.state + (selectedNonprofit?.address?.zipCode ? "," : "")
        } ${selectedNonprofit?.address?.zipCode || ""} `,
      capitalize: true,
      // value: "1234 Fake Street Lumberton, SC 28358",
    },
    {
      title: "Mailing Address",
      value: selectedNonprofit?.addressMailing,
      capitalize: true,
      // value: "1234 Fake Street Lumberton, SC 28358",
    },
    {
      title: "EIN",
      value: selectedNonprofit?.ein,
    },
    {
      title: "Phone",
      value: selectedNonprofit?.phone,
    },
    {
      title: "Email",
      value: selectedNonprofit?.adminEmail,
    },
  ];

  function onBack() {
    navigate(-1);
  }

  return (
    <ScrollView style={{ width: "100%", maxHeight: height - 80 }}>
      {!selectedNonprofit ? (
        <View style={{ flexGrow: 1, height: height - 100 }}>
          <ActivityIndicator
            animating
            size={"large"}
            style={styles.activity}
            color={COLORS.primary.lightBlue}
          />
        </View>
      ) : (
        <>
          <View style={styles.container}>

            <NavHeader title={selectedNonprofit?.name} onBack={onBack} />

            <Grid container mb={50} style={{ marginTop: 24 }}>
              <Grid item xs={12} md={6} px="5%">
                <View style={styles.statsPhoto}>
                  <View style={styles.cover_img_v}>
                    <Image
                      style={{ width: "100%", height: "100%", borderRadius: 12 }}
                      source={{ uri: selectedNonprofit?.coverPhoto || Misc.cover }}
                      resizeMode={"contain"}
                    />
                  </View>
                </View>
              </Grid>
              <Grid item xs={12} md={6} px="5%">
                <View
                  flexDirection="row"
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 12,
                  }}
                >
                  <MuiButton
                    title={"Donate"}
                    startIcon={<FiIcons.FiDollarSign size={15} />}
                    variant="contained"
                    sx={{
                      backgroundColor: COLORS.primary.green,
                    }}
                    onPress={() => setShowDonateModal(true)}
                  />
                </View>

                <View style={{ paddingTop: 8 }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 12 }}>
                    <PrimaryText fontSize={32} fontWeight={900}>
                      {selectedNonprofit?.name}
                    </PrimaryText>
                    {selectedNonprofit?.verified && (
                      <View style={{ marginLeft: 12 }}>
                        <Image
                          style={{ width: 24, height: 24, tintColor: COLORS.primary.sunflower }}
                          source={require("assets/verification.png")}
                          resizeMode={"contain"}
                        />
                      </View>
                    )}
                  </View>
                  <PrimaryText fontSize={16} fontWeight={400}>
                    {selectedNonprofit?.about}
                  </PrimaryText>
                </View>


              </Grid>
            </Grid>

            {/** ROW 1 */}
            {/* <View style={[styles.info, { marginTop: 24 }]}>
              <View style={styles.statsPhoto}>
                <View style={styles.cover_img_v}>
                  <Image
                    style={{ width: "100%", height: "100%", borderRadius: 12 }}
                    source={{ uri: selectedNonprofit?.coverPhoto }}
                    resizeMode={"contain"}
                  />
                </View>
              </View>
              <View style={styles.stats}>
                {statistics.map((row, i) => {
                  return (
                    <View style={{ justifyContent: "space-between" }} key={i}>
                      {row.map((item, i) => {
                        const { title, amount } = item;
                        return (
                          <View style={styles.statContainer} key={i}>
                            <PrimaryText
                              fontSize={18}
                              fontWeight={400}
                              style={styles.header}
                            >
                              {title}
                            </PrimaryText>
                            <PrimaryText
                              fontSize={24}
                              fontWeight={700}
                              style={styles.header}
                            >
                              {amount}
                            </PrimaryText>
                          </View>
                        );
                      })}
                    </View>
                  );
                })}
              </View>
            </View> */}

            {/** ROW 2 */}
            <View style={styles.info}>
              <View style={styles.infoContainer}>
                {orgDetails.map((u, i) => {
                  const { title, value } = u;
                  if (!value) return;
                  return (
                    <View style={styles.infoTextRow} key={i}>
                      <View
                        style={{ marginRight: 12 }}
                        className="text-right mr-4"
                      >
                        <PrimaryText
                          fontSize={14}
                          fontWeight={400}
                          style={{ width: 180, textAlign: "right" }}
                        >
                          {title}
                        </PrimaryText>
                      </View>
                      <PrimaryText
                        fontSize={16}
                        fontWeight={600}
                        style={u?.capitalize && { textTransform: "capitalize" }}
                      >
                        {value}
                      </PrimaryText>
                    </View>
                  );
                })}
              </View>

              <ScrollView style={styles.causesContainer}>
                <PrimaryText fontSize={16} fontWeight={600}>
                  {"Causes"}
                </PrimaryText>
                <View style={styles.causes}>
                  {selectedNonprofit?.causes && selectedNonprofit?.causes.map((item) => (
                    <View style={styles.cause} key={item.id}>
                      <PrimaryText
                        fontSize={14}
                        fontWeight={400}
                        style={{ color: COLORS.primary.lightBlue }}
                      >
                        {item}
                      </PrimaryText>
                      {/* <FaIcons.FaTimes /> */}
                    </View>
                  ))}
                </View>
              </ScrollView>
            </View>

          </View>

        </>
      )}

      {showDonateModal && (
        <DonateModal
          isVisible={showDonateModal}
          setIsVisible={setShowDonateModal}
          selectedNonprofit={selectedNonprofit}
        />
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  activity: {
    flex: 1,
    width: "100%",
    backgroundColor: COLORS.primary.white,
  },
  container: {
    flex: 1,
    width: "100%",
    paddingVertical: 20,
    backgroundColor: COLORS.primary.white,
  },
  cover_img_v: {
    width: "100%",
    aspectRatio: 16 / 9,
  },
  statsPhoto: {
    width: "100%",
  },
  header: {
    color: COLORS.primary.darkBlue,
  },
  stats: {
    flex: 1,
    flexDirection: "row",
    padding: 27,
    justifyContent: "space-around",
    // justifyContent:'center',
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    marginLeft: 16,
  },
  statContainer: {
    alignItems: "center",
  },
  info: {
    flexDirection: "row",
    marginBottom: 16,
    maxHeight: 300,
  },
  infoContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "space-around",
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
  },
  infoTextRow: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 6,
  },
  causesContainer: {
    flex: 1,
    // height: 200,
    padding: 20,
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    marginLeft: 16,
  },
  causes: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: 20,
    paddingTop: 12,
  },
  cause: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginRight: 8,
    marginBottom: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLORS.primary.lightBlue,
  },
  verificationStatusContainer: {
    flex: 1,
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 12,
  },
  tabs: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  tabsButton: {
    width: 168,
    height: 36,
    backgroundColor: COLORS.primary.gray,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    marginRight: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  tabActive: {
    backgroundColor: COLORS.primary.offWhite,
    color: "black",
  },
  tabText: {
    textAlign: "center",
    color: COLORS.primary.white,
    fontWeight: "500",
  },
  tabTextActive: {
    color: COLORS.primary.darkBlue,
    fontWeight: "700",
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    // margin: 12,
    borderRadius: 6,
  },
  saveButton: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.green,
    // width: 200,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
});
