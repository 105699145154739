import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { COLORS } from "constants/Colors";
import * as Geocode from "react-geocode";
import { useDisplay } from "context/DisplayContext";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  FaCheck,
  FaEdit,
  FaPencilAlt,
  FaRegCheckSquare,
  FaRegSquare,
} from "react-icons/fa";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Cropper from "react-easy-crop";
import getCroppedImg from "screens/Settings/cropImage";
import { Dimensions, Image, TouchableOpacity } from "react-native-web";
import { CustomInput } from "components/Inputs/CustomInput";
import { useOrganization } from "context/OrganizationContext";
import { CausesSelect } from "components/CausesSkills/CausesSelect";
import { FullModal } from "components/Modals/FullModal";
import { Button } from "components/Common/Button";
import './styles.css'
import { RiAlertFill } from "react-icons/ri";
import { dollarFormatter } from "utils/helpers";
import SelectCauses from "components/CausesSkills/SelectCauses";

const { height } = Dimensions.get('window');
const geofire = require("geofire-common");



export const OrgInfoForm = (props) => {
  const {
    setCoverPhotos,
    coverPhotos,
    profilePic,
    setProfilePic,
    details,
    setDetails,
    adminEmailErr,
    phoneErr,
    useMailingAddress,
    setUseMailingAddress,
    selectedCauses,
    setSelectedCauses,
    business = false,
    customDonationAmounts,
    setCustomDonationAmounts
  } = props;
  const { darkMode } = useDisplay();
  const { selectedOrg } = useOrganization();

  const [value, setValue] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [zoomProfilePic, setZoomProfilePic] = useState(1);
  const [croppedCoverPhotoArea, setCroppedCoverPhotoArea] = useState(null);
  const [croppedCoverPhoto, setCroppedCoverPhoto] = useState(coverPhotos[0]);
  const [croppedProfilePhotoArea, setCroppedProfilePhotoArea] = useState(null);
  const [croppedProfilePhoto, setCroppedProfilePhoto] = useState(profilePic[0]);

  const [showPhotoModal, setShowPhotoModal] = useState(false);

  const [errors, setErrors] = useState([]);
  const [addressError, setAddressError] = useState(null);



  const { line1 = "", city = "", state = "", zipCode = "" } = props?.details?.address || {};
  useEffect(() => {
    setAddressError(null);
    city &&
      setValue({
        label: `${line1 ? capitalize(line1) + ", " : ""} ${capitalize(
          city
        )}, ${capitalize(state)} ${zipCode || ""}`,
      });

    if (details?.address?.zipCode && !details.address.city) {
      setAddressError("Please enter a valid physical address. Try searching for your address including zip code")
    }
  }, [props?.details?.address]);

  function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  const getLatLong = (searchText) => {
    setValue(searchText);
    const placeId = searchText?.value?.place_id;
    // console.log('searchText', searchText)
    if (!searchText) return;
    Geocode.fromPlaceId(placeId)
      .then((json) => {
        var addressComponent = json.results[0].address_components;
        var streetNumber;
        var fullAddress;
        var city;
        var state;
        var zipCode;
        const latLng = json.results[0].geometry.location;
        // console.log('address component', addressComponent)
        let foundCity = false
        addressComponent.forEach((comp, index) => {
          // console.log(JSON.stringify(comp))
          let types = comp.types
          if (types.find((x) => x === "street_number")) {
            streetNumber = comp.long_name + " "
          } else if (types.find((x) => x === "route")) {
            if (streetNumber !== null) {
              fullAddress = streetNumber + comp.long_name
            }
          } else if (types.find((x) => x === 'locality')) {
            foundCity = true
            city = comp.long_name
          } else if (types.find((x) => x === "administrative_area_level_1")) {
            state = comp.long_name
          } else if (types.find((x) => x === "postal_code")) {
            zipCode = comp.long_name
          }
          // if (!foundCity) {
          //   if (types.find((x) => x === 'administrative_area_level_2')) {
          //     foundCity = true
          //     city = comp.long_name
          //   } else if (!foundCity) {
          //     if (types.find((x) => x === 'administrative_area_level_1')) {
          //       foundCity = true
          //       city = comp.long_name
          //     }
          //   }
          // }
        })
        const { lat, lng } = latLng;
        const hash = geofire.geohashForLocation([lat, lng]);
        setDetails({
          ...details,
          address: {
            line1: fullAddress,
            city: city,
            zipCode: zipCode,
            state: state,
          },
          latLng: latLng,
          hash
        });
      })
      .catch((e) => {
        console.log("error getting lat lng", e);
      });
  };

  function onSelectCoverPhoto(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setZoom(1);
          setCroppedCoverPhoto(reader.result.toString() || "")
          setShowPhotoModal('coverPhoto');
        }
        // setCrop(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onCropCoverPhotoComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedCoverPhotoArea(croppedAreaPixels);
    },
    []
  );

  function onSelectProfilePhoto(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setZoomProfilePic(1);
          setCroppedProfilePhoto(reader.result.toString() || "")
          setShowPhotoModal('profilePhoto');
        }
        // setCrop(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onCropProfilePhotoComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedProfilePhotoArea(croppedAreaPixels);
    },
    []
  );

  const showCroppedImage = useCallback(
    async (type) => {
      try {
        if (type === "coverPhoto") {
          const finalImage = await getCroppedImg(
            croppedCoverPhoto,
            croppedCoverPhotoArea,
            rotation
          );
          setCoverPhotos([finalImage]);
        } else {
          const finalImage = await getCroppedImg(
            croppedProfilePhoto,
            croppedProfilePhotoArea,
            rotation
          );
          setProfilePic([finalImage]);
        }
      } catch (e) {
        console.error(e);
      }
      setShowPhotoModal(false);
    },
    [croppedCoverPhotoArea, croppedProfilePhotoArea, rotation]
  );

  useEffect(() => {
    // setZoom(1)
  }, [showPhotoModal])

  // useEffect(() => {
  //   setCroppedCoverPhoto(coverPhotos[0])
  //   setCroppedProfilePhoto(profilePic[0])
  // }, [selectedOrg])

  const einErr = details?.ein?.length !== 10 ? "Please enter a valid EIN" : null;


  const { type } = details;

  // console.log('details', details)

  const style = styles(darkMode);

  return (
    <View style={style.container_v}>


      <View style={{ flexDirection: "row" }}>

        {/** LEFT COLUMN */}
        <View style={{ flex: 1 }}>
          {/** Name */}
          <View style={style.form_item_v}>
            <CustomInput
              mandatory
              title={`Organization Name`}
              placeholder={"Enter organization name"}
              value={details.name}
              // maxLength={24}
              onChangeText={(text) =>
                setDetails({
                  ...details,
                  name: text,
                })
              }
              name
            />
          </View>

          {/** ADDRESS */}
          <View style={[style.form_item_v, { zIndex: 998 }]}>
            <PrimaryText fontSize={20} fontWeight={700} style={style.textInput}>
              {business ? "Address*" : "Address* (will be displayed on map)"}
            </PrimaryText>
            <GooglePlacesAutocomplete
              autocompletionRequest={{
                // types: ["address"],
              }}
              selectProps={{
                isClearable: true,
                value: value,
                onChange: getLatLong,
                styles: {
                  dropdownIndicator: false,
                  option: (provided) => ({
                    ...provided,
                    color: COLORS.primary.darkGray,
                    // height: 40,
                    paddingHorizontal: 16,
                    fontSize: 12,
                    fontWeight: 300,
                    backgroundColor: COLORS.primary.white,
                    fontFamily: "Montserrat",
                  }),
                  control: (provided) => {
                    // console.log(provided)
                    return {
                      ...provided,
                      minHeight: undefined,
                      padding: 0,
                      paddingTop: 1,
                      // paddingBottom: 10,
                      // margin: 0,
                      borderColor: COLORS.primary.lightGray,
                      borderWidth: 1,
                      backgroundColor: COLORS.primary.offWhite,
                      borderRadius: 15,
                      overflow: "hidden",
                      height: 32,
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                    };
                  },
                  indicatorsContainer: (provided) => {
                    return {};
                  },
                  clearIndicator: (provided) => {
                    // console.log("clear", provided);
                    return {
                      ...provided,
                      padding: 0,
                      marginTop: "12%",
                      boxSizing: undefined,
                      display: undefined,
                    };
                  },
                  dropdownIndicator: (provided) => {
                    return {
                      ...provided,
                      padding: 0,
                      marginRight: 4,
                    };
                  },
                  placeholder: (provided) => {
                    return {
                      ...provided,
                    };
                  },
                  singleValue: (provided, state) => {
                    // console.log("sing value", provided);
                    return {
                      ...provided,
                      paddingLeft: 2,
                      fontSize: 14,
                      fontWeight: 300,
                      fontFamily: "Montserrat",
                      // backgroundColor: "red",
                    };
                  },
                  valueContainer: (provided, state) => {
                    // console.log('value cont', provided)
                    return {
                      ...provided,
                      top: state.hasValue ? "-34%" : undefined,
                    };
                  },
                },
                placeholder: "Select address...",
              }}
              apiKey={"AIzaSyDR0wfcPs5HkIoR4iJTVP8K-srlpOn-Yhs"}
            />
          </View>
          <PrimaryText fontSize={14} fontWeight={400} style={[style.textInput, { marginTop: -8, marginBottom: 12 }]}>
            Note: This address cannot be a PO Box
          </PrimaryText>

          {type !== 'community' && (
            <View style={style.form_item_v}>
              <TouchableOpacity
                style={{ marginBottom: 12, flexDirection: "row" }}
                onPress={() => {
                  // console.log(details?.address)

                  if (!(details?.address.city && !details?.address.line1) && !(details?.address?.zipCode && !details.address.city)) {
                    setUseMailingAddress(!useMailingAddress)
                  } else {
                    if (details?.address.city && !details?.address.line1) {
                      setAddressError("Please enter a valid physical address.");
                    } else if (details?.address?.zipCode && !details.address.city) {
                      setAddressError("Please enter a valid physical address. Try searching for your address including zip code");
                    }
                  }
                }
                }
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {!useMailingAddress ? (
                    <FaRegCheckSquare
                      width={16}
                      height={16}
                      // fill={COLORS.primary.error}
                      stroke={COLORS.primary.white}
                    />
                  ) : (
                    <FaRegSquare
                      width={16}
                      height={16}
                      // fill={COLORS.primary.error}
                      stroke={COLORS.primary.white}
                    />
                  )}

                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={{ marginLeft: 6, marginTop: 2 }}
                  >
                    {!business ? "Address matches your IRS document" : "Mailing address matches physical address"}
                  </PrimaryText>
                </View>

              </TouchableOpacity>
              {addressError ? (
                <View style={style.errorContainer}>
                  <RiAlertFill
                    width={16}
                    height={16}
                    fill={COLORS.primary.error}
                    stroke={COLORS.primary.white}
                  />
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={style.errorMessage}
                  >
                    {addressError}
                  </PrimaryText>
                </View>
              ) : null}
            </View>
          )}
          {useMailingAddress && (
            <View style={style.form_item_v}>
              <CustomInput
                mandatory
                title={`Documented Mailing Address`}
                placeholder={"Enter mailing address"}
                value={details?.addressMailing}
                onChangeText={(text) =>
                  props.setDetails({
                    ...props.details,
                    addressMailing: text,
                  })
                }
              />
            </View>
          )}

          {/** Phone */}
          {!business &&
            <View style={style.form_item_v}>
              <CustomInput
                mandatory
                title={`Phone Number`}
                placeholder={"Enter organization phone number"}
                value={details?.phone}
                maxLength={10}
                onChangeText={(text) =>
                  setDetails({
                    ...details,
                    phone: text.replace(/[^0-9+]/g, ""),
                  })
                }
                errorMessage={phoneErr}
              />
            </View>
          }

          {/** Website */}
          <View style={style.form_item_v}>
            <CustomInput
              title={`Website`}
              placeholder={"Enter URL"}
              value={details?.website}
              onChangeText={(text) =>
                setDetails({
                  ...details,
                  website: text,
                })
              }
            />
          </View>

          {/** Admin Email */}
          {!business &&
            <View style={style.form_item_v}>
              <CustomInput
                mandatory
                title={`Admin Email`}
                placeholder={"Enter administrator email"}
                value={details?.adminEmail}
                onChangeText={(text) =>
                  setDetails({
                    ...details,
                    adminEmail: text,
                  })
                }
                errorMessage={adminEmailErr}
              />
            </View>
          }

          {/** EIN */}
          {type === 'nonprofit' &&
            <View style={style.form_item_v}>
              <CustomInput
                disabled={selectedOrg?.verified}
                mandatory
                title={`Employee Identification Number (EIN)`}
                placeholder={"Enter Company EIN"}
                value={details?.ein}
                onChangeText={(text) => {
                  let trimmed = text.length > 10 ? text.substring(0, 10) : text;
                  if (trimmed?.length > 2) {
                    trimmed = trimmed.replace("-", "");
                    trimmed =
                      trimmed.substring(0, 2) + "-" + trimmed.substring(2, 10);
                  }

                  setDetails({
                    ...details,
                    ein: trimmed.toUpperCase(),
                  });
                }}
                errorMessage={einErr}
              />
            </View>
          }

          {/** About */}
          <View style={[style.form_item_v, { zIndex: 0 }]}>
            <CustomInput
              mandatory
              multiline
              title={`About`}
              maxLength={500}
              placeholder={"Describe your event\n(500 characters max)"}
              value={details.about}
              onChangeText={(text) =>
                setDetails({
                  ...details,
                  about: text,
                })
              }
            />
          </View>


          <PrimaryText
            fontSize={20}
            fontWeight={700}
            style={{ color: COLORS.primary.neutral_500 }}
          >
            Custom Donations Amounts
          </PrimaryText>

          {customDonationAmounts.map((item, index) => (
            <View
              style={style.customDonation}
              key={index}
            >
              <PrimaryText
                fontSize={20}
                fontWeight={700}
                style={style.sequence}
              >
                {index + 1}.
              </PrimaryText>

              <View>
                <CustomInput
                  placeholder={dollarFormatter.format(item?.amount)}
                  value={item?.amount}
                  onChangeText={(text) => {
                    let newCustomDonationAmounts = [...customDonationAmounts]
                    newCustomDonationAmounts[index].amount = Number(text.replace(/[^0-9+]/g, ""))
                    setCustomDonationAmounts(newCustomDonationAmounts)
                  }}
                  errorMessage={!item?.amount ? 'Please enter a valid amount' : index === 0 ? '' : customDonationAmounts[index - 1]?.amount >= item?.amount ? 'Amount must be higher than previous entry' : ''}
                />

                {item.default && (
                  <PrimaryText
                    fontSize={12}
                    fontWeight={600}
                    style={style.defaultDonationText}
                  >
                    Default Donation
                  </PrimaryText>
                )}
              </View>
            </View>
          ))}
        </View>




        {/** RIGHT COLUMN */}
        <View style={{ flex: 1, marginLeft: 36 }}>
          {/* <View
            style={{
              // flexDirection: "row",
              alignItems: "flex-end",
              marginBottom: 12,
              backgroundColor: 'blue'
            }}
          > */}
          {/** COVER PHOTO */}
          <View style={{ flexGrow: 0}}>
            <PrimaryText fontSize={20} fontWeight={700} style={style.textInput}>
              Cover Photo*:
            </PrimaryText>
            <View style={[style.settingsRow, { marginLeft: 0, marginBottom: 8 }]}>
              <TouchableOpacity
                disabled={coverPhotos[0]}
                onPress={() => document.getElementById("choose-cover-photo").click()}
                style={[
                  style.cover_img_v,
                  !coverPhotos[0] && {
                    borderWidth: 1,
                    borderColor: "lightgray",
                    borderRadius: 12,
                  },
                ]}
              >
                <img
                  style={{
                    width: coverPhotos[0] ? "100%" : undefined,
                    height: "100%",
                    borderRadius: 12,
                    objectFit: "contain",
                    alignSelf: "center",
                  }}
                  src={
                    coverPhotos[0]
                      ? coverPhotos[0].uri
                        ? coverPhotos[0]?.uri
                        : coverPhotos[0]
                      : require("assets/misc/emptyPicRect.png")
                  }
                />
                {!coverPhotos[0] && (
                  <Image
                    source={require("assets/misc/plusCircle.png")}
                    style={{
                      width: 24,
                      height: 24,
                      position: "absolute",
                      bottom: '46%',
                      right: '47%',
                    }}
                  />
                )}
              </TouchableOpacity>


            </View>
            <input
              id="choose-cover-photo"
              type="file"
              accept="image/*"
              onChange={onSelectCoverPhoto}
              style={{ display: "none" }}
            />
            {coverPhotos[0] && (
              <TouchableOpacity
                style={{ alignSelf: "flex-start", marginLeft: 4 }}
                onPress={() => document.getElementById("choose-cover-photo").click()}
              >

                <Button
                  title={"Edit/Change"}
                  style={{
                    paddingVertical: 4,
                    text: {
                      fontSize: 12,
                      fontWeight: 700,
                    },
                  }}
                  iconLeft={<FaEdit size={14} color={COLORS.primary.white} style={{ marginRight: 4 }} />}
                  onPress={() => {
                    // setZoom(1)
                    setShowPhotoModal('coverPhoto')
                  }}
                />
              </TouchableOpacity>
            )}
          </View>

          {/** PROFILE PHOTO */}
          {!business &&
            <View style={{ flexGrow: 0, marginTop: 24, marginBottom: 24 }}>
              <PrimaryText fontSize={20} fontWeight={700} style={style.textInput}>
                Profile Photo:
              </PrimaryText>
              <View style={[style.settingsRow, { marginLeft: 0, marginBottom: 8 }]}>

                <TouchableOpacity
                  disabled={profilePic[0]}
                  onPress={() => document.getElementById("choose-profile-photo").click()}
                  style={[
                    style.profilePhoto,
                    !profilePic[0] && {
                      borderWidth: 1,
                      borderColor: "lightgray",
                      borderRadius: 12,
                    },
                  ]}
                >
                  <img
                    style={{
                      width: profilePic[0] ? "100%" : undefined,
                      height: "100%",
                      borderRadius: 12,
                      objectFit: "contain",
                      alignSelf: "center",
                    }}
                    src={
                      profilePic[0]
                        ? profilePic[0].uri
                          ? profilePic[0]?.uri
                          : profilePic[0]
                        : require("assets/misc/emptyPicRect.png")
                    }
                  />
                  {!profilePic[0] && (
                    <Image
                      source={require("assets/misc/plusCircle.png")}
                      style={{
                        width: 24,
                        height: 24,
                        position: "absolute",
                        bottom: '43%',
                        right: '43%',
                      }}
                    />
                  )}
                </TouchableOpacity>

              </View>
              <input
                id="choose-profile-photo"
                type="file"
                accept="image/*"
                onChange={onSelectProfilePhoto}
                style={{ display: "none" }}
              />
              {profilePic[0] && (
                <TouchableOpacity
                  style={{ alignSelf: "flex-start", marginLeft: 4 }}
                  onPress={() => document.getElementById("choose-profile-photo").click()}
                >

                  <Button
                    title={"Edit/Change"}
                    style={{
                      paddingVertical: 4,
                      text: {
                        fontSize: 12,
                        fontWeight: 700,
                      },
                    }}
                    iconLeft={<FaEdit size={14} color={COLORS.primary.white} style={{ marginRight: 4 }} />}
                    onPress={() => {
                      // setZoom(1)
                      setShowPhotoModal('profilePhoto')
                    }}
                  />
                </TouchableOpacity>
              )}
            </View>
          }
          {/* </View> */}


          {!business && (
            <>
              <PrimaryText
                fontSize={20}
                fontWeight={800}
                style={{ color: COLORS.primary.neutral_500 }}
              >
                {"Causes "}
                <PrimaryText
                  fontSize={20}
                  fontWeight={400}
                  style={{ color: COLORS.primary.neutral_500 }}
                >
                  (Limit 5)
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={{ color: COLORS.primary.error }}
                  >
                    *
                  </PrimaryText>
                </PrimaryText>
              </PrimaryText>
              <SelectCauses
                // maxWidth={705}
                selectedCauses={selectedCauses}
                onChange={(e) => setSelectedCauses(e)}
                maxLimit={5}
              />
            </>
          )}
        </View>
      </View>

      {!!showPhotoModal &&
        <FullModal
          title={showPhotoModal === 'coverPhoto' ? "Upload Cover Photo" : "Upload Profile Photo"}
          subtitle={`Upload a minimum size of ${showPhotoModal === 'coverPhoto' ? "1300 x 730px" : "500 x 500px"} for best results.`}
          setShowModal={setShowPhotoModal}
          // containerStyle={{height: height}}
          containerStyle={{ width: '100%', height: '100%' }}
        // style={{}}
        >
          <View style={{ flex: 1, paddingHorizontal: 36, paddingBottom: 24 }}>
            <View style={{ width: '100%', aspectRatio: showPhotoModal === 'coverPhoto' ? 16 / 9 : 1, borderRadius: 4, overflow: 'hidden', maxHeight: 300 }}>
              <Cropper
                image={showPhotoModal === 'coverPhoto' ? croppedCoverPhoto : croppedProfilePhoto}
                crop={crop}
                rotation={rotation}
                zoom={showPhotoModal === 'coverPhoto' ? zoom : zoomProfilePic}
                aspect={showPhotoModal === 'coverPhoto' ? 16 / 9 : 1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={showPhotoModal === 'coverPhoto' ? onCropCoverPhotoComplete : onCropProfilePhotoComplete}
                onZoomChange={showPhotoModal === 'coverPhoto' ? setZoom : setZoomProfilePic}
              />
            </View>

            <View style={{ width: '80%', alignSelf: 'center', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
              <PrimaryText
                fontSize={12}
                fontWeight={700}
                style={{ color: COLORS.primary.darkGray, marginRight: 8 }}
              >Zoom</PrimaryText>
              <input
                type="range"
                value={showPhotoModal === 'coverPhoto' ? zoom : zoomProfilePic}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  showPhotoModal === 'coverPhoto' ? setZoom(e.target.value) : setZoomProfilePic(e.target.value)
                }}
                className="zoom-range"
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 36,
                marginTop: 20
              }}
            >
              <Button
                title={"Change Photo"}
                onPress={() => document.getElementById(showPhotoModal === 'coverPhoto' ? "choose-cover-photo" : "choose-profile-photo").click()}
                style={{
                  backgroundColor: COLORS.primary.offWhite,
                  borderColor: COLORS.primary.darkGray,
                  borderWidth: 1,
                  text: {
                    color: COLORS.primary.darkGray,
                  },
                }}
              />
              <Button
                title={"Confirm"}
                onPress={() => showCroppedImage(showPhotoModal)}
              // onPress={saveNewCampaign}
              // style={{
              //   opacity: saveCampaignDisabled ? 0.5 : 1,
              // }}
              // disabled={saveCampaignDisabled}
              />
            </View>
          </View>
        </FullModal>
      }
    </View>
  );
};

const styles = (darkMode) =>
  StyleSheet.create({
    container_v: {
      flex: 1,
      width: "100%",
      maxWidth: 1200,
      paddingHorizontal: 20,
    },
    form_item_v: {
      width: "100%",
      marginBottom: 12,
    },
    form_item: {
      height: 50,
    },
    verticalScroll: {
      flex: 1,

      alignContent: "center",
    },
    errorContainer: {
      backgroundColor: "#FEEFEF",
      height: 32,
      justifyContent: "flex-start",
      alignItems: "center",
      paddingHorizontal: 16,
      paddingVertical: 6,
      borderRadius: 8,
      flexDirection: "row",
      marginBottom: 12,
    },
    errorMessage: {
      color: COLORS.primary.error,
      paddingLeft: 8,
    },
    coverPhotos: {
      fontSize: 16,
      fontWeight: "600",
    },
    textInput: {
      paddingLeft: 10,
      marginBottom: 5,
      color: COLORS.primary.neutral_500,
    },
    dateContainer: {
      marginHorizontal: 6,
      backgroundColor: COLORS.primary.white,
      alignItems: "center",
    },
    splitInputContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      zIndex: 997,
    },
    splitInput: { width: "48%" },
    cover_img_v: {
      width: '90%',
      aspectRatio: 16 / 9,
      alignSelf: "center",
    },
    profilePhoto: {
      width: 175,
      aspectRatio: 1,
      alignSelf: "center",
      overflow: "hidden",
    },
    settingsRow: {
      flexDirection: "row",
      marginBottom: 24,
      alignItems: "center",
    },

    errorContainer: {
      backgroundColor: COLORS.background.lightRed,
      justifyContent: "flex-start",
      alignItems: "center",
      paddingHorizontal: 16,
      paddingVertical: 8,
      borderRadius: 8,
      flexDirection: "row",
    },
    errorMessage: {
      color: COLORS.primary.error,
      paddingLeft: 8,
    },
    customDonation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginVertical: 10
    },
    sequence: {
      marginRight: 16,
      color: COLORS.primary.neutral_500
    },
    defaultDonationText: {
      fontStyle: "italic",
      color: COLORS.primary.lightBlue,
      position: "absolute",
      left: 18,
      bottom: -15
    }
  });
