import React, { useEffect, useState } from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";
import { COLORS } from "constants/Colors";
import { CustomInput } from "components/Inputs/CustomInput";
import { PrimaryText } from "components/Common/PrimaryText";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import { useNavigate } from "react-router-dom";
import { generateInvitePasscode, getInvite } from "services/invite-service";
import { useWindowDimensions } from "react-native-web";
import { BREAKPOINTS } from "utils/breakpoints";
import TermsAndPrivacyText from "components/Common/TermsAndPrivacyText";

export function InviteLanding() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const [invitation, setInvitation] = useState(null);
  const [org, setOrg] = useState(null);

  //1. get associated invite link
  //2. get associated business
  //3. check business email domain
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log(queryParams.get("invite"));
    const invite = queryParams.get("invite");
    if (invite) {
      checkInvite(invite);
    }
  }, []);

  async function checkInvite(inviteId) {
    const invite = await getInvite(inviteId);
    console.log(invite);
    setInvitation(invite);
    if (invite) {
      setOrg(invite.org);
    }
  }

  useEffect(() => {
    if (org) {
      setLoading(false);
    }
  }, [org]);

  useEffect(() => {
    setEmailError(null);

    //check valid email here
    checkIfLoginActive();
  }, [email]);

  useEffect(() => {
    // setLoading(false);
  }, [emailError]);

  const checkIfLoginActive = () => {
    if (email !== "") {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  };

  const loginPressed = () => {
    setLoading(true);
    generateInvitePasscode(email);
    navigate(Links.InvitePasscode, { state: { invitation, email } });
  };

  const styles = style({ width });

  return (
    <View style={styles.container}>
      <img
        src={require("assets/rayze.png")}
        width={width > BREAKPOINTS["sm"] ? 78 : 47}
      />

      <PrimaryText
        fontSize={width > BREAKPOINTS["sm"] ? 54 : 32}
        fontWeight="900"
        style={{
          marginTop: width > BREAKPOINTS["sm"] ? 31 : 42.5,
          textAlign: "center",
        }}
      >
        Welcome to Rayze!
      </PrimaryText>

      <PrimaryText
        fontSize={width > BREAKPOINTS["sm"] ? 32 : 20}
        fontWeight={400}
        style={{
          marginTop: width > BREAKPOINTS["sm"] ? 20 : 53,
          textAlign: "center",
          color: COLORS.primary.gray,
          maxWidth: 700,
        }}
      >
        You've been invite by <i>{org?.name || "Business"}</i>, please enter
        your company email address.
      </PrimaryText>

      <CustomInput
        placeholder="Enter email*"
        title="Email Address"
        value={email}
        errorMessage={emailError}
        onChangeText={(e) => setEmail(e)}
        style={{
          marginTop: width > BREAKPOINTS["sm"] ? 80 : 54,
          marginBottom: width > BREAKPOINTS["sm"] ? 164 : 57,
          maxWidth: 514,
          width: "100%",
        }}
        mandatory={true}
      />

      <PrimaryButton
        title="Continue"
        onPress={loginPressed}
        disabled={loginDisabled}
      />

      <TermsAndPrivacyText />

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: COLORS.primary.white },
          ]}
          color={COLORS.primary.blue}
        />
      )}
    </View>
  );
}

const style = ({ width }) =>
  StyleSheet.create({
    container: {
      backgroundColor: COLORS.primary.white,
      justifyContent: width > BREAKPOINTS["sm"] ? "center" : "flex-start",
      alignItems: "center",
      width: "100%",
      height: "100%",
      paddingTop: width > BREAKPOINTS["sm"] ? 0 : 19,
    },
    loginText: {
      fontSize: 20,
      fontWeight: "700",
      textAlign: "center",
      color: COLORS.primary.lightBlue,
    },
    forgotPasswordBtn: {
      width: "100%",
      marginTop: 6,
    },
    forgotPassword: {
      color: COLORS.primary.lightBlue,
      fontSize: 14,
      marginBottom: 31,
      textAlign: "right",
    },
    separator: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 18,
    },
    line: {
      width: 75,
      height: 1,
      backgroundColor: COLORS.primary.gray,
    },
    separatorText: { marginHorizontal: 15, color: COLORS.primary.gray },
    termsText: {
      textAlign: "center",
      color: COLORS.primary.black,
      fontSize: 14,
      fontWeight: "400",
      flexWrap: "nowrap",
    },
    termsButtonText: {
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      color: COLORS.primary.lightBlue,
      flexWrap: "nowrap",
    },
  });
