import {
	Timestamp,
	addDoc,
	collection,
	doc,
	documentId,
	endBefore,
	getCountFromServer,
	getDoc,
	getDocs,
	limit,
	limitToLast,
	orderBy,
	query,
	serverTimestamp,
	startAfter,
	updateDoc,
	where
} from "firebase/firestore";
import { firestore } from "utils/firebase";
import { getAuth } from "firebase/auth";

export const createCustomLink = async ({ orgId, name, customUrl }) => {
	const userId = getAuth().currentUser.uid;

	const customLinkRef = collection(
		collection(firestore, "organizations"),
		orgId,
		"custom_links"
	);

	const linkQuery = query(
		customLinkRef,
		where("customUrl", "==", customUrl),
		where("status", "==", "active")
	);

	const linkSnaps = await getDocs(linkQuery);

	if (!linkSnaps.empty) {
		const error = new Error("Url already taken.");
		error.code = 412;
		throw error;
	}

	const customLink = await addDoc(customLinkRef, {
		name,
		lowerCaseName: name.toLowerCase(),
		customUrl,
		createdAt: serverTimestamp(),
		creator: userId,
		status: "active"
	});

	return {
		id: customLink.id,
		name,
		customUrl,
		createdAt: Timestamp.fromDate(new Date())
	};
};

// Allow user to update name or customUrl
export const updateCustomLink = async ({ orgId, data, id }) => {
	const customLinkDocRef = doc(
		firestore,
		"organizations",
		orgId,
		"custom_links",
		id
	);

	const customLinkRef = collection(
		collection(firestore, "organizations"),
		orgId,
		"custom_links"
	);

	if (data?.customUrl) {
		const linkQuery = query(
			customLinkRef,
			where("customUrl", "==", data.customUrl),
			where("status", "==", "active"),
			where(documentId(), "!=", id)
		);

		const linkSnaps = await getDocs(linkQuery);

		if (!linkSnaps.empty) {
			const error = new Error("Url already taken.");
			error.code = 412;
			throw error;
		}
	}
	await updateDoc(customLinkDocRef, data);

	return {
		...data,
		id
	};
};

export const deleteCustomLink = async (orgId, id) => {
	await firestore
		.collection('organizations')
		.doc(orgId)
		.collection("custom_links")
		.doc(id)
		.delete()

	return true;
};


export const getCustomLinkById = async ({ orgId, id }) => {
	const customLinkRef = doc(
		firestore,
		"organizations",
		orgId,
		"custom_links",
		id
	);

	const customLink = await getDoc(customLinkRef);
	const { creator } = customLink.data();

	const usersRef = doc(firestore, "users", creator);
	const user = await getDoc(usersRef);
	const { firstName, lastName } = user.data();

	return {
		...customLink.data(),
		id: customLink.id,
		creator: firstName + " " + lastName
	};
};

export const getCustomLinksByOrgId = async ({
	orgId,
	links,
	currentPage,
	nextPage,
	linksCount,
	rowsPerPage,
	search,
	startDate,
	endDate
}) => {
	const customLinkRef = collection(
		collection(firestore, "organizations"),
		orgId,
		"custom_links"
	);

	const queries = [];

	// Other conditions
	queries.push(where("status", "==", "active"));

	if (search) {
		queries.push(where("lowerCaseName", ">=", search.toLowerCase()));
		queries.push(where("lowerCaseName", "<=", search.toLowerCase() + "\uf8ff"));
	}

	// Sorting
	queries.push(orderBy("createdAt", "desc"));

	if (endDate) queries.push(startAfter(Timestamp.fromDate(endDate)));
	if (startDate) queries.push(endBefore(Timestamp.fromDate(startDate)));

	// get total no. of custom links after filters
	const countQuery = query(customLinkRef, ...queries);
	const totalLinks = await getCountFromServer(countQuery);

	const go =
		nextPage === 0
			? "first"
			: linksCount && nextPage === Math.floor(linksCount / rowsPerPage)
				? "last"
				: nextPage > currentPage
					? "next"
					: nextPage < currentPage
						? "previous"
						: "first";

	// Define the rows limit
	const rowsLimit = go === "last" ? linksCount % rowsPerPage : rowsPerPage;

	console.log({ linksCount, nextPage });

	// Pagination queries
	if (go === "first") queries.push(limit(rowsLimit || 10));

	if (go === "last") queries.push(limitToLast(rowsLimit || 10));

	if (go === "next" && links.length) {
		const startFrom = links[links.length - 1].createdAt.toDate();
		queries.push(startAfter(startFrom), limit(rowsLimit || 10));
	}

	if (go === "previous" && links.length) {
		const endTo = links[0]?.createdAt.toDate();
		queries.push(endBefore(endTo), limitToLast(rowsLimit || 10));
	}
	// get data query
	const dataQuery = query(customLinkRef, ...queries);
	const snapshot = await getDocs(dataQuery);

	if (snapshot.empty) {
		console.log("No custom links found.");

		return {
			customLinkDocs: [],
			totalLinks: 0
		};
	}

	const customLinks = [];

	snapshot.forEach((doc) => {
		customLinks.push({
			...doc.data(),
			id: doc.id
		});
	});

	return {
		customLinkDocs: customLinks,
		totalLinks: totalLinks.data().count
	};
};

export const listenForCustomLinks = (orgId, setCustomLinks) => {
	console.log('here')
	return firestore
		.collection('organizations')
		.doc(orgId)
		.collection("custom_links")
		.orderBy("createdAt", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let customLinks = [];

				querySnapshot.forEach((doc) => {
					customLinks.push({
						...doc.data(),
						id: doc.id
					});
				});

				console.log("listenForCustomLinks", customLinks);
				setCustomLinks(customLinks);
			},
			(e) => {
				console.log("listenForCustomLinks err", e);
			}
		);
};

export const listenForDonationsToCustomLink = (customLink, setDonations) => {
	return firestore
		.collection("donations")
		.where("customLink", "==", customLink.id)
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let donations = [];

				querySnapshot.forEach((doc) => {
					donations.push({
						...doc.data(),
						id: doc.id
					});
				});

				console.log("listenForDonationsToCustomLink", customLink.id);
				const promises = donations.map((donation) =>
					getUsersPromise(donation)
				);
				Promise.all(promises).then((donations) => {
					setDonations(donations);
				});
				// setDonations(donations);
			},
			(e) => {
				console.log("listenForDonationsToFundraiser err", e);
			}
		);
};

export const getUsersPromise = (obj) => {
	return new Promise((resolve, reject) => {
		if (!obj.fundraiserUserId) return resolve(obj);
		const userId = obj.fundraiserUserId.split("-")[0];
		firestore
			.collection("users")
			.doc(userId)
			.get()
			.then((doc) => {
				//check if user active here
				if (doc.exists) {
					let user = {
						id: doc.id,
						...doc.data()
					};
					obj.user = user;
					resolve(obj);
				} else {
					resolve(obj);
				}
			});
	});
};