import { auth, functions } from "utils/firebase";
import firebase from "firebase/compat/app";

export const signUpWithEmail = async (
	email,
	password,
	onSuccess,
	handleErrors
) => {
	const credential = firebase.auth.EmailAuthProvider.credential(
		email,
		password
	);
	auth.currentUser
		.linkWithCredential(credential)
		.then(() => {
			onSuccess && onSuccess();
		})
		.catch((e) => {
			handleErrors && handleErrors(e);
			console.log("error linking email", e, email);
		});
};

export const signUpWithEmailAndPassword = async (
	email,
	password,
	onSuccess,
	handleErrors
) => {
	auth
		.createUserWithEmailAndPassword(email, password)
		.then(() => {
			onSuccess && onSuccess();
		})
		.catch((e) => {
			handleErrors && handleErrors(e);
			console.log("error linking email", e, email);
		});
};

export const login = (
	email,
	password,
	setEmailError,
	setPasswordError,
	onSuccess
) => {
	auth
		.signInWithEmailAndPassword(email, password)
		.then((user) => {
			onSuccess && onSuccess();
		})
		.catch((err) => {
			console.log("error signing in", err);
			//return errors

			if (err.code === "auth/invalid-email") {
				setEmailError("Invalid email address");
			} else {
				setPasswordError("Invalid password");
			}
		});
};

export const logout = (onSuccess) => {
	auth.signOut().then(() => {
		if (onSuccess) {
			onSuccess();
		} else {
			window.location.reload(false);
		}
	});
};

export const sendPasswordReset = (email) => {
	auth
		.sendPasswordResetEmail(email)
		.then(() => {
			window.alert("Password reset email successfully sent");
		})
		.catch((e) => {
			window.alert(e.message);
			// console.log('error sending password reset', e)
		});
};

export async function checkEmailSignInMethods(
	email,
	onEmailCheckSuccess,
	setEmailError
) {
	if (!email) return onEmailCheckSuccess();
	const userEmailExists = await auth
		.fetchSignInMethodsForEmail(email)
		.catch((e) => {
			console.log("check email sign in methods error", e);
		});

	if (userEmailExists?.length > 0) {
		setEmailError("An account already exists for this email.");
	} else {
		onEmailCheckSuccess();
		// setEmailError('Email can be used')
	}
}

export async function checkPhoneSignInMethods(
	phoneNumber,
	onPhoneCheckSuccess,
	setPhoneError
) {
	const checkPhoneExists = await functions.httpsCallable("checkPhoneExists")({
		phoneNumber
	});

	const { data } = checkPhoneExists;
	const { exists } = data;

	if (exists) {
		setPhoneError("An account already exists for this phone number.");
	} else {
		onPhoneCheckSuccess();
		// setPhoneError('Phone number can be used')
	}
}

export async function signInWithPhoneNumber(phoneNumber, setPhoneConfirmation) {
	var applicationVerifier = new firebase.auth.RecaptchaVerifier(
		"recaptcha-container"
	);
	const confirmation = await auth
		.signInWithPhoneNumber(phoneNumber, applicationVerifier)
		.then(function (confirmationResult) {
			var verificationCode = window.prompt(
				"Please enter the verification " +
					"code that was sent to your mobile device."
			);
			return confirmationResult.confirm(verificationCode);
		})
		.catch((e) => {
			console.log("signInWithPhoneNumber get confirmation error", e);
		});
	setPhoneConfirmation(confirmation);
}
