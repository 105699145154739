import React from "react"
import { COLORS } from "constants/Colors"
import { StyleSheet, View } from "react-native"
import { useDonations } from "context/DonationsContext"
import { DonationCard } from "components/Cards/DonationCard"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"

export const DonationsTab = () => {
  const { recentDonations } = useDonations()

  return (
    <View style={styles.tableContainer}>

      <Table className="table table-borderless table2">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell style={{textAlign: 'left'}}>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Frequency</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
        {recentDonations &&
          recentDonations.map((el, i) => {
            return <DonationCard item={el} key={i} />
          }
        )}
        </TableBody>
      </Table>

      
    </View>
  )
}

const styles = StyleSheet.create({
  tableContainer: {
    minHeight: 300,
    backgroundColor: COLORS.primary.offWhite,
  },
  headerContainer: {
    flexDirection: "row",
  },
  infoContainer: {
    backgroundColor: COLORS.primary.white,
    marginHorizontal: 12,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  title: {
    marginBottom: 12,
  }
})
