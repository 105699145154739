import React from "react";
import { Box, IconButton } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { Close } from "assets/svgs";

export default function ModalHeader({ onClose, editing }) {
	return (
		<Box
			width="100%"
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			paddingTop={34}
			paddingBottom={22}
			borderBottom={`1px solid ${COLORS.primary.lightGray}`}
		>
			<PrimaryText
				fontSize={24}
				fontWeight={900}
			>
				{editing ? "Edit Event" : "Create New Event"}
			</PrimaryText>

			<PrimaryText
				style={{ color: COLORS.primary.gray, fontSize: 16, fontWeight: 500 }}
			>
				the people are going to love it!
			</PrimaryText>

			<IconButton
				onClick={onClose}
				sx={{
					position: "absolute",
					top: 20,
					right: 20,
					cursor: "pointer"
				}}
			>
				<Close fill={COLORS.primary.gray} />
			</IconButton>
		</Box>
	);
}
