import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";

export const FundraisingBackerCard = ({ item, onPress }) => {
  const { userId, email, date, amount = 0, fee = 0, } = item;
  const amountGiven = amount - fee;

  const [user, setUser] = useState(item?.user);

  useEffect(() => {
    if (!user) {
      userId && getUser(userId, gotUser);
    }
  }, [userId]);

  function gotUser(user) {
    item.user = {...item.user, ...user};
    setUser({...item.user, ...user});
  }

  return (
    <>
      {(!!userId && !user) ? null : (
        <TableRow hover onClick={onPress}>
          <TableCell width={36}>
            <ProfilePic uri={user?.profilePic} size={36} />
          </TableCell>
          <TableCell style={{ textAlign: "left" }}>
            <PrimaryText fontSize={18} fontWeight={900}>
              {user ? getUsersName(user) : item?.name}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {email || user?.email}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {date.toDate().toDateString()}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {dollarFormatter.format(amountGiven)}
            </PrimaryText>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
