import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import MuiButton from "components/Common/MuiButton";
import { PrimaryText } from "components/Common/PrimaryText";
import BlurBGModal from "components/Modals/BlurBGModal";
import { useOrganization } from "context/OrganizationContext";
import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { View } from "react-native-web";
import { updateOrganization } from "services/organizations-service";

export default function SetupStripe(props) {
  const { onClose, onSetupStripe, updateStripeCustomerId, currentUser, stripeSetupOpen, setupStep, setSetupStep, selectedOrg } = props

  const StartSetup = ({ onPress }) => (
    <>
      <PrimaryText fontSize={24} style={{ marginTop: 36 }}>
        Payment Account
      </PrimaryText>
      <MuiButton
        onPress={onPress}
        title={selectedOrg?.stripeCustomerId ? "Setup Payment Methods" : "Setup Stripe Account"}
        variant="contained"
        sx={{ mt: 21 }}
      />
      <PrimaryText fontSize={12} style={{ marginTop: 12, marginBottom: 68, marginHorizontal: '10%', fontStyle: 'italic', textAlign: 'center' }}>
        {`This will setup Stripe using your ${currentUser?.email} email address, but can be changed at any time through Stripe.`}
      </PrimaryText>
    </>
  );

  const AnonymousDonation = ({ onPress }) => (
    <View style={{ flex: 1, alignItems: 'center' }}>
      <PrimaryText fontSize={24} style={{ marginTop: 74 }}>
        Anonymous Donations
      </PrimaryText>
      <PrimaryText fontSize={14} style={{ textAlign: "center", marginTop: 12 }}>
        This enables your employees to donate to
        <br />
        organizations anonymously.
      </PrimaryText>

      <FormControl sx={{ mt: 12 }}>
        <RadioGroup row defaultValue="yes"
          value={selectedOrg.allowAnonymousDonations ? 'yes' : 'no'}
          onChange={(e) => {
            updateOrganization(selectedOrg, { allowAnonymousDonations: e.target.value === 'yes' }, [], [], () => { })
          }}
        >
          <FormControlLabel
            control={<Radio />}
            componentsProps={{
              typography: {
                fontSize: 17,
                fontWeight: 900,
              },
            }}
            value="yes"
            label="Yes"
            sx={{ mr: 26 }}
          />
          <FormControlLabel
            componentsProps={{
              typography: {
                fontSize: 17,
                fontWeight: 900,
              },
            }}
            value="no"
            control={<Radio />}
            label="No"
            sx={{ ml: 26 }}
          />
        </RadioGroup>
      </FormControl>
    </View>
  );

  const DonationApproval = () => (
    <>
      <PrimaryText fontSize={24} style={{ marginTop: 74 }}>
        Donation Approval
      </PrimaryText>
      <PrimaryText fontSize={14} style={{ textAlign: "center", marginTop: 12 }}>
        Choose to automatically or manually approve
        <br />
        employee donation matching
      </PrimaryText>

      <FormControl sx={{ mt: 23 }}>
        <RadioGroup row defaultValue="automatic"
          value={selectedOrg.autoApproveDonations ? 'automatic' : 'manual'}
          onChange={(e) => {
            updateOrganization(selectedOrg, { autoApproveDonations: e.target.value === 'automatic' }, [], [], () => { })
          }}
        >
          <FormControlLabel
            control={<Radio />}
            componentsProps={{
              typography: {
                fontSize: 17,
                fontWeight: 900,
              },
            }}
            value="automatic"
            label="Automatic"
            sx={{ mr: 26 }}
          />
          <FormControlLabel
            componentsProps={{
              typography: {
                fontSize: 17,
                fontWeight: 900,
              },
            }}
            control={<Radio />}
            value="manual"
            label="Manual"
            sx={{ ml: 26 }}
          />
        </RadioGroup>
      </FormControl>
    </>
  );

  const FiscalYear = () => (
    <>
      <PrimaryText fontSize={24} style={{ marginTop: 74 }}>
        Beginning of Fiscal Year
      </PrimaryText>
      <PrimaryText fontSize={14} style={{ textAlign: "center", marginTop: 12 }}>
        When does your fiscal year start? This will affect when
        <br />
        your total donation match allotment restarts as well
        <br />
        as assist in keeping track of tax documents.
      </PrimaryText>

      <FormControl sx={{ mt: 17, width: 115 }}>
        <Select
          value={selectedOrg.fiscalYear}
          onChange={(e) => { updateOrganization(selectedOrg, { fiscalYear: e.target.value }, [], [], () => { }) }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ height: 30, borderRadius: 15 }}
          IconComponent={(props) => <FiChevronDown {...props} color="black" />}
        >
          <MenuItem value="">Month</MenuItem>
          <MenuItem value={"January"}>Jan</MenuItem>
          <MenuItem value={"February"}>Feb</MenuItem>
          <MenuItem value={"March"}>Mar</MenuItem>
          <MenuItem value={"April"}>Apr</MenuItem>
          <MenuItem value={"May"}>May</MenuItem>
          <MenuItem value={"June"}>Jun</MenuItem>
          <MenuItem value={"July"}>Jul</MenuItem>
          <MenuItem value={"August"}>Aug</MenuItem>
          <MenuItem value={"September"}>Sep</MenuItem>
          <MenuItem value={"October"}>Oct</MenuItem>
          <MenuItem value={"November"}>Nov</MenuItem>
          <MenuItem value={"December"}>Dec</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  if (!stripeSetupOpen) {
    return null
  }

  return (
    <BlurBGModal
      sx={{ flex: 1, marginLeft: -12, width: '110%', minHeight: '100%', maxWidth: 600 }}
      pages={4}
      currentPage={setupStep}
      onClose={onClose}
    >
      <PrimaryText fontSize={24} fontWeight={900}>
        Admin Settings
      </PrimaryText>
      <PrimaryText fontSize={16} fontWeight={900} style={{ marginTop: 9 }}>
        First time setup
      </PrimaryText>
      <PrimaryText fontSize={17} style={{ marginTop: 2 }}>
        Let’s get you setup quick and easy like!
      </PrimaryText>

      {setupStep === 0 && <StartSetup onPress={() => {
        setSetupStep(1)
        setTimeout(() => {
          onSetupStripe()
        }, 250);
      }}
      />}
      {setupStep === 1 && <AnonymousDonation onPress={() => {
        updateStripeCustomerId()
      }} />}
      {setupStep === 2 && <DonationApproval />}
      {setupStep === 3 && <FiscalYear />}

      {setupStep ? (
        <View style={{ flexDirection: 'row', marginTop: '10%', marginBottom: '20%' }}>
          <MuiButton
            color="secondary"
            sx={{ mx: 18 }}
            variant="outlined"
            title="Back"
            onPress={() => {
              setSetupStep((prev) => prev - 1);
            }}
          />
          <MuiButton
            sx={{ mx: 18 }}
            variant="contained"
            title={setupStep < 3 ? "Next" : "Finish"}
            onPress={() => {
              if (setupStep === 1) {
                updateStripeCustomerId()
              }
              if (setupStep === 3) {
                updateOrganization(selectedOrg, { stripeSettingsComplete: true }, [], [], () => { onClose() })
                return
              }
              setSetupStep((prev) => (prev < 3 ? prev + 1 : onClose()));
            }}
          />
        </View>
      ) : null}
    </BlurBGModal>
  );
}


