import React, { useEffect, useState } from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View } from "react-native";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getUser } from "services/user-service";
import { useUsers } from "context/UserContext";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { PrimaryText } from "components/Common/PrimaryText";
import { ActivityIndicator, Image } from "react-native-web";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { CustomInput } from "components/Inputs/CustomInput";
import { Button } from "components/Common/Button";
import MuiButton from "components/Common/MuiButton";
import * as FiIcons from "react-icons/fi";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { useBusinessCampaigns } from "context/Business/BusinessCampaignsContext";
import Grid from "@mui/material/Grid";
import Misc from "assets/misc";
import LinearProgressWithLabel from "components/Progress/LinearProgressWithLabel";
import MuiCard from "components/Common/MuiCard";
import { BusinessCampaignBackerCard } from "components/Cards/Business/BusinessCampaignBackerCard";
import { NavHeader } from "components/Headers/NavHeader";
import { dollarFormatter } from "utils/helpers";
import moment from "moment";
import { FullModal } from "components/Modals/FullModal";
import { CampaignInfoForm } from "components/Campaigns/CampaignInfoForm";
import { archiveCampaign, updateCampaign } from "services/businesses/business-campaign-service";
import { ConfirmModal } from "components/Modals/Confirmation";

const options = [{ value: "recent", label: "Recent" }];

export const BusinessCampaignDetails = () => {
  const navigate = useNavigate();
  const { selectedCampaign, setSelectedCampaign, selectedCampaignDonations } =
    useBusinessCampaigns();
  const { setSelectedUser } = useUsers();

  const [search, setSearch] = useState("");
  const [selectedView, setSelectedView] = useState(options[0]?.value);

  const [showEditCampaign, setShowEditCampaign] = useState(false);
  const [saveCampaignDisabled, setSaveCampaignDisabled] = useState(true);
  const [details, setDetails] = useState({ ...selectedCampaign });
  const [selectedCauses, setSelectedCauses] = useState([]);
  const [newCampaignCoverPhotos, setNewCampaignCoverPhotos] = useState([
    selectedCampaign?.coverPhoto,
  ]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSaveCampaignDisabled(
      !details.name ||
        !details.startDate ||
        !details.endDate ||
        !details.goal ||
        !details.about ||
        newCampaignCoverPhotos?.length < 1 ||
        (shallowEqual(details, selectedCampaign) &&
          shallowEqual(newCampaignCoverPhotos, [selectedCampaign?.coverPhoto]))
    );
  }, [details, newCampaignCoverPhotos]);

  const shallowEqual = (objA, objB) => {
    if (!objA || !objB) {
      return objA === objB;
    }
    return !Boolean(
      Object.keys(Object.assign({}, objA, objB)).find(
        (key) => objA[key] !== objB[key]
      )
    );
  };

  function onBack() {
    navigate(-1);
  }

  function saveNewCampaign() {
    setLoading(true);

    let newCoverPhoto = [];
    if (selectedCampaign?.coverPhoto !== newCampaignCoverPhotos[0]) {
      //update cover photo
      newCoverPhoto = newCampaignCoverPhotos;
    }

    updateCampaign(selectedCampaign.id, details, newCoverPhoto, onSuccessSave);
    // createCampaign(details, newCampaignCoverPhotos, onSuccessSave)
  }

  function onSuccessSave(campaign) {
    setShowEditCampaign(false);

    setLoading(false);
    setSelectedCampaign({ ...campaign });
    setDetails({ ...campaign });
  }

  function archive() {
    archiveCampaign(selectedCampaign.id, !selectedCampaign?.archived, () => {
      setShowConfirmModal(false);
      onBack();
    });
  }

  if (loading) {
    return (
      <View
        style={[styles.container, { paddingTop: "80%", alignItems: "center" }]}
      >
        <ActivityIndicator
          animating={loading}
          size="large"
          style={styles.activity}
          color={COLORS.primary.blue}
        />
      </View>
    );
  }

  const {
    name,
    about = "",
    causes = [],
    backers = 0,
    raised = 0,
    goal,
    goalType,
    startDate,
    endDate,
    status,
  } = selectedCampaign;

  const raisedPercentage = Math.round((raised / goal) * 100 * 100) / 100;
  const daysLeft = moment(endDate).diff(moment(), "days");

  return (
    <View style={styles.container}>
      <NavHeader title={name} onBack={onBack} />

      <Grid container mb={50} style={{ marginTop: 24 }}>
        <Grid item xs={12} md={6} px="5%">
          <View style={styles.statsPhoto}>
            <View style={styles.cover_img_v}>
              <Image
                style={{ width: "100%", height: "100%", borderRadius: 12 }}
                source={{ uri: selectedCampaign?.coverPhoto || Misc.cover }}
                resizeMode={"contain"}
              />
            </View>
          </View>
        </Grid>
        <Grid item xs={12} md={6} px="5%">
          <View
            flexDirection="row"
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 12,
            }}
          >
            {/* <MuiButton
              sx={{ mr: 0 }}
              title="Export Report"
              startIcon={<FiIcons.FiUpload size={18} />}
              variant="contained"
            /> */}

            <MuiButton
              sx={{ mr: 12 }}
              title="Edit"
              startIcon={<FiIcons.FiEdit size={18} />}
              variant="outlined"
              color="darkBlue"
              onPress={() => setShowEditCampaign(true)}
            />

            <MuiButton
              title={selectedCampaign?.archived ? "Unarchive" : "Archive"}
              startIcon={<FiIcons.FiArchive size={18} />}
              variant="contained"
              color="error"
              onPress={() => setShowConfirmModal(true)}
            />
          </View>

          <View style={{ paddingTop: 8 }}>
            <PrimaryText fontSize={32} fontWeight={900}>
              {name}
            </PrimaryText>
            <br />
            <PrimaryText fontSize={16} fontWeight={400}>
              {about}
            </PrimaryText>
          </View>

          <LinearProgressWithLabel
            value={raisedPercentage}
            rightText={"Raised"}
            rightValue={raisedPercentage + "%"}
            height={24}
          />

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 24,
            }}
          >
            <View>
              <PrimaryText fontSize={24} fontWeight={600}>
                {dollarFormatter.format(raised).slice(0, -3)}
              </PrimaryText>
              <PrimaryText fontSize={17} fontWeight={400}>
                Raised of {dollarFormatter.format(goal).slice(0, -3)}
              </PrimaryText>
            </View>
            <View>
              <PrimaryText fontSize={24} fontWeight={600}>
                {backers}
              </PrimaryText>
              <PrimaryText fontSize={17} fontWeight={400}>
                Backers
              </PrimaryText>
            </View>
            <View>
              <PrimaryText fontSize={24} fontWeight={600}>
                {daysLeft}
              </PrimaryText>
              <PrimaryText fontSize={17} fontWeight={400}>
                Days left
              </PrimaryText>
            </View>
          </View>
        </Grid>
      </Grid>

      <Grid container mb={72}>
        {/* <Grid item xs={12} sm={6} md={3}>
          <MuiCard value="The Access Fund" title="Nonprofit" />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <MuiCard value="100%" title="Match Percentage" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MuiCard
            value={
              moment(startDate).format("MM/DD") +
              " - " +
              moment(endDate).format("MM/DD")
            }
            title="Date Range"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MuiCard
            value={
              raised
                ? dollarFormatter.format(raised / backers).slice(0, -3)
                : "$0"
            }
            title="Average Donation"
          />
        </Grid>
      </Grid>

      <ShadowContainer>
        <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
          Backers
        </PrimaryText>
        <View
          style={{
            zIndex: 999,
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginVertical: 12,
          }}
        >
          <View
            style={{ flexDirection: "row", alignItems: "center", flexGrow: 1 }}
          >
            <CustomInput
              placeholder="Search"
              style={{ flexGrow: 1, maxWidth: 400 }}
              onChangeText={setSearch}
              value={search}
              backgroundColor={COLORS.primary.white}
            />

            <PrimaryText
              fontSize={14}
              fontWeight={900}
              style={{
                color: COLORS.primary.darkGray,
                marginHorizontal: 4,
                marginLeft: 24,
              }}
            >
              Sort By
            </PrimaryText>
            <CustomPicker
              value={selectedView}
              onChange={(value) => setSelectedView(value)}
              options={options}
              style={{ maxWidth: 170, marginLeft: 3 }}
              backgroundColor={COLORS.primary.white}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              title="Export Report"
              iconLeft={
                <FiIcons.FiUpload
                  size={18}
                  color={COLORS.primary.white}
                  style={{ marginRight: 6 }}
                />
              }
            />
          </View>
        </View>

        <Table
          className="table"
          style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ textAlign: "left" }}>NAME</TableCell>
              <TableCell>NONPROFIT</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>AMOUNT GIVEN</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedCampaignDonations &&
              selectedCampaignDonations.map((el, i) => {
                return (
                  <BusinessCampaignBackerCard
                    item={el}
                    key={i}
                    // onPress={() => userSelected(el)}
                  />
                );
              })}
          </TableBody>
        </Table>
      </ShadowContainer>
      {/** Edit Campaign */}
      {showEditCampaign && (
        <FullModal
          title={"Invite Team Member"}
          setShowModal={setShowEditCampaign}
        >
          <CampaignInfoForm
            details={details}
            setDetails={setDetails}
            coverPhotos={newCampaignCoverPhotos}
            setCoverPhotos={setNewCampaignCoverPhotos}
            address={!details?.virtual}
            selectedCauses={selectedCauses}
            setSelectedCauses={setSelectedCauses}
          />

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 36,
            }}
          >
            <Button
              title={"Cancel"}
              onPress={() => setShowEditCampaign(false)}
              style={{
                backgroundColor: COLORS.primary.offWhite,
                borderColor: COLORS.primary.darkGray,
                borderWidth: 1,
                text: {
                  color: COLORS.primary.darkGray,
                },
              }}
            />
            <Button
              title={"Update Campaign"}
              onPress={saveNewCampaign}
              style={{
                opacity: saveCampaignDisabled ? 0.5 : 1,
              }}
              disabled={saveCampaignDisabled}
            />
          </View>
        </FullModal>
      )}

      {showConfirmModal && (
        <ConfirmModal
          title={`Are you sure you want to \n${
            selectedCampaign?.archived ? "unarchive" : "archive"
          } ${selectedCampaign?.name}`}
          // message={"Are you sure?"}
          onConfirm={() => archive()}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  container: {
    flex: 1,
    width: "100%",
    paddingTop: 20,
    backgroundColor: COLORS.primary.white,
  },
  headerContainer: {
    flexDirection: "row",
  },
  infoContainer: {
    backgroundColor: COLORS.primary.white,
    marginHorizontal: 12,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  title: {
    color: COLORS.primary.darkBlue,
  },
  URL: {
    fontSize: 20,
    fontWeight: 800,
    color: COLORS.primary.lightBlue,
  },
  cover_img_v: {
    width: "100%",
    aspectRatio: 16 / 9,
  },
  statsPhoto: {
    width: "100%",
  },
});
