import { PrimaryText } from "components/Common/PrimaryText";
import React, { useRef } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { COLORS } from "constants/Colors";
import moment from "moment";
import { RiAlertFill } from "react-icons/ri";
import { FiCalendar } from "react-icons/fi";
import "react-datepicker/dist/react-datepicker.css";

export const DateInput = ({
	title,
	placeholder,
	onChange,
	errorMessage,
	value,
	date,
	darkMode,
	mandatory,
	backgroundColor = COLORS.primary.offWhite,
	short,
	long,
	...props
}) => {
	const datePickerRef = useRef(null);

	const style = styles();

	const handleClick = () => {
		datePickerRef.current.showPicker();
	};

	return (
		<View style={[style.container, props?.style]}>
			{!!title && (
				<PrimaryText
					fontSize={20}
					fontWeight={700}
					style={style.inputTitle}
				>
					{title}
					{mandatory && (
						<PrimaryText
							fontSize={20}
							fontWeight={700}
							style={{ color: COLORS.primary.error }}
						>
							{"*"}
						</PrimaryText>
					)}
				</PrimaryText>
			)}

			<TouchableOpacity
				onPress={handleClick}
				style={style.input}
			>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
					style={[style.value, { fontSize: 14, fontWeight: 400 }]}
				>
					{value
						? moment(value).format(
								short ? "MMMM YYYY" : long ? "MM/DD/YY" : "MM/YY"
						  )
						: placeholder}
				</PrimaryText>

				<input
					type="date"
					ref={datePickerRef}
					id="datePicker"
					onChange={onChange}
					value={value}
					style={{ visibility: "hidden", width: 0 }}
				/>
				<View>
					<FiCalendar style={style.icon} />
				</View>
			</TouchableOpacity>

			{errorMessage ? (
				<View style={style.errorContainer}>
					<RiAlertFill
						width={16}
						height={16}
						fill={COLORS.primary.error}
						stroke={COLORS.primary.white}
					/>
					<PrimaryText
						fontSize={14}
						fontWeight={400}
						style={style.errorMessage}
					>
						{errorMessage}
					</PrimaryText>
				</View>
			) : null}
		</View>
	);
};

const styles = () =>
	StyleSheet.create({
		container: {},
		inputTitle: {
			paddingLeft: 12,
			marginBottom: 5,
			color: COLORS.primary.gray
		},
		input: {
			height: 30,
			paddingHorizontal: 16,
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			backgroundColor: COLORS.primary.offWhite,
			borderWidth: 0.5,
			borderRadius: 25,
			borderColor: COLORS.primary.lightGray,
			paddingVertical: 6
		},
		value: {
			color: COLORS.primary.darkGray,
			// fontSize: 20,
			fontWeight: 300,
			fontFamily: "Montserrat"
		},
		icon: {
			tintColor: COLORS.primary.darkGray,
			height: 24,
			width: 24
		},
		errorContainer: {
			backgroundColor: COLORS.background.lightRed,
			justifyContent: "flex-start",
			alignItems: "center",
			paddingHorizontal: 16,
			paddingVertical: 8,
			borderRadius: 8,
			flexDirection: "row"
		},
		errorMessage: {
			color: COLORS.primary.error,
			paddingLeft: 8
		}
	});
