import React from "react";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { BarPlot } from "@mui/x-charts/BarChart";
import { Box } from "@mui/material";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";

const xLabels = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec"
];

export default function MuiBarChart(props) {
	const {
		thisYearsDepositsByMonth,
		thisYearsWithdrawalsByMonth
	} = props;

	//change thisYearsWithdrawalsByMonth amounts to absolute values
	const withdrawals = thisYearsWithdrawalsByMonth.map(x => Math.abs(x));

	const thisYearsName = new Date().getFullYear();
	const noDeposits = thisYearsDepositsByMonth.length === 0 || thisYearsDepositsByMonth.every((x) => x === 0);
	const noWithdrawals = thisYearsWithdrawalsByMonth.length === 0 || thisYearsWithdrawalsByMonth.every((x) => x === 0);
	const bothAreEmpty = noDeposits && noWithdrawals;
	return (
		<Box
			px={20}
			pt={20}
			pb={20}
			bgcolor={COLORS.primary.neutral}
			borderRadius={3}
			boxShadow="5px 5px 15px 0px #80808026"
			ml={36}
		>
			<Box
				display="flex"
				alignItems="center"
				mb={10}
			>
				<PrimaryText fontSize={20}>{thisYearsName}</PrimaryText>

				<Box
					width={20}
					height={20}
					borderRadius="3px"
					bgcolor={COLORS.primary.success_500}
					ml={90}
					mr={12}
				/>
				<PrimaryText
					fontSize={14}
					fontWeight={700}
				>
					Incoming
				</PrimaryText>
				<Box
					width={20}
					height={20}
					borderRadius="3px"
					bgcolor={COLORS.primary.error}
					mr={12}
					ml={14}
				/>
				<PrimaryText
					fontSize={14}
					fontWeight={700}
				>
					Outgoing
				</PrimaryText>
			</Box>
			<ChartContainer
				width={500}
				height={100}
				series={bothAreEmpty ? 
					[{
						data: [0,0,0,0,100,200,140,0,0,0,0, 0],
						label: "pv",
						id: "pvId",
						type: "bar",
						color: COLORS.primary.lightGray,
					}] 
					:
					[
					{
						data: thisYearsDepositsByMonth,
						label: "pv",
						id: "pvId",
						type: "bar",
						color: COLORS.primary.success_500
					},
					{
						data: withdrawals,
						label: "uv",
						id: "uvId",
						type: "bar",
						color: COLORS.primary.error
					}
				]}
				xAxis={[{ scaleType: "band", data: xLabels }]}
				margin={{ left: 0, top: 0, bottom: 0, right: 0 }}
			>
				<BarPlot
					borderRadius={20}
					slotProps={{
						bar: { fill: "red" }
					}}
				/>
			</ChartContainer>

			<Box
				width={500}
				height="1px"
				bgcolor={COLORS.primary.neutral_400}
				mt={-4}
			/>
		</Box>
	);
}
