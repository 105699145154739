import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUsersName } from "utils/helpers";
import { Button } from "components/Common/Button";
import { FiCheck } from "react-icons/fi";
import { COLORS } from "constants/Colors";

export const EventAttendeeCard = ({ user, onPress }) => {
	// console.log({ user });
	return (
		<TableRow
			hover
			onClick={() => console.log("user clicked", user?.id)}
		>
			{/* Name */}
			<TableCell style={{ display: "flex", alignItems: "center" }}>
				<ProfilePic
					uri={user?.profilePic}
					size={36}
				/>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
					style={{ marginLeft: 14 }}
				>
					{getUsersName(user)}
				</PrimaryText>
			</TableCell>
			{/* Email */}
			<TableCell sx={{ textAlign: "left" }}>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					{user?.email || "N/A"}
				</PrimaryText>
			</TableCell>

			{/* Username */}
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					@{user?.username}
				</PrimaryText>
			</TableCell>

			{/* Shift */}
			<TableCell sx={{ textAlign: "left" }}>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					N/A
				</PrimaryText>
			</TableCell>

			{/* Time */}
			<TableCell sx={{ textAlign: "left" }}>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
				>
					N/A
				</PrimaryText>
			</TableCell>

			{/* Check In Button */}
			{onPress && (
				<TableCell>
					<Button
						onPress={onPress}
						title={user?.attended ? "Checked In" : "Check In"}
						iconLeft={
							user?.attended ? (
								<FiCheck
									size={24}
									color={COLORS.primary.white}
									style={{ marginRight: 12 }}
								/>
							) : null
						}
						style={{
							backgroundColor: user?.attended
								? COLORS.primary.green
								: COLORS.primary.lightBlue,
							maxWidth: 125
						}}
					/>
				</TableCell>
			)}
		</TableRow>
	);
};
