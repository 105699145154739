import {
	Box,
	IconButton,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@mui/material";
import { Close, Filter } from "assets/svgs";
import PrimaryButton from "components/Buttons/PrimaryButton";
import EmployeeSelectionCard from "components/Cards/EmployeeSelectionCard";
import { Button } from "components/Common/Button";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { PrimaryText } from "components/Common/PrimaryText";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { COLORS } from "constants/Colors";
import React, { useEffect, useState } from "react";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { PiWarning } from "react-icons/pi";
import { StyleSheet, View } from "react-native-web";
import { dollarFormatter } from "utils/helpers";

const RAYZE_AMOUNT = 10_801;

const options = [{ value: "recent", label: "Recent" }];

export default function EmployeeSelectionModal({
	isOpen,
	onClose,
	onConfirm,
	onBack,
	users,
	rayzeAccountBalance,
	giftAmount,
	setGiftAmount,
	giftFrequency,
	setGiftFrequency,
	giftTotal,
	setGiftTotal,
	numberOfEmployees,
	allEmployees,
	setAllEmployees,
	selectedEmployees,
	setSelectedEmployees,
	paymentMethod
}) {
	const EMPLOYEES = users.filter(x => x.active);
	const [selectedView, setSelectedView] = useState(options[0]?.value);
	const [filtered, setFiltered] = useState(EMPLOYEES);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const [search, setSearch] = useState("");

	useEffect(() => {
		if (allEmployees) return;
		setGiftTotal(giftAmount * selectedEmployees.length);
	}, [giftAmount, selectedEmployees, allEmployees])

	function employeeSelected(user) {
		if (selectedEmployees.includes(user)) {
			setSelectedEmployees(selectedEmployees.filter((x) => x !== user));
		} else {
			setSelectedEmployees([...selectedEmployees, user]);
		}
	}

	const currentTableData = () => {
		const firstPageIndex = currentPage * rowsPerPage;
		const lastPageIndex = firstPageIndex + rowsPerPage;
		return filtered.slice(firstPageIndex, lastPageIndex);
	};

	const INSUFFICIENT = paymentMethod?.title === "Rayze Account" &&
		giftTotal > rayzeAccountBalance

	return (
		<Modal
			open={isOpen}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
			onClose={onClose}
		>
			<Box
				minWidth={932}
				pt={32}
				pb={44}
				bgcolor={COLORS.primary.white}
				display="flex"
				flexDirection="column"
				borderRadius={3}
				position="relative"
				px={60}
				boxSizing="border-box"
			>
				<IconButton
					sx={{ position: "absolute", top: 16, right: 20 }}
					onClick={onClose}
				>
					<Close fill={COLORS.primary.gray} />
				</IconButton>
				<PrimaryText
					fontSize={24}
					fontWeight={900}
					style={{ textAlign: "center" }}
				>
					Employee Gift
				</PrimaryText>
				<PrimaryText
					fontSize={16}
					color={COLORS.primary.neutral_500}
					style={{ marginTop: 10, marginBottom: 30, textAlign: "center" }}
				>
					Current Balance: {dollarFormatter.format(rayzeAccountBalance)}
				</PrimaryText>
				<Box
					minWidth={812}
					bgcolor={COLORS.primary.neutral}
					borderRadius="25px"
					pt={49}
					pb={40}
					px={30}
					display="flex"
					flexDirection="column"
					boxShadow="5px 5px 15px 0 rgba(128, 128, 128, 0.15)"
				>
					{/* Table */}
					<PrimaryText
						fontSize={20}
						fontWeight={900}
						style={styles.title}
					>
						Employee Selection
					</PrimaryText>
					<View style={styles.filtersrow}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								flexGrow: 1
							}}
						>
							<View style={styles.inputRow}>
								<CustomInput
									placeholder="Search"
									style={{ flexGrow: 1, maxWidth: 400 }}
									onChangeText={setSearch}
									value={search}
									backgroundColor={COLORS.primary.white}
								/>

								<PrimaryText
									fontSize={14}
									fontWeight={900}
									style={{
										color: COLORS.primary.darkGray,
										marginHorizontal: 4,
										marginLeft: 24
									}}
								>
									Sort By
								</PrimaryText>
								<CustomPicker
									value={selectedView}
									onChange={(value) => setSelectedView(value)}
									options={options}
									style={{ maxWidth: 170, marginLeft: 3 }}
									backgroundColor={COLORS.primary.white}
								/>

								<PrimaryButton
									title="Filter"
									variant="outlined"
									fontSize={14}
									fontWeight={700}
									style={styles.filterBtn}
									icon={<Filter />}
								/>
							</View>
						</View>
					</View>

					<Table
						className="table"
						style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
					>
						<TableHead>
							<TableRow>
								<TableCell style={{ textAlign: "left" }}>NAME</TableCell>
								<TableCell>ROLE</TableCell>
								<TableCell>JOB TITLE</TableCell>
								<TableCell>SELECTION</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{currentTableData() &&
								currentTableData().map((el, i) => {
									return (
										<EmployeeSelectionCard
											key={i}
											user={el}
											selected={selectedEmployees.includes(el)}
											onPress={() => employeeSelected(el)}
										/>
									);
								})}
						</TableBody>
					</Table>

					<MuiTablePagination
						count={filtered?.length}
						rowsPerPage={rowsPerPage}
						page={currentPage}
						setCurrentPage={setCurrentPage}
						onRowsPerPageChange={(e) => {
							setRowsPerPage(e.target.value);
							setCurrentPage(0);
						}}
					/>
					{/* Table End */}
				</Box>
				<PrimaryText
					fontSize={16}
					fontWeight={900}
					style={{ marginTop: 30 }}
				>
					Total:
				</PrimaryText>
				{/* Buttons */}
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
				>
					<Box
						display="flex"
						flexDirection="row"
						alignItems="flex-end"
						gap={5}
					>
						<PrimaryText
							fontSize={32}
							fontWeight={900}
							color={
								INSUFFICIENT ? COLORS.primary.error : COLORS.primary.darkBlue
							}
						>
							{dollarFormatter.format(giftTotal)}
						</PrimaryText>

						<PrimaryText
							fontSize={20}
							color={
								INSUFFICIENT
									? COLORS.primary.error_300
									: COLORS.primary.neutral_500
							}
							style={{ marginBottom: 5 }}
						>
							({dollarFormatter.format(giftAmount)} per employee)
						</PrimaryText>
					</Box>

					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="center"
						gap={44}
					>
						<PrimaryButton
							title="Back"
							variant="outlined"
							onPress={onBack}
							style={{
								borderRadius: 20,
								paddingHorizontal: 24,
								marginBottom: 0
							}}
							icon={
								<LuChevronLeft
									size={20}
									color={COLORS.primary.darkBlue}
								/>
							}
						/>
						<Button
							disabled={!selectedEmployees.length || INSUFFICIENT}
							title="Next"
							onPress={onConfirm}
							iconRight={
								<LuChevronRight
									color={COLORS.primary.white}
									size={20}
								/>
							}
							style={{
								paddingHorizontal: 24,
								paddingVertical: 12,
								text: {
									fontSize: 16,
									fontWeight: 900
								}
							}}
						/>
					</Box>
				</Box>
				{INSUFFICIENT && (
					<Box
						display="flex"
						alignItems="center"
						gap={6}
						mt={4}
					>
						<PiWarning
							color={COLORS.primary.error}
							size={20}
						/>
						<PrimaryText
							fontSize={12}
							color={COLORS.primary.error}
							style={{ marginBottom: -4, letterSpacing: 0.8 }}
						>
							Insufficient funds, choose a different account
						</PrimaryText>
					</Box>
				)}
			</Box>
		</Modal>
	);
}

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row"
	},
	infoContainer: {
		backgroundColor: COLORS.primary.white,
		marginHorizontal: 12,
		borderRadius: 12,
		paddingHorizontal: 20,
		paddingVertical: 12,
		alignItems: "center",
		justifyContent: "center"
	},
	info: {
		alignItems: "center",
		justifyContent: "center",
		marginHorizontal: 24
	},
	title: {
		marginBottom: 12
	},
	URL: {
		fontSize: 20,
		fontWeight: 800,
		color: COLORS.primary.lightBlue
	},
	inputRow: {
		flexDirection: "row",
		alignItems: "center",
		flexGrow: 1
	},
	filtersrow: {
		zIndex: 999,
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
		marginVertical: 12
	},
	filterBtn: {
		height: 30,
		marginBottom: 0,
		gap: 8,
		width: 116,
		paddingHorizontal: 0,
		marginLeft: 20
	}
});