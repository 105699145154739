import React from "react";
import { useAuth } from "context/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import { SkillsProvider } from "context/SkillsContext";
import { DonationsProvider } from "context/DonationsContext";
import { EventsProvider } from "context/EventContext";
import { UserProvider } from "context/UserContext";
import AuthNavigator from "./AuthNavigator";
import Loading from "components/Common/Loading";
import OnboardingNavigator from "./OnboardingNavigator";
import OrganizationNavigator from "./OrganizationNavigator";
import InviteNavigator from "./InviteNavigator";

export default function RootNavigator() {
  const { currentUser, onboarding } = useAuth();

  //get subdomain
  const subdomain = window.location.hostname.split(".").slice(0, -1).join(".");

  // console.log("subdomain", subdomain, window.location.hostname)

  if (subdomain.includes('invite')) {
    return (
      <Router>
        <InviteNavigator />
      </Router>
    )
  }

  return (
    <>
      <Router>
        {currentUser === null && !onboarding ? (
          <AuthNavigator />
        ) : (
          <SkillsProvider>
            <DonationsProvider>
              <UserProvider>
                <EventsProvider>
                  {onboarding ? (
                    <OnboardingNavigator />
                  ) : (
                    <OrganizationNavigator />
                  )}
                </EventsProvider>
              </UserProvider>
            </DonationsProvider>
          </SkillsProvider>
        )}
      </Router>

      <Loading />
    </>
  );
}
