import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  Dimensions,
  View,
} from "react-native-web";
import MuiButton from "components/Common/MuiButton";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { COLORS } from "constants/Colors";
import { useOrganization } from "context/OrganizationContext";
import { updateOrganization } from "services/organizations-service";
import { useBusinessNonprofits } from "context/Business/BusinessNonprofitsContext";
import {
  Box,
  Tabs,
  Tab,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { BusinessSelectNonprofitCard } from "components/Cards/Business/BusinessSelectNonprofitCard";
import MuiCheckbox from "components/Common/MuiCheckbox";
import { useCauses } from "context/CausesContext";

export function NonprofitSelectionModal(props) {
  const { showNonprofitModal, setShowNonprofitModal, onUpdateSupportedNonprofits, supportedNonprofits, limit = null } = props;
  const { selectedOrg } = useOrganization();
  const { setGetNonprofits, allNonprofits } = useBusinessNonprofits();
  const { allCauses } = useCauses();

  const [] = useState(false);
  const [activeTabForManageNonprofits, setActiveTabForManageNonprofits] =
    useState(1);
  const [filteredNonprofits, setFilteredNonprofits] = useState(allNonprofits);
  const [selectedNonprofits, setSelectedNonprofits] = useState(
    supportedNonprofits || []
  );
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    verification: "",
    cause: "",
  });

  function onFilter(key, value) {
    setFilter((prev) => ({ ...prev, [key]: value }));
  }

  function selectNonprofit(nonprofit) {
    
    const index = selectedNonprofits.findIndex((item) => item === nonprofit.id);
    if (index === -1) {
      if (limit && selectedNonprofits.length >= limit) return;
      setSelectedNonprofits([...selectedNonprofits, nonprofit.id]);
    } else {
      const newSelectedNonprofits = [...selectedNonprofits];
      newSelectedNonprofits.splice(index, 1);
      setSelectedNonprofits(newSelectedNonprofits);
    }
  }

  function globalCheckSelected(e) {
    const value = e?.target?.checked;
    if (value) {
      setSelectedNonprofits(filteredNonprofits.map((x) => x.id));
    } else {
      setSelectedNonprofits([]);
    }
  }

  useEffect(() => {
    if (!showNonprofitModal) return;
    setGetNonprofits(true);
  }, [showNonprofitModal]);

  useEffect(() => {
    const filtered = allNonprofits.filter((item) => {
      // Search by text
      const searched = item.name.toLowerCase().includes(search.toLowerCase());

      // Filter by cause
      const filterCause = filter?.cause
        ? item.causes.includes(filter?.cause)
        : true;

      // Filter by verification
      const filterVerification = filter?.verification
        ? item?.verified == filter?.verification
        : filter?.verification === false
          ? !item?.verified
          : true;

      return searched === filterCause && filterCause === filterVerification;
    });

    setFilteredNonprofits(filtered);
  }, [search, allNonprofits, filter]);




  return (
    <Modal open={showNonprofitModal} >
      <Box sx={style.modalBox}>
        <PrimaryText fontSize="24" fontWeight="900">
          Manage Supported Nonprofits
        </PrimaryText>

        <IconButton
          sx={style.close}
          onClick={() => setShowNonprofitModal(false)}
        >
          <img src={require("assets/misc/close.png")} />
        </IconButton>

        <Box style={style.modalHeader}>
          <View
            style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
          >
            <CustomInput
              placeholder="Search"
              style={{ flexGrow: 1, maxWidth: 400 }}
              backgroundColor={COLORS.primary.white}
              value={search}
              onChangeText={(text) => setSearch(text)}
            />
            {/* 
            <PrimaryText
              fontSize={14}
              fontWeight={900}
              style={{
                color: COLORS.primary.darkGray,
                marginLeft: 24,
              }}
            >
              Sort By
            </PrimaryText>
            <CustomPicker
              placeholder="Recent"
              style={{ maxWidth: 170, marginLeft: 14 }}
              backgroundColor={COLORS.primary.white}
            /> */}

            <PrimaryText
              fontSize={14}
              fontWeight={900}
              style={{
                color: COLORS.primary.darkGray,
                marginLeft: 24,
              }}
            >
              Filter
            </PrimaryText>
            <CustomPicker
              placeholder="All Causes"
              style={{ maxWidth: 170, marginLeft: 14 }}
              backgroundColor={COLORS.primary.white}
              options={[
                {
                  name: "All Causes",
                  all: null,
                },
              ]
                .concat(allCauses)
                .map((item) => ({
                  label: item.name,
                  value: item.all !== undefined ? item.all : item.name,
                }))}
              onChange={(e) => onFilter("cause", e)}
              value={filter?.cause}
            />

            {/* <CustomPicker
              placeholder="Verification"
              style={{ maxWidth: 170, marginLeft: 14 }}
              backgroundColor={COLORS.primary.white}
              options={[
				{ label: "Any", value: null },
                { label: "Verified", value: true },
                { label: "Not Verified", value: false },
              ]}
              onChange={(e) => onFilter("verification", e)}
              value={filter?.verification}
            /> */}
          </View>

          <Box sx={{ alignSelf: "flex-end" }}>
            <MuiButton
              variant="contained"
              title="Save"
              sx={{ height: 44, marginLeft: 90 }}
              onClick={() =>
                onUpdateSupportedNonprofits(selectedNonprofits)
              }
            />
            <MuiButton
              variant="outlined"
              title="Cancel"
              color="darkBlue"
              sx={{ height: 44, marginLeft: 29 }}
              onClick={() => setShowNonprofitModal(false)}
            />
          </Box>
        </Box>

        <Box sx={style.tabBox}>
          <Tabs value={activeTabForManageNonprofits} sx={style.tabs}>
            <Tab
              sx={style.tabItem({
                width: "40%",
                isActive: activeTabForManageNonprofits === 0,
              })}
              label="Selected"
              onClick={() => setActiveTabForManageNonprofits(0)}
            />
            <Tab
              sx={style.tabItem({
                width: "60%",
                isActive: activeTabForManageNonprofits === 1,
              })}
              label="All"
              onClick={() => setActiveTabForManageNonprofits(1)}
            />
          </Tabs>
        </Box>

        <TableContainer>
          <Table
            className="table"
            style={{ borderTopLeftRadius: 12 }}
          // stickyHeader={true}
          >
            <TableHead style={{ position: "sticky", top: 0 }}>
              <TableRow>
                <TableCell>
                  <MuiCheckbox
                    disabled={false}
                    onChange={(e) => globalCheckSelected(e)}
                    indeterminate={
                      selectedNonprofits.length > 0 &&
                      selectedNonprofits.length < filteredNonprofits.length
                    }
                    checked={
                      selectedNonprofits.length === filteredNonprofits.length
                    }
                  />
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>NAME</TableCell>
                <TableCell>VERIFICATION</TableCell>
                <TableCell>CAUSES</TableCell>
                <TableCell>DONATIONS</TableCell>
                <TableCell>VOLUNTEER HOURS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNonprofits.map((nonprofit) => (
                <BusinessSelectNonprofitCard
                  nonprofit={nonprofit}
                  key={nonprofit.id}
                  onPress={() => selectNonprofit(nonprofit)}
                  selected={selectedNonprofits.includes(nonprofit.id)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal >
  );
}

const style = {
  modalBox: {
    width: "80vw",
    height: "80vh",
    bgcolor: "offwhite.main",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "25px",
    pt: 35,
    paddingX: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 32,
  },
  tabBox: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: 20,
  },
  tabs: {
    borderBottom: 1,
    borderColor: "gray.lighter",
    width: "100%",
    // marginTop: 30,
  },
  tabItem: ({ width, isActive }) => ({
    textTransform: "none",
    fontSize: 16,
    fontWeight: isActive ? 700 : 400,
    fontFamily: "Montserrat",
    width,
    "&:hover": {
      backgroundColor: "neutral.200",
    },
  }),
  close: { position: "absolute", right: 56, top: 23 },
};
