import React, { useState, createContext, useEffect, useContext } from "react"
import { useOrganization } from "./OrganizationContext"
import { listenForDonationsToFundraiser, listenForFundraiserChanges } from "services/fundraisers-service"
import { listenForCustomLinks, listenForDonationsToCustomLink } from "services/custom-link-service"

export const CustomLinkContext = createContext()

export const CustomLinkProvider = ({ children }) => {
  const { selectedOrg } = useOrganization()

  const [getCustomLinks, setGetCustomLinks] = useState(false)
  const [customLinks, setCustomLinks] = useState([])
  const [selectedCustomLink, setSelectedCustomLink] = useState(null)
  const [customLinkDonations, setCustomLinkDonations] = useState([])

  useEffect(() => {
    if (!selectedOrg || !getCustomLinks) return

    const sub = listenForCustomLinks(
      selectedOrg.id,
      setCustomLinks
    )

    return () => {
      sub()
    }
  }, [selectedOrg, getCustomLinks])

  useEffect(() => {
    // if (!selectedCustomLink) return;
    // const findIndex = customLinks.findIndex(f => f.id === selectedCustomLink.id)
    // if (findIndex !== -1) {
    //   setSelectedCustomLink(customLinks[findIndex])
    // }
  }, [customLinks])

  useEffect(() => {
    if (!selectedCustomLink) return;

    console.log('test', selectedCustomLink)
    const sub = listenForDonationsToCustomLink(
      selectedCustomLink,
      setCustomLinkDonations
    )

    return () => {
      sub();
      // setCustomLinkDonations([])
    }

  }, [selectedCustomLink])

  return (
    <CustomLinkContext.Provider
      value={{
        setGetCustomLinks,
        customLinks, setCustomLinks,
        selectedCustomLink,
        setSelectedCustomLink,
        customLinkDonations
      }}
    >
      {children}
    </CustomLinkContext.Provider>
  )
}

export const useCustomLinks = () => useContext(CustomLinkContext)
