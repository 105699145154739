import React from "react";
import { View, Dimensions, StyleSheet } from "react-native";
import { Navigate, Route, Routes } from "react-router-dom";
import { Links } from "constants/Links";
import { Landing } from "screens/Auth/0-Landing";

const { height } = Dimensions.get("window");

export default function AuthNavigator() {
  return (
    <View
      style={{
        flex: 1,
        height: height,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 24,
        minWidth: 1024,
        paddingTop: 12,
      }}
    >
      <Routes>
        <Route path={Links.Landing} element={<Landing />} />
        <Route path={"/*"} element={<Navigate replace to={Links.Landing} />} />
      </Routes>
    </View>
  );
}

const styles = StyleSheet.create({});
