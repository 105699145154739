import React from "react";
import { Box, StepConnector, StepLabel } from "@mui/material";
import { COLORS } from "constants/Colors";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

export default function Steps({ steps, active }) {
	return (
		<Stepper
			activeStep={active}
			alternativeLabel
			sx={{ marginY: 20 }}
			connector={
				<StepConnector
					sx={{
						".MuiStepConnector-line": {
							border: `0.5px solid ${COLORS.primary.darkBlue}`
						},
						"&.Mui-active > .MuiStepConnector-line, &.Mui-completed > .MuiStepConnector-line":
							{
								borderColor: COLORS.primary.lightBlue
							},
						marginX: 15
					}}
				/>
			}
		>
			{steps?.map((step, index) => (
				<Step key={index}>
					<StepLabel
						StepIconComponent={(props) => (
							<StepIconComponent
								{...props}
								icon={step.icon}
							/>
						)}
						componentsProps={{
							label: {
								style: {
									fontSize: 12,
									fontWeight: active === index ? 700 : 400,
									marginTop: 5,
									color:
										active === index || active > index
											? COLORS.primary.lightBlue
											: COLORS.primary.darkBlue
								}
							}
						}}
					>
						{step.title}
					</StepLabel>
				</Step>
			))}
		</Stepper>
	);
}

const StepIconComponent = ({ icon, active, completed }) => {
	return (
		<Box
			width={30}
			height={30}
			borderRadius={15}
			border={`1px solid ${
				active
					? COLORS.primary.lightBlue
					: completed
					? COLORS.primary.white
					: COLORS.primary.darkBlue
			}`}
			display="flex"
			justifyContent="center"
			alignItems="center"
			bgcolor={completed && COLORS.primary.lightBlue}
		>
			{icon({
				fill: active
					? COLORS.primary.lightBlue
					: completed
					? COLORS.primary.white
					: COLORS.primary.darkBlue
			})}
		</Box>
	);
};
