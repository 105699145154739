import { Box, IconButton, Modal } from "@mui/material";
import { Close } from "assets/svgs";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import React from "react";
import { LuChevronLeft } from "react-icons/lu";
import { StyleSheet, TouchableOpacity, View } from "react-native-web";
import { giftFundsToEmployeeSuccess } from "services/businesses/business-wallet-service";
import { dollarFormatter } from "utils/helpers";

const RAYZE_AMOUNT = 10_801;

export default function EmployeeGiftConfirmationModal({
	isOpen,
	onClose,
	onConfirm,
	onBack,
	rayzeAccountBalance,
	giftTotal,
	giftAmount,
	giftFrequency,
	selectedEmployees,
	allEmployees,
	numberOfEmployees
}) {

	const { rayzeAccount } = useBusinessDonations();

	function giftFunds() {
		// return console.log('test')
		giftFundsToEmployeeSuccess(rayzeAccount, giftTotal, selectedEmployees)
			.then(() => {
				onConfirm();
			})
			.catch((e) => {
				console.log('error', e)
			})
	}


	return (
		<Modal
			open={isOpen}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
			onClose={onClose}
		>
			<Box
				width={{ base: 704, lg: 800 }}
				pt={32}
				pb={24}
				bgcolor={COLORS.primary.white}
				display="flex"
				flexDirection="column"
				borderRadius={3}
				position="relative"
				px={90}
				boxSizing="border-box"
			>
				<IconButton
					sx={{ position: "absolute", top: 16, right: 20 }}
					onClick={onClose}
				>
					<Close fill={COLORS.primary.gray} />
				</IconButton>
				<PrimaryText
					fontSize={24}
					fontWeight={900}
					style={{ textAlign: "center" }}
				>
					Employee Gift
				</PrimaryText>

				<PrimaryText
					fontSize={16}
					color={COLORS.primary.neutral_500}
					style={{ marginTop: 10, marginBottom: 30, textAlign: "center" }}
				>
					Current Balance: {dollarFormatter.format(rayzeAccountBalance)}
				</PrimaryText>

				<Box
					minWidth={526}
					bgcolor={COLORS.primary.neutral}
					borderRadius="25px"
					pt={49}
					pb={40}
					display="flex"
					flexDirection="column"
					alignItems="center"
					boxShadow="5px 5px 15px 0 rgba(128, 128, 128, 0.15)"
				>
					<PrimaryText
						fontSize={24}
						fontWeight={400}
					>
						Summary
					</PrimaryText>

					<PrimaryText
						fontSize={32}
						fontWeight={900}
						style={{ marginTop: 23 }}
					>
						{allEmployees ? numberOfEmployees : selectedEmployees?.length} Employees Selected
					</PrimaryText>

					<PrimaryButton
						title="View/Edit Selection"
						titleStyle={{ fontSize: 14, fontWeight: 700 }}
						style={{ height: 29, paddingHorizontal: 16, marginTop: 15 }}
					/>

					<View style={styles.line} />

					<PrimaryText
						fontSize={32}
						fontWeight={900}
					>
						{giftFrequency} Gift
					</PrimaryText>

					<View style={styles.line} />

					<PrimaryText
						fontSize={32}
						fontWeight={900}
					>
						{dollarFormatter.format(giftTotal)} Total
					</PrimaryText>

					<PrimaryText
						fontSize={20}
						fontWeight={400}
						color={COLORS.primary.neutral_500}
					>
						({dollarFormatter.format(giftAmount)} per employee)
					</PrimaryText>

					<View style={styles.line} />

					<PrimaryText
						fontSize={40}
						fontWeight={900}
					>
						Rayze Wallet
					</PrimaryText>
				</Box>

				{/* Buttons */}


				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="center"
					gap={44}
					mt={36}
				>
					<PrimaryButton
						title="Back"
						variant="outlined"
						onPress={onBack}
						style={{ borderRadius: 20, paddingHorizontal: 24 }}
						icon={
							<LuChevronLeft
								size={20}
								color={COLORS.primary.darkBlue}
							/>
						}
					/>
					<PrimaryButton
						title="Confirm"
						style={{ borderRadius: 20, paddingHorizontal: 24 }}
						onPress={giftFunds}
						disabled={giftAmount <= 0}
					/>
				</Box>
			</Box>
		</Modal>
	);
}

const styles = StyleSheet.create({
	line: {
		marginVertical: 22,
		width: 187,
		height: 1,
		backgroundColor: COLORS.primary.neutral_400
	}
});
