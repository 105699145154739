import { Edit } from "assets/svgs";
import MuiButton from "components/Common/MuiButton";
import { COLORS } from "constants/Colors";
import React from "react";

export default function MuiEditButton({ onChange, endIcon, ...props }) {
	return (
		<MuiButton
			title="Edit/Change"
			onPress={onChange}
			startIcon={<Edit fill={COLORS.primary.white} />}
			endIcon={endIcon && endIcon}
			variant="contained"
			sx={{ height: 29, width: 160, fontWeight: 700, fontSize: 14, mt: 14 }}
			{...props}
		/>
	);
}
