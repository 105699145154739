import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

import MuiButton from "components/Common/MuiButton";
import { PrimaryText } from "components/Common/PrimaryText";
import BlurBGModal from "components/Modals/BlurBGModal";
import React, { useState } from "react";
import { updateOrganization } from "services/organizations-service";

export default function EmployeeMatchingSetup({ onClose, selectedOrg, onEditSupportedNonprofits }) {
  const [setupStep, setSetupStep] = useState(0);

  const [matchPercentage, setMatchPercentage] = useState(selectedOrg.matchPercentage);
  const [maxEmployeeMatchPerYear, setMaxEmployeeMatchPerYear] = useState(selectedOrg.maxEmployeeMatchPerYear);
  const [maxCompanyMatchPerYear, setMaxCompanyMatchPerYear] = useState(selectedOrg.maxCompanyMatchPerYear);



  const NonProfitDonation = (props) => (
    <>
      <PrimaryText fontSize={24} style={{ marginTop: 74 }}>
        Nonprofit Donations
      </PrimaryText>
      <PrimaryText fontSize={14} style={{ textAlign: "center", marginTop: 12 }}>
        Would you like to allow employees to donate
        <br />
        to any nonprofit?
      </PrimaryText>
      <FormControl sx={{ mt: 23 }}>
        <RadioGroup
          row
          value={selectedOrg.allowDonationsToAllNonprofits ? 'yes' : 'no'}
          onChange={(e) => {
            updateOrganization(selectedOrg, { allowDonationsToAllNonprofits: e.target.value === 'yes' }, [], [], () => { })
          }}
        >
          <FormControlLabel
            control={<Radio />}
            componentsProps={{
              typography: {
                fontSize: 17,
                fontWeight: 900,
              },
            }}
            value="yes"
            label="Yes"
            sx={{ mr: 26 }}
          />
          <FormControlLabel
            componentsProps={{
              typography: {
                fontSize: 17,
                fontWeight: 900,
              },
            }}
            value="no"
            control={<Radio />}
            label="No"
            sx={{ ml: 26 }}
          />
        </RadioGroup>
      </FormControl>
      {!selectedOrg.allowDonationsToAllNonprofits && (
        <MuiButton
          variant="contained"
          title="View/Edit Supported Nonprofits"
          sx={{ mt: 50 }}
          onPress={onEditSupportedNonprofits}
        />
      )}
      <NextBackBtn mt={72} {...props} />
    </>
  );

  const MatchingProfile = (props) => (
    <>
      <PrimaryText fontSize={24} style={{ marginTop: 74 }}>
        Employee Matching Profile
      </PrimaryText>
      <PrimaryText fontSize={14} style={{ textAlign: "center", marginTop: 12 }}>
        This lets you set whether employee donations
        <br />
        outside of company campaigns will be
        <br />
        matched or not.
      </PrimaryText>

      <FormControl sx={{ mt: 23 }}>
        <RadioGroup
          row
          value={selectedOrg.matchAnyDonation ? 'true' : 'false'}
          onChange={(e) => {
            updateOrganization(selectedOrg, { matchAnyDonation: e.target.value === "true" }, [], [], () => { })
          }}
        >
          <FormControlLabel
            control={<Radio />}
            componentsProps={{
              typography: {
                fontSize: 17,
                fontWeight: 900,
              },
            }}
            value="false"
            label="Campaign Only"
            sx={{ mr: 26 }}
          />
          <FormControlLabel
            componentsProps={{
              typography: {
                fontSize: 17,
                fontWeight: 900,
              },
            }}
            control={<Radio />}
            value="true"
            label="Any Donation"
            sx={{ ml: 26 }}
          />
        </RadioGroup>
      </FormControl>

      <NextBackBtn mt={52} {...props} />
    </>
  );









  return (
    <BlurBGModal
      sx={{ flex: 1, marginLeft: -12, width: '110%', minHeight: '100%', maxWidth: 600 }}
      pages={5}
      currentPage={setupStep}
      onClose={onClose}
    >
      <PrimaryText fontSize={24} fontWeight={900}>
        Employee Matching Settings
      </PrimaryText>
      <PrimaryText fontSize={16} fontWeight={900} style={{ marginTop: 9 }}>
        First time setup
      </PrimaryText>
      <PrimaryText fontSize={17} style={{ marginTop: 2 }}>
        Let’s get you setup quick and easy!
      </PrimaryText>

      {setupStep === 0 && <NonProfitDonation onNext={() => setSetupStep(1)} />}
      {setupStep === 1 && (
        <MatchingProfile
          onNext={() => setSetupStep(2)}
          onBack={() => setSetupStep(0)}
        />
      )}
      {setupStep === 2 && (
        <MatchPercentage
          onNext={() => setSetupStep(3)}
          onBack={() => setSetupStep(1)}
          matchPercentage={matchPercentage}
          setMatchPercentage={setMatchPercentage}
          selectedOrg={selectedOrg}
          setSetupStep={setSetupStep}
        />
      )}
      {setupStep === 3 && (
        <MatchTotal
          onNext={() => setSetupStep(4)}
          onBack={() => setSetupStep(2)}
          maxEmployeeMatchPerYear={maxEmployeeMatchPerYear}
          setMaxEmployeeMatchPerYear={setMaxEmployeeMatchPerYear}
          selectedOrg={selectedOrg}
          setSetupStep={setSetupStep}
        />
      )}
      {setupStep === 4 && (
        <FiscalYear
          // onNext={() => setSetupStep(3)}
          onBack={() => setSetupStep(3)}
          maxCompanyMatchPerYear={maxCompanyMatchPerYear}
          setMaxCompanyMatchPerYear={setMaxCompanyMatchPerYear}
          selectedOrg={selectedOrg}
          setSetupStep={setSetupStep}
          onClose={onClose}
        />
      )}
    </BlurBGModal>
  );
}



const MatchPercentage = (props) => (
  <>
    <PrimaryText fontSize={24} style={{ marginTop: 74 }}>
      Match Percentage
    </PrimaryText>
    <PrimaryText fontSize={14} style={{ textAlign: "center", marginTop: 12 }}>
      Determine the percentage match of each
      <br />
      employee donation your company will make.
    </PrimaryText>

    <TextInput
      placeholder="1 - 100"
      value={props.matchPercentage}
      onChange={(e) => {
        let trimmed = e.target.value.replace(/\D/g, "");
        trimmed = trimmed.slice(0, 3);
        if (Number(trimmed) > 100) {
          trimmed = 100;
        }
        props.setMatchPercentage(Number(trimmed));
      }}
    />

    <NextBackBtn mt={52} {...props}
      onNext={
        () => {
          updateOrganization(props.selectedOrg, { matchPercentage: props.matchPercentage }, [], [], () => {
            props.setSetupStep(3)
          })
        }
      } />
  </>
);

const MatchTotal = (props) => (
  <>
    <PrimaryText fontSize={24} style={{ marginTop: 74 }}>
      Employee Match Total
    </PrimaryText>
    <PrimaryText fontSize={14} style={{ textAlign: "center", marginTop: 12 }}>
      Determine the maximum match attributed to
      <br />
      individual employees each year.
    </PrimaryText>

    <TextInput
      placeholder="$ 000"
      value={props.maxEmployeeMatchPerYear}
      onChange={(e) => {
        let trimmed = e.target.value.replace(/\D/g, "");
        props.setMaxEmployeeMatchPerYear(Number(trimmed));
      }}
    />

    <NextBackBtn
      mt={52}
      {...props}
      onNext={
        () => {
          updateOrganization(props.selectedOrg, { maxEmployeeMatchPerYear: props.maxEmployeeMatchPerYear }, [], [], () => {
            props.setSetupStep(4)
          })
        }}
    />
  </>
);

const FiscalYear = (props) => (
  <>
    <PrimaryText fontSize={24} style={{ marginTop: 74 }}>
      Total Fiscal Year Amount
    </PrimaryText>
    <PrimaryText fontSize={14} style={{ textAlign: "center", marginTop: 12 }}>
      Determine the total yearly match allotment
      <br />
      your company will make.
    </PrimaryText>

    <TextInput
      placeholder="$ 000"
      value={props.maxCompanyMatchPerYear}
      onChange={(e) => {
        let trimmed = e.target.value.replace(/\D/g, "");
        props.setMaxCompanyMatchPerYear(Number(trimmed));
      }}
    />

    <NextBackBtn
      mt={52}
      {...props}
      nextBtnTitle="Finish"
      onNext={
        () => {
          updateOrganization(props.selectedOrg, { matchSettingsComplete: true, maxCompanyMatchPerYear: props.maxCompanyMatchPerYear }, [], [], () => {
            props.onClose()
          })
        }}
    />
  </>
);







const TextInput = (props) => (
  <TextField
    variant="outlined"
    sx={{
      "& .MuiOutlinedInput-root": {
        width: 165,
        height: 30,
        borderRadius: 15,
        bgcolor: "white",
        mt: 25,
      },
    }}
    {...props}
  />
);

const NextBackBtn = ({ onNext, onBack, nextBtnTitle, ...props }) => (
  <Box mb={85} mt={40} {...props}>
    <MuiButton
      color="secondary"
      sx={{ mx: 18 }}
      variant="outlined"
      title="Back"
      onPress={onBack}
    />
    <MuiButton
      sx={{ mx: 18 }}
      variant="contained"
      title={nextBtnTitle || "Next"}
      onPress={onNext}
    />
  </Box>
);