import { firestore, auth, storage } from "utils/firebase";
import { getUsersName } from 'utils/helpers';

export const getPostById = async (docId, setPost, setLoading) => {
    firestore
      .collection('posts')
      .doc(docId)
      .get()
      .then((doc) => {
        if (doc.exists) {
            let data = doc.data()
            const post = {
                id: doc.id,
                postId: doc.id,
                ...data,
            }
            setPost(post)
        } else {
            setLoading(false)
        }
      })
      .catch((e) => {
        console.log('getPostById err', e);
      });
}

export const getUsersPosts = async (user, setPosts, setLoading) => {
  firestore
    .collection('posts')
    .where('poster', '==', user.id)
    .orderBy('createdAt', 'desc')
    .get()
    .then((querySnapshot) => {
      const temp = []
      querySnapshot.forEach(doc => {
        let data = doc.data()
        const post = {
            id: doc.id,
            postId: doc.id,
            ...data,
        }
        temp.push(post)
      })

      setPosts(temp)

      setLoading(false)
    })
    .catch((e) => {
      console.log('getUsersPosts err', e);
    });
}

export const getOrgsPosts = async (org, setPosts, setLoading) => {
  firestore
    .collection('posts')
    .where("orgId", "==", org.id)
    .orderBy('createdAt', 'desc')
    .get()
    .then((querySnapshot) => {
      const temp = []
      querySnapshot.forEach(doc => {
        let data = doc.data()
        const post = {
            id: doc.id,
            postId: doc.id,
            ...data,
        }
        temp.push(post)
      })

      setPosts(temp)

      setLoading(false)
    })
    .catch((e) => {
      console.log('getUsersPosts err', e);
    });
}

export async function getLumens(feedId, post, setTotalLumens, setLumens, setLumensLastSaved, setMax, updateLumanImage) {
  firestore
  .collection("lumens")
  .doc(feedId)
  .get()
  .then((doc) => {
    if (doc.exists) {
      const data = doc.data()
      setTotalLumens(post.lumens)
      setLumens(data.lumens)
      setLumensLastSaved(data.lumens)
      setMax(post.lumens + 50 - data.lumens)
      updateLumanImage(data.lumens)
    }
  })
}

export const updateLumens = (updatedLumenCount, max, lumensLastSaved, feedId, post, currentUser) => {
  if (updatedLumenCount <= max && updatedLumenCount !== lumensLastSaved) {
    firestore.collection("lumens").doc(feedId)
    .set({
      lumens: updatedLumenCount,
      poster: post.poster,
      postId: post.postId,
      sendersName: getUsersName(currentUser),
      userId: currentUser.id,
      updatedAt: new Date()
    }, {merge: true})
  }
}