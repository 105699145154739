import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { CustomInput } from "components/Inputs/CustomInput";
import { Links } from "constants/Links";
import { DateInput } from "components/Inputs/DateInput";
import { useLocation, useNavigate } from "react-router-dom";
import { checkUsernameAvailability, createUser, createUserInDb, updateUsername } from "services/user-service";
import { useLoading } from "context/LoadingContext";
import { auth } from "utils/firebase";

export default function PersonalInformation() {
  const { state } = useLocation();
  const navigate = useNavigate();

  console.log("PersonalInformation", state);
  console.log("auth", auth.currentUser);

  const { setLoading } = useLoading();

  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    birthday: new Date("1990-01-02"),
    zipCode: "",
    username: ""
  });
  const [errors, setErrors] = useState({});

  const handleInputs = (key, e) => {
    const { value } = e.target;

    handleErrors(key, null);

    console.log("key", key, value);

    setInputs((prev) => ({ ...prev, [key]: value }));
  };

  const handleErrors = (key, value) =>
    setErrors((prev) => ({ ...prev, [key]: value }));

  const onCreate = async () => {
    let hasError = false;

    console.log("inputs", inputs);

    Object.values(errors).forEach((value) => {
      if (value) hasError = true;
    });

    Object.entries(inputs).forEach(([key, value]) => {
      if (!value) {
        handleErrors(key, "This field is required.");
        hasError = true;
      }
    });

    await checkUsernameAvailability(inputs.username, (err) => {
      if (err) {
        console.log('username error', err)
        handleErrors("username", err);
        hasError = true;
      }
    });

    console.log("hasError", hasError);

    if (hasError) return;

    const { uid, email, phone } = state;

    setLoading(true);

    console.log('here')

    // createUser({
    //   email,
    //   phone,
    //   ...inputs
    // })

    updateUsername(inputs.username, () =>
      createUserInDb({
        uid,
        email,
        phone,
        ...inputs,
        birthday: new Date(inputs.birthday),
        createdAt: new Date(),
        lastUpdate: new Date(),
      })
        .then((_) => navigate(Links.Congrats))
        .catch((err) => {
          console.log(err);
          alert(err.message);
        })
        .finally((_) => setLoading(false))
    )


};

return (
  <View style={styles.container}>
    <img src={require("assets/rayze.png")} width={77.64} />

    <PrimaryText fontSize={54} fontWeight="900">
      Create Personal Account
    </PrimaryText>

    <PrimaryText
      fontSize={32}
      style={{ color: COLORS.primary.gray, marginTop: 14 }}
    >
      Enter Your Personal Information
    </PrimaryText>

    <View style={{ marginTop: 59, flexDirection: 'row', width: '100%' }}>
      <View style={{flex: 1, marginRight: 6}}>
        <CustomInput
          style={{ marginBottom: 12}}
          title="First Name"
          placeholder="Enter First Name"
          mandatory={true}
          onChange={(e) => handleInputs("firstName", e)}
          value={inputs?.firstName}
          errorMessage={errors?.firstName}
        />
        <CustomInput
          style={{ marginBottom: 12}}
          title="Last Name"
          placeholder="Enter Last Name"
          mandatory={true}
          onChange={(e) => handleInputs("lastName", e)}
          value={inputs?.lastName}
          errorMessage={errors?.lastName}
        />
        <CustomInput
          // style={{ width: 365, marginRight: 17.5 }}
          title="Username"
          placeholder="Enter username"
          mandatory={true}
          onChange={(e) => handleInputs("username", e)}
          value={inputs?.username}
          errorMessage={errors?.username}
        />
      </View>

      <View style={{flex: 1, marginLeft: 6}}>
        <DateInput
          style={{ marginBottom: 12}}
          title="Date of Birth"
          placeholder="January 1990"
          mandatory={true}
          onChange={(e) => handleInputs("birthday", e)}
          value={inputs?.birthday}
          errorMessage={errors?.birthday}
          short
        />
        <CustomInput
          title="Zip Code"
          placeholder="123456"
          mandatory={true}
          onChange={(e) => handleInputs("zipCode", e)}
          value={inputs?.zipCode}
          errorMessage={errors?.zipCode}
        />
      </View>
    </View>

    <PrimaryButton
      title="Create"
      style={{ marginTop: 60 }}
      onPress={onCreate}
    />
  </View>
);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // width: "60%",
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
});
