import {
	getOrgPromise,
	updateOrganization
} from "services/organizations-service";
import { firestore, functions, auth } from "utils/firebase";
import firebase from "firebase/compat/app";

export const getBusinessRayzeAccount = (selectedOrg, setRayzeAccountBalance) => {
	return firestore
		.collection("accounts")
		.where("customerId", "==", selectedOrg?.id)
		.onSnapshot(
			(querySnapshot) => {
				if (querySnapshot.empty) return;
				const account = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
				setRayzeAccountBalance(account);
			},
			(e) => {
				console.log("getBusinessRayzeAccount error", e);
			}
		);
};

export const getBusinessRayzeAccountTransactions = (rayzeAccount, setRayzeAccountBalance) => {
	return firestore
		.collection("accounts").doc(rayzeAccount?.id)
		.collection("transactions")
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				const transactions = [];
				querySnapshot.forEach((doc) => {
					transactions.push({
						...doc.data(),
						id: doc.id
					});
				});
				setRayzeAccountBalance(transactions);

			},
			(e) => {
				console.log("getBusinessRayzeAccount error", e);
			}
		);
};

export const depositFundsSuccess = (rayzeAccount, amount) => {
	const transaction = {
		amount,
		balance: rayzeAccount.balance + amount,
		date: firebase.firestore.Timestamp.now(),
		description: "Deposit from bank account xxxx1234",
		type: "deposit"
	}
	const batch = firestore.batch();
	const accountRef = firestore.collection("accounts").doc(rayzeAccount.id);
	const transactionRef = accountRef.collection("transactions").doc();
	batch.update(accountRef, { balance: rayzeAccount.balance + amount });
	batch.set(transactionRef, transaction);
	return batch.commit();
}

export const giftFundsToEmployeeSuccess = (rayzeAccount, amount, employees) => {
	const stringOfEmployeeNames = employees.map(employee => (employee.firstName + " " + employee.lastName)).join(", ");
	const transaction = {
		amount: Math.abs(amount),
		balance: rayzeAccount.balance - amount,
		date: firebase.firestore.Timestamp.now(),
		description: `Withdrawal to ${employees?.length} employees - ${stringOfEmployeeNames}`,
		type: "withdrawal",
		employee: true
	}
	const batch = firestore.batch();
	const accountRef = firestore.collection("accounts").doc(rayzeAccount.id);
	const transactionRef = accountRef.collection("transactions").doc();
	batch.update(accountRef, { balance: rayzeAccount.balance - amount });
	batch.set(transactionRef, transaction);
	return batch.commit();
}