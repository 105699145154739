import { PrimaryText } from "components/Common/PrimaryText";
import React from "react";
import { StyleSheet, TextInput, View, TouchableOpacity } from "react-native";
import { COLORS } from "constants/Colors";
import moment from "moment";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FiAlertCircle, FiCalendar } from "react-icons/fi";

export const CustomInput = (props) => {
	const {
		title,
		subtitle,
		placeholder,
		onChange,
		onChangeText = null,
		errorMessage,
		value,
		secure,
		date,
		time,
		onPress,
		multiline,
		keyboardType,
		timeModalOpen,
		onBlur,
		maxLength,
		mandatory,
		backgroundColor = COLORS.primary.neutral,
		borderColor = COLORS.primary.lightGray,
		titleStyle = {},
		textContentType = "none"
	} = props;

	const style = styles(multiline);

	return (
		<View style={[style.container, props?.style && { ...props.style }]}>
			<View style={style.titleContainer}>
				{!!title && (
					<PrimaryText
						fontSize={20}
						fontWeight={700}
						{...titleStyle}
					>
						{title}
						{mandatory && (
							<PrimaryText
								fontSize={20}
								fontWeight={700}
								style={{ color: COLORS.primary.error }}
							>
								{"*"}
							</PrimaryText>
						)}
					</PrimaryText>
				)}

				{!!subtitle && (
					<PrimaryText
						fontSize={12}
						fontWeight={400}
						color={COLORS.primary.neutral_500}
					>
						{subtitle}
					</PrimaryText>
				)}
			</View>

			{!date && (
				<TextInput
					{...props}
					maxFontSizeMultiplier={1}
					style={[style.input, { backgroundColor, borderColor }]}
					placeholder={placeholder}
					keyboardType={keyboardType}
					placeholderTextColor={COLORS.primary.neutral_500}
					value={value}
					onChange={onChange}
					onChangeText={onChangeText}
					secureTextEntry={secure}
					textContentType={textContentType}
					multiline={multiline}
					maxLength={maxLength || 500}
					onBlur={onBlur}
					autoCapitalize={"none"}
				/>
			)}
			{date && (
				<TouchableOpacity
					onPress={onPress}
					disabled={!onPress}
					style={style.input}
				>
					{time ? (
						<PrimaryText
							fontSize={16}
							fontWeight={400}
							style={style.value}
						>
							{moment(value).format("LT")}
						</PrimaryText>
					) : (
						<PrimaryText
							fontSize={16}
							fontWeight={400}
							style={[style.value, { marginRight: 4 }]}
						>
							{value ? value.toLocaleDateString() : placeholder}
						</PrimaryText>
					)}
					<View>
						{time ? (
							!timeModalOpen ? (
								<FaChevronDown style={style.icon} />
							) : (
								<FaChevronUp style={style.icon} />
							)
						) : (
							<FiCalendar style={style.icon} />
						)}
					</View>
				</TouchableOpacity>
			)}
			{errorMessage ? (
				<View style={style.errorContainer}>
					<FiAlertCircle
						width={16}
						height={16}
						fill={COLORS.primary.white}
						stroke={COLORS.primary.error}
					/>
					<PrimaryText
						fontSize={14}
						fontWeight={400}
						style={style.errorMessage}
					>
						{errorMessage}
					</PrimaryText>
				</View>
			) : null}
		</View>
	);
};

const styles = (multiline) =>
	StyleSheet.create({
		container: {
			// flexGrow: 1,
			// flexDirection: "column",
			// marginBottom: 12,
		},
		titleContainer: {
			paddingLeft: 10,
			marginBottom: 5
		},
		input: {
			height: multiline ? 128 : 30,
			paddingHorizontal: 16,
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			fontSize: 14,
			color: COLORS.primary.darkGray,
			fontWeight: 400,
			fontFamily: "Montserrat",
			backgroundColor: COLORS.primary.neutral_200,
			borderWidth: 0.5,
			borderRadius: 15,
			borderColor: COLORS.primary.lightGray,
			paddingVertical: multiline ? 5 : 0
		},
		value: {
			color: COLORS.primary.darkGray
		},
		icon: {
			tintColor: COLORS.primary.darkGray,
			height: 24,
			width: 24
		},
		errorContainer: {
			// backgroundColor: COLORS.background.lightRed,
			justifyContent: "flex-start",
			alignItems: "center",
			paddingHorizontal: 16,
			paddingVertical: 4,
			borderRadius: 8,
			flexDirection: "row"
		},
		errorMessage: {
			color: COLORS.primary.error,
			paddingLeft: 8
		}
	});
