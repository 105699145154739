import { parseEventData } from "utils/events-helper";
import { firestore, storage, auth } from "utils/firebase";
import { getUsersName } from "utils/helpers";
import { getUsersFromMembersPromise } from "./user-service";

// Create an event
// New create event function
export const createAnEvent = async (data) => {
	console.log('create event', { data });
	// Map data to save in database
	const eventDetail = {
		coverPhoto: "",
		waiver: "",
		active: true,
		about: data?.about,
		createdAt: new Date(),
		updatedAt: new Date(),
		creator: auth.currentUser.uid,
		categories: data?.categories,
		date: data?.date,
		endTime: data?.endTime,
		lowercaseName: data?.name?.toLowerCase(),
		meetingURL: data?.meetingURL || "",
		mode: data?.mode,
		name: data?.name,
		orgId: data?.orgId,
		volunteersNeeded:
			data?.noOfVolunteers === "Limited" ? data?.volunteersNeeded : null,
		orgName: data?.orgName,
		orgType: data?.orgType,
		passCode: data?.passCode || "",
		repeat: data?.repeat || false,
		revenue: "unpaid",
		startTime: data?.startTime,
		trendingScore: 0,
		type: "event",
		latLng: data.latLng || null,
		address: data.address || null,
		shifts: data?.shifts || null,
		distanceInM: 0
	};

	console.log(eventDetail);

	// Create reference to the event collection
	const eventRef = firestore.collection("events");

	// Create event to firestore
	const eventDoc = data?.id
		? await eventRef.doc(data.id).update(eventDetail)
		: await eventRef.add(eventDetail);

	const eventId = eventDoc?.id || data?.id;

	// Upload cover photo
	let coverUrl;
	const coverRef = storage.ref(`eventCoverPhotos/${eventId}/cover-photo`);
	await coverRef.putString(data.coverPhoto.dataUrl, "data_url");
	coverUrl = await coverRef.getDownloadURL();

	// Upload waiver (if any)
	let waiverUrl;
	if (data.uploadWaiver === "Yes" && data.waiver?.url) {
		const waiverRef = storage.ref(`eventWaivers/${eventId}/waiver`);
		await waiverRef.putString(data.waiver.url, "data_url");
		waiverUrl = await waiverRef.getDownloadURL();
	}

	console.log({
		waiverUrl,
		coverUrl
	});

	// Update waiver and cover photo urls to event
	await eventRef.doc(eventId).update({
		coverPhoto: coverUrl || "",
		waiver: waiverUrl || ""
	});

	// Return event id to fetch again on the next page
	return eventId;
};

export const getFileMetadata = async (url) => {
	const storageRef = storage.refFromURL(url);
	const metadata = await storageRef.getMetadata();
	console.log({ metadata });
	return metadata;
};

export const getEvents = (organization, setUpcomingEvents, setPastEvents) => {
	return firestore
		.collection("events")
		.where("orgId", "==", organization.id)
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let upcomingEvents = [];
				let pastEvents = [];

				if (querySnapshot.empty) {
					setUpcomingEvents([]);
					setPastEvents([]);
					return;
				}

				querySnapshot.forEach((doc) => {
					const event = parseEventData(doc);

					if (event?.startTime < new Date() / 1000) {
						pastEvents.push(event);
					} else {
						upcomingEvents.push(event);
					}
				});
				setUpcomingEvents(upcomingEvents);
				setPastEvents(pastEvents);
			},
			(e) => {
				console.log("error getting events", organization.id, e);
			}
		);
};

export const createEvent = (data, coverPhotos, onSuccess) => {
	delete data.coverPhoto;
	data.active = true;

	firestore
		.collection("events")
		.add(data)
		.then((doc) => {
			data.id = doc.id;

			coverPhotos.forEach((val, index) => {
				let ref = storage.ref(`eventCoverPhotos/${doc.id}/${index}.jpg`);
				ref
					.put(val.blob, { cacheControl: "max-age=31536000" })
					.then(async (result) => {
						console.log("updateEvent uploaded cover photo!", result);
						const downloadURL = await ref.getDownloadURL();
						firestore
							.collection("events")
							.doc(doc.id)
							.update({ coverPhoto: downloadURL })
							.then(() => {
								data.coverPhoto = downloadURL;
								onSuccess(data);
								updateEventInterestStatus(
									doc.id,
									true,
									false,
									() => {},
									() => {}
								);
							});
					})
					.catch((err) => {
						onSuccess(data);
						console.log("err", err);
					});
			});
		})
		.catch((err) => {
			console.log("err", err);
			window.alert("Error", "An error has occured please try later.", [
				{ text: "Ok", onPress: onSuccess }
			]);
			onSuccess();
		});
};

export const updateEvent = (eventId, data, coverPhotos, onSuccess) => {
	// console.log("update event", eventId, data)
	data.updatedAt = new Date();
	firestore
		.collection("events")
		.doc(eventId)
		.update(data)
		.then(() => {
			coverPhotos.forEach((val, index) => {
				let ref = storage.ref(`eventCoverPhotos/${eventId}/${index}.jpg`);
				ref
					.put(val.blob, { cacheControl: "max-age=31536000" })
					.then(async (result) => {
						// console.log('updateEvent uploaded cover photo!', result)
						const downloadURL = await ref.getDownloadURL();
						firestore
							.collection("events")
							.doc(eventId)
							.update({ coverPhoto: downloadURL })
							.then(() => {
								data.coverPhoto = downloadURL;
								onSuccess(data);
								// Alert.alert("Success", "Update Successful", [
								//   { text: "Ok", onPress: () => onSuccess(data) },
								// ])
							});
					})
					.catch((err) => {
						console.log("err", err);
					});
			});
			if (coverPhotos?.length === 0) {
				onSuccess(data);
			}
		})
		.catch((err) => {
			console.log("err", err);
			// Alert.alert("Error", "An error has occured please try later.", [
			//   { text: "Ok", onPress: () => onSuccess(data) },
			// ])
		});
};

export const updateEventInterestStatus = async (
	eventId,
	interested,
	going,
	setInterested,
	setGoing
) => {
	const uid = auth.currentUser.uid;

	const data = {
		eventId,
		lastUpdated: new Date(),
		userId: uid
	};
	if (interested !== null) data.interested = !interested;
	if (going !== null) data.going = !going;

	if (data.going) data.interested = false;

	firestore
		.collection("event_interest")
		.doc(`${eventId}-${uid}`)
		.set(data, { merge: true })
		.then(() => {
			if (interested !== null) setInterested(!interested);
			if (going !== null) setGoing(!going);
		});
};

export const deleteEvent = (event, onSuccess) => {
	firestore
		.collection("events")
		.doc(event?.id)
		.delete()
		.then(() => {
			console.log("here", event.id);
			onSuccess();
		})
		.catch((e) => {
			window.alert("There was an error deleting your event. Please try again");
		});
};

export const checkInEventAttendee = async (
	eventId,
	userId,
	onSuccess,
	onError
) => {
	const eventInterestRef = firestore
		.collection("event_interest")
		.doc(`${eventId}-${userId}`);
	const doc = await eventInterestRef.get();
	if (doc.exists) {
		eventInterestRef.update({ attended: true }).then(() => {
			onSuccess();
		});
	} else {
		window.confirm(
			"User not listed as attending. Please have them RSVP and try again"
		);
		onError();
	}
};

export const undoCheckInEventAttendee = async (eventId, userId, onSuccess) => {
	const eventInterestRef = firestore
		.collection("event_interest")
		.doc(`${eventId}-${userId}`);
	const doc = await eventInterestRef.get();
	if (doc.exists) {
		eventInterestRef.update({ attended: false }).then(() => {
			onSuccess();
		});
	}
};

export const getEventAttendees = (eventId, setAttendees) => {
	return firestore
		.collection("event_interest")
		.where("eventId", "==", eventId)
		.onSnapshot(
			(querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const attendee = {
						id: doc.id,
						...doc.data()
					};
					temp.push(attendee);
				});
				let promises = temp.map((x) => getUsersFromMembersPromise(x.userId, x));
				Promise.all(promises)
					.then((users) => {
						setAttendees(
							users.sort((a, b) =>
								getUsersName(a).toLowerCase() > getUsersName(b).toLowerCase()
									? 1
									: -1
							)
						);
					})
					.catch((e) => {
						console.log("getEventAttendees error getting user promise", e);
					});
			},
			(e) => {
				console.log("getEventAttendees listening for attendees", e);
			}
		);
};


//check usernames and custom_urls if existing
export const checkCustomEventUrls = async (customUrl) => {
	const eventQuery = await firestore
		.collection("events")
		.where("customUrl", "==", customUrl)
		.get();
	console.log("eventQuery", eventQuery.size);
	if (eventQuery.size > 0) return true;
	return false;
};

export const saveCustomEventUrl = async (eventId, customUrl) => {
	let error;
	await firestore
		.collection("events")
		.doc(eventId)
		.update({ customUrl })
		.catch((e) => {
			error = e;
			console.log("error saving custom url", e);
		});
	return error;
};

export const getEventDonations = (selectedEvent, setHistory) => {
	return firestore
		.collection("donations")
		.where("eventId", "==", selectedEvent?.id)
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let history = [];
				querySnapshot.forEach((doc) => {
					history.push({
						...doc.data(),
						id: doc.id
					});
				});
				setHistory(history);
			},
			(e) => {
				console.log("getEventDonations error", e);
			}
		);
};