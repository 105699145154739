import React, { useEffect } from "react";
import RootNavigator from "./navigators/RootNavigator";
import { AuthProvider, useAuth } from "context/AuthContext";
import { DisplayProvider } from "context/DisplayContext";
import "./App.css";
import * as Geocode from "react-geocode";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import { StripeProvider } from "context/StripeContext";
import { LoadingProvider } from "context/LoadingContext";
import { CausesProvider } from "context/CausesContext";
import { OrganizationProvider } from "context/OrganizationContext";

function App() {
	const { setQueryParams } = useAuth();
	useEffect(() => {
		Geocode.setKey("AIzaSyDR0wfcPs5HkIoR4iJTVP8K-srlpOn-Yhs");

		  //log query params
		  const urlParams = new URLSearchParams(window.location.search);
		  console.log("urlParams", window.location.search);
		  setQueryParams(urlParams);

		// functions.useEmulator("localhost", 5001) //testing
	}, []);

	return <RootNavigator />;
}

export default () => {
	const path = window.location.pathname;
	if (isMobile && (isIOS || isAndroid) && path.includes("appstore")) {
		// console.log("isMobile", isIOS, isAndroid);
		if (isIOS) {
			window.location.href = "https://apps.apple.com/us/app/rayze/id1615610467";
		} else {
			window.location.href =
				"https://play.google.com/store/apps/details?id=com.rayze";
		}
	} else {
		// console.log("not mobile");
		return (
			<DisplayProvider>
				<AuthProvider>
					<CausesProvider>
						<OrganizationProvider>
							<StripeProvider>
								<LoadingProvider>
									<App />
								</LoadingProvider>
							</StripeProvider>
						</OrganizationProvider>
					</CausesProvider>
				</AuthProvider>
			</DisplayProvider>
		);
	}
};
