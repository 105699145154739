import { StyleSheet, View, TouchableOpacity } from "react-native";
import React from "react";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { FaLandmark } from "react-icons/fa"
import { creditCardTypes } from "constants/Data";

export default function PaymentMethodCard(props) {
  const {
    isSelected,
    onSelect,
    style: externalStyle,
    item,
    darkMode = true
  } = props;
	const { card = null, isDefault, us_bank_account = null } = item;
	const account = card ? card : us_bank_account;
	// if (!card) return null;
	const { brand = "Bank Account", last4 } = account;
	let desc = "xxxx-xxxx-xxxx-" + last4;

	//find credit card with regex
	const cardType = creditCardTypes.find((x) => x.id === brand);
	const cardIcon = cardType ? cardType.icon : <FaLandmark size={20} />;

  const style = styles(darkMode);

  return (
    <TouchableOpacity
      style={[style.container, isSelected && style.selected, externalStyle]}
      onPress={onSelect}
    >
      {cardIcon}
      <View style={style.textsContainer}>
        <View style={style.row}>
          <PrimaryText fontSize={14} fontWeight={600} style={style.brand}>
            {brand}
          </PrimaryText>
          <PrimaryText fontSize={10} fontWeight={300}>
            {desc}
          </PrimaryText>
        </View>

        {/* {isSelected && (
          <View style={[style.row, { marginTop: 4 }]}>
            <PrimaryText fontSize={10} fontWeight={300}>
              {name}
            </PrimaryText>
            {exp_month && exp_year && (
              <PrimaryText fontSize={10} fontWeight={300}>
                {exp_month}/{exp_year}
              </PrimaryText>
            )}
          </View>
        )} */}
      </View>
      {/* {rightIcon && <Feather name={rightIcon} size={24} />} */}
      {isDefault && (
        <View
          style={{
            position: "absolute",
            right: -1,
            top: -1,
            backgroundColor: COLORS.primary.lightBlue,
            borderTopRightRadius: 11,
            borderBottomLeftRadius: 11,
            paddingHorizontal: 12,
            paddingVertical: 2,
          }}
        >
          <PrimaryText
            fontSize={10}
            fontWeight={600}
            style={{ color: COLORS.primary.white }}
          >
            Default
          </PrimaryText>
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = (darkMode) =>
  StyleSheet.create({
    container: {
      // width: "80%",
      maxWidth: 300,
      borderRadius: 12,
      borderWidth: 1,
      borderColor: COLORS.primary.lightGray,
      backgroundColor: darkMode ? 'transparent' : COLORS.primary.white,
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: 14,
      paddingRight: 18,
      marginBottom: 20,
      paddingVertical: 0,
      height: 60,
    },
    selected: {
      borderColor: COLORS.primary.lightBlue,
      backgroundColor: darkMode ? 'rgba(121, 196, 242, 0.35)' : COLORS.secondary.lightBlue,
      paddingVertical: 18,
      height: null,
    },
    textsContainer: {
      flex: 1,
      marginLeft: 12,
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      flex: 1,
    },
    brand: {
      textTransform: "capitalize",
    },
  });
