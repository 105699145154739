import React, { useEffect, useState } from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View, ScrollView, TouchableOpacity } from "react-native";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import { useAuth } from "context/AuthContext";
import { useOrganization } from "context/OrganizationContext";
import { ActivityIndicator, Image } from "react-native-web";
import {
  deleteOrgDoc,
  getOrgDocuments,
  uploadOrgDoc,
} from "services/document-service";
import { GiTrashCan } from "react-icons/gi";
import { ConfirmModal } from "components/Modals/Confirmation";
import { functions } from "utils/firebase";
import { Button } from "components/Common/Button";
import { updateOrganization } from "services/organizations-service";

export const Verification = () => {
  const { selectedOrg, userType, currentMember } = useOrganization();
  const { currentUser } = useAuth();

  const [files, setFiles] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOrgDocuments(selectedOrg?.id, setFiles);
  }, []);

  function onFileSelected(e) {
    if (e.target.files && e.target.files.length > 0) {
      // const reader = new FileReader()
      // reader.addEventListener(
      //   "load",
      //   () => console.log('test', reader.result)
      // )
      setNewFile(e.target.files[0]);
    }
  }

  function onSave() {
    setLoading(true);
    uploadOrgDoc(selectedOrg?.id, newFile, onSuccessSave);
  }

  function onSuccessSave() {
    functions.httpsCallable("orgDocumentUploaded")({
      name: selectedOrg?.name,
      orgId: selectedOrg?.id,
      fileName: newFile?.name,
    });
    setNewFile(null);
    setLoading(false);
    getOrgDocuments(selectedOrg?.id, setFiles);
    window.alert("File uploaded successfully");
    updateOrganization(
      selectedOrg,
      { pendingVerification: true },
      [],
      [],
      () => {}
    );
  }

  function deleteFile(file) {
    setFileToDelete(file);
  }

  function confirmDeleteFile() {
    deleteOrgDoc(selectedOrg?.id, fileToDelete, onSuccessDelete);
  }

  function onSuccessDelete() {
    setFileToDelete(null);
    setLoading(false);
    getOrgDocuments(selectedOrg?.id, setFiles);
    window.alert("File deleted successfully");
  }

  return (
    <View style={styles.container}>
      <Image
        source={
          selectedOrg?.verified
            ? require("assets/verificationLarge.png")
            : require("assets/rayzeLogo.png")
        }
        style={{ width: "100%", height: 177 }}
        resizeMode={"contain"}
      />
      <PrimaryText
        fontSize={24}
        fontWeight={800}
        style={{ color: COLORS.primary.darkBlue, textAlign: "center" }}
      >
        {selectedOrg?.verified
          ? ""
          : "To become a verified nonprofit organization, upload your\nIRS Tax Exempt Determination Letter"}
      </PrimaryText>
      {!selectedOrg?.verified && (
        <PrimaryText
          fontSize={20}
          fontWeight={400}
          style={{
            color: COLORS.primary.gray,
            textAlign: "center",
            marginVertical: 8,
          }}
        >
          {"Someone from Rayze will reach out to you\nin 2-3 business days"}
        </PrimaryText>
      )}
      {!selectedOrg?.verified ? (
        <>
          <input
            id="choose-file"
            type="file"
            onChange={onFileSelected}
            style={{ display: "none" }}
          />
          <Button
            title={"Upload Document"}
            onPress={() => document.getElementById("choose-file").click()}
            iconRight={
              <FaIcons.FaPlus size={16} color={COLORS.primary.white} />
            }
            style={{ marginBottom: 12, alignSelf: "center" }}
          />

          <PrimaryText
            fontSize={12}
            fontWeight={400}
            style={{ textAlign: "center", marginBottom: 12 }}
          >
            {
              "*Please note that if your organization has a fiscal sponsor, you will \nalso need to upload a letter or a copy of existing documentation \nthat confirms the fiscal sponsor relationship in order to be verified."
            }
          </PrimaryText>
        </>
      ) : (
        <PrimaryText
          fontSize={75}
          fontWeight={800}
          style={{ textAlign: "center" }}
        >
          You're Verified!
        </PrimaryText>
      )}
      {newFile && (
        <View
          style={{ flexDirection: "row", alignItems: "center", margin: 12 }}
        >
          <FiIcons.FiFilePlus size={30} color={COLORS.primary.lightGray} />
          <PrimaryText
            fontSize={20}
            fontWeight={800}
            style={{ marginHorizontal: 12 }}
          >
            {newFile?.name}
          </PrimaryText>
          <Button title={"Save"} onPress={onSave} />
        </View>
      )}

      <Table
        className="table"
        style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
      >
        <TableHead>
          <TableRow>
            <TableCell>File</TableCell>
            <TableCell>Uploaded At</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {files &&
            files.map((file, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{file?.name}</TableCell>
                  <TableCell>
                    {new Date(file?.timeCreated).toDateString()}
                  </TableCell>
                  <TableCell>
                    {!selectedOrg?.verified && (
                      <TouchableOpacity onPress={() => deleteFile(file)}>
                        <GiTrashCan size={20} color={COLORS.primary.error} />
                      </TouchableOpacity>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      {!!fileToDelete && (
        <ConfirmModal
          title={"Delete file"}
          message={"Are you sure?"}
          onConfirm={confirmDeleteFile}
          onConfirmText={"Yes"}
          onCancel={() => setFileToDelete(null)}
        />
      )}

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.lightBlue}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  container: {
    width: "100%",
    alignItems: "center",
    // backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    borderTopLeftRadius: 0,
    paddingVertical: 12,
  },
  saveButton: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    // width: 200,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    margin: 12,
    borderRadius: 100,
  },
  headerContainer: {
    flexDirection: "row",
  },
  infoContainer: {
    backgroundColor: COLORS.primary.white,
    marginHorizontal: 12,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  title: {
    marginBottom: 12,
  },
});
