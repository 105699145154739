import { firestore } from "utils/firebase";

export const getCauses = (setCauses) => {
    firestore
      .collection('causes')
      .orderBy('lowercaseName', 'asc')
      .get()
      .then((querySnapshot) => {
        let causes = [];
  
        querySnapshot.forEach((doc) => {
          causes.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setCauses(causes);
      })
      .catch((error) => {
        console.log('getCauses err', error);
      });
};

export const getOurCauses = (item, allCauses, setOurCauses) => {
    let temp = []
    item.causes && item.causes.length &&
    item.causes.map(x => {
      let cause = allCauses.find(y => y.id===x)
      cause && temp.push(cause)
    })
    setOurCauses(temp)
}