import moment from "moment";

export const parseEventData = (doc, currentUser) => {
	try {
		const eventData = doc.data();
		let createdAt = eventData?.createdAt?.toDate?.();
		let date = eventData?.date?.toDate?.();
		let endTime = eventData?.endTime?.toDate?.();
		let startTime = eventData?.startTime?.toDate?.();

		const event = {
			...eventData,
			createdAt,
			date,
			endTime,
			startTime,
			id: doc.id
		};

		return event;
	} catch (e) {
		console.log("error parsing event data", e, doc.id, currentUser?.id);
	}
};

export const getEventDate = (event, short) => {
	const date = event?.date ? new Date(event?.date).toDateString() : "";
	const startTime = event?.startTime
		? new Date(event?.startTime).toLocaleTimeString([], {
				hour: "numeric",
				minute: "2-digit",
				timeZoneName: short ? "short" : undefined
		  })
		: "";

	const endTime = event?.endTime
		? new Date(event?.endTime).toLocaleTimeString([], {
				hour: "numeric",
				minute: "2-digit",
				timeZoneName: "short"
		  })
		: "";

	return `${date} @ ${startTime}${short ? "" : " - " + endTime}`;
};

export const getEventDateWithoutTime = (event) => {
	const date = event?.date ? new Date(event?.date) : "";
	return moment(date).format("dddd, MMMM D");
};
