import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { FullModal } from "./FullModal";
import { PrimaryText } from "components/Common/PrimaryText";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { COLORS } from "constants/Colors";

export default function DeactiveLinkConfirmation({ setShow, onDeactivate }) {
  return (
    <FullModal
      title="Deactivate Link"
      setShowModal={setShow}
      style={{ maxWidth: 516 }}
    >
      <PrimaryText fontSize={14} style={{ textAlign: "center" }}>
        Please note: Anyone who this link has been sent to{"\n"}will not be able
        to use it. If you copy the link again it{"\n"}will be a new version.
      </PrimaryText>

      <View style={styles.btnRow}>
        <PrimaryButton
          title="Cancel"
          variant="outlined"
          style={{
            paddingHorizontal: 24,
          }}
          onPress={() => setShow(false)}
        />

        <PrimaryButton
          title="Deactivate Link"
          style={{
            backgroundColor: COLORS.primary.error,
            paddingHorizontal: 24,
          }}
          onPress={onDeactivate}
        />
      </View>
    </FullModal>
  );
}

const styles = StyleSheet.create({
  btnRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 24,
    marginTop: 25,
  },
});
