import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { COLORS } from "constants/Colors";
import * as Geocode from "react-geocode";
import { useDisplay } from "context/DisplayContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { CustomInput } from "../Inputs/CustomInput";
import { FaCheck, FaEdit, FaPencilAlt } from "react-icons/fa";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Cropper from "react-easy-crop";
import getCroppedImg from "screens/Settings/cropImage";
import { Image, TouchableOpacity } from "react-native-web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RiAlertFill } from "react-icons/ri";
import { CausesSelect } from "components/CausesSkills/CausesSelect";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { Button } from "components/Common/Button";
import { FullModal } from "components/Modals/FullModal";

const options = [
	{ value: "", label: "" },
	{ value: "In-Person", label: "In-Person" },
	{ value: "Virtual", label: "Virtual" }
];

const geofire = require("geofire-common");

export const AddEventInfoForm = (props) => {
	const {
		setCoverPhotos,
		coverPhotos,
		details,
		setDetails,
		address,
		selectedCauses,
		setSelectedCauses
	} = props;
	const { darkMode } = useDisplay();
	const [dateModal, setDateModal] = useState(false);
	const [startTimeModal, setStartTimeModal] = useState(false);
	const [endTimeModal, setEndTimeModal] = useState(false);
	const [value, setValue] = useState(null);

	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedCoverPhotoArea, setCroppedCoverPhotoArea] = useState(null);
	const [croppedCoverPhoto, setCroppedCoverPhoto] = useState(coverPhotos[0]);

	const [showPhotoModal, setShowPhotoModal] = useState(false);

	const { line1, city, state, zipCode } = props?.details?.address || {};
	useEffect(() => {
		city &&
			setValue({ label: line1 + ", " + city + ", " + state + " " + zipCode });
	}, []);

	const getLatLong = (searchText) => {
		setValue(searchText);
		if (!searchText) return;
		Geocode.fromAddress(searchText.label)
			.then((json) => {
				var addressComponent = json.results[0].address_components;
				var streetNumber;
				var fullAddress;
				var city;
				var state;
				var zipCode;
				const latLng = json.results[0].geometry.location;
				addressComponent.forEach((comp, index) => {
					let types = comp.types;
					if (types.find((x) => x === "street_number")) {
						streetNumber = comp.long_name + " ";
					} else if (types.find((x) => x === "route")) {
						if (streetNumber !== null) {
							fullAddress = streetNumber + comp.long_name;
						}
					} else if (types.find((x) => x === "locality")) {
						city = comp.long_name;
					} else if (types.find((x) => x === "administrative_area_level_1")) {
						state = comp.long_name;
					} else if (types.find((x) => x === "postal_code")) {
						zipCode = comp.long_name;
					}
					if (!city && types.find((x) => x === "sublocality")) {
						city = comp.long_name;
					}
				});
				const { lat, lng } = latLng;
				const hash = geofire.geohashForLocation([lat, lng]);
				setDetails({
					...details,
					address: {
						line1: fullAddress,
						city: city,
						zipCode: zipCode,
						state: state
					},
					latLng: latLng,
					hash
				});
			})
			.catch((e) => {
				console.log("error getting lat lng", e);
			});
	};

	function onSelectCoverPhoto(e) {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener(
				"load",
				() => {
					setZoom(1);
					setCroppedCoverPhoto(reader.result.toString() || "");
					setShowPhotoModal("coverPhoto");
				}
				// setCrop(reader.result.toString() || "")
			);
			reader.readAsDataURL(e.target.files[0]);
		}
	}

	const onCropCoverPhotoComplete = useCallback(
		(croppedArea, croppedAreaPixels) => {
			setCroppedCoverPhotoArea(croppedAreaPixels);
		},
		[]
	);

	const showCroppedImage = useCallback(
		async (type) => {
			try {
				const finalImage = await getCroppedImg(
					croppedCoverPhoto,
					croppedCoverPhotoArea,
					rotation
				);
				setCoverPhotos([finalImage]);
			} catch (e) {
				console.error(e);
			}
		},
		[croppedCoverPhotoArea, rotation]
	);

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			borderBottom: "1px solid lightgray",
			color: state.isSelected ? COLORS.primary.white : COLORS.primary.darkGray,
			paddingHorizontal: 12,
			fontFamily: "Montserrat",
			fontSize: 14
		}),
		valueContainer: (provided, state) => ({
			...provided,
			width: 115
		}),
		singleValue: (styles) => ({
			...styles,
			color: COLORS.primary.darkGray,
			fontFamily: "Montserrat",
			fontSize: 14
		})
	};

	const DateInput = forwardRef(({ value, onClick }, ref) => (
		<CustomInput
			title={`Date`}
			mandatory
			placeholder={"DD/MM/YYYY"}
			value={new Date(value)}
			onChange={(e) =>
				setDetails({
					...details,
					date: e
				})
			}
			date
			onPress={onClick}
			innerRef={ref}
		/>
	));

	const StartTimeInput = forwardRef(({ value, onClick }, ref) => (
		<CustomInput
			innerRef={ref}
			title={"Start Time"}
			mandatory
			placeholder={"Select start time"}
			value={new Date(details.startTime)}
			onChange={(e) =>
				setDetails({
					...details,
					startTime: e
				})
			}
			date
			time
			timeModalOpen={startTimeModal}
			onPress={() => {
				setStartTimeModal(true);
				onClick();
			}}
		/>
	));

	const EndTimeInput = forwardRef(({ value, onClick }, ref) => (
		<CustomInput
			innerRef={ref}
			title={"End Time"}
			mandatory
			placeholder={"Select end time"}
			value={new Date(details.endTime)}
			date
			time
			timeModalOpen={endTimeModal}
			onPress={() => {
				setEndTimeModal(true);
				onClick();
			}}
		/>
	));

	const style = styles(darkMode);

	return (
		<View style={style.container_v}>
			{/** Location */}
			<View style={[style.form_item_v, { zIndex: 999, marginBottom: 12 }]}>
				<CustomPicker
					title={"In-person or virtual"}
					mandatory
					styles={customStyles}
					value={
						!details?.mode
							? null
							: options.find((x) => x.value === details?.mode)?.value
					}
					onChange={(value) => setDetails({ ...details, mode: value })}
					options={options}
					placeholder={"Select type..."}
				/>
			</View>

			{details.mode === "Virtual" && (
				<View style={style.form_item_v}>
					<CustomInput
						title={`Virtual meeting information*`}
						multiline
						placeholder={"Enter link, passcode, etc...\n(500 characters max)"}
						value={details?.meetingInfo}
						maxLength={500}
						onChangeText={(text) =>
							setDetails({
								...details,
								meetingInfo: text
							})
						}
					/>
				</View>
			)}
			{details.mode !== "Virtual" && (
				<View style={[style.form_item_v, { zIndex: 998, marginBottom: 12 }]}>
					<PrimaryText
						fontSize={14}
						fontWeight={700}
						style={style.textinput}
					>
						Address*
					</PrimaryText>
					<GooglePlacesAutocomplete
						selectProps={{
							isClearable: true,
							value: value,
							onChange: getLatLong,
							placeholder: "Select event address..."
						}}
						apiKey={"AIzaSyDR0wfcPs5HkIoR4iJTVP8K-srlpOn-Yhs"}
					/>
					{!!(details?.address?.city && !details?.address?.line1) && (
						<View style={style.errorContainer}>
							<RiAlertFill
								width={16}
								height={16}
								fill={COLORS.primary.error}
								stroke={COLORS.primary.white}
							/>
							<PrimaryText
								fontSize={12}
								fontWeight={400}
								style={style.errorMessage}
							>
								{"Invalid address."}
							</PrimaryText>
						</View>
					)}
				</View>
			)}

			{/** Name */}
			<View style={style.form_item_v}>
				<CustomInput
					title={`Event Name`}
					mandatory
					placeholder={"Enter event name"}
					value={details.name}
					// maxLength={24}
					onChangeText={(text) =>
						setDetails({
							...details,
							name: text
						})
					}
					name
				/>
			</View>

			{/** About */}
			<View style={[style.form_item_v, { zIndex: 0 }]}>
				<CustomInput
					title={`About`}
					multiline
					mandatory
					maxLength={500}
					placeholder={"Describe your event\n(500 characters max)"}
					value={details.about}
					onChangeText={(text) =>
						setDetails({
							...details,
							about: text
						})
					}
					onPress={() => console.log("test")}
				/>
			</View>

			<View style={[{ zIndex: 998, marginBottom: 12 }]}>
				<DatePicker
					selected={details?.date}
					onChange={(date) =>
						setDetails({
							...details,
							date
						})
					}
					customInput={<DateInput />}
				/>
			</View>

			<View style={style.splitInputContainer}>
				<View style={[style.splitInput, { flexDirection: "column-reverse" }]}>
					<DatePicker
						selected={details?.startTime}
						customInput={<StartTimeInput />}
						showTimeSelect
						showTimeSelectOnly
						onCalendarClose={() => setStartTimeModal(false)}
						onChange={(e) =>
							setDetails({
								...details,
								startTime: e
							})
						}
					/>
				</View>
				<View style={[style.splitInput, { flexDirection: "column-reverse" }]}>
					<DatePicker
						selected={details?.startTime}
						customInput={<EndTimeInput />}
						showTimeSelect
						showTimeSelectOnly
						onCalendarClose={() => setEndTimeModal(false)}
						onChange={(e) =>
							setDetails({
								...details,
								endTime: e
							})
						}
					/>
				</View>
			</View>
			{endTimeModal && (
				<View style={style.dateContainer}>
					{/* <DatePicker
              date={details.endTime}
              onDateChange={(e) =>
                setDetails({
                  ...details,
                  endTime: e,
                })
              }
              mode={"time"}
              textColor={getDarkModeText(darkMode)}
            /> */}
				</View>
			)}
			{/* {startTimeModal && (
        
      )} */}

			<View style={style.form_item_v}>
				<PrimaryText
					fontSize={14}
					fontWeight={700}
					style={style.textinput}
				>
					Cover Photo*:
				</PrimaryText>
				<View style={style.settingsRow}>
					<TouchableOpacity
						disabled={coverPhotos[0]}
						onPress={() =>
							document.getElementById("choose-cover-photo").click()
						}
						style={[
							style.cover_img_v,
							!coverPhotos[0] && {
								borderWidth: 1,
								borderColor: "lightgray",
								borderRadius: 12
							}
						]}
					>
						<img
							style={{
								width: coverPhotos[0] ? "100%" : undefined,
								height: "100%",
								borderRadius: 12,
								objectFit: "contain",
								alignSelf: "center"
							}}
							src={
								coverPhotos[0]
									? coverPhotos[0].uri
										? coverPhotos[0]?.uri
										: coverPhotos[0]
									: require("assets/misc/emptyPicRect.png")
							}
						/>
						{!coverPhotos[0] && (
							<Image
								source={require("assets/misc/plusCircle.png")}
								style={{
									width: 24,
									height: 24,
									position: "absolute",
									bottom: "46%",
									right: "47%"
								}}
							/>
						)}
					</TouchableOpacity>
				</View>
				<input
					id="choose-cover-photo"
					type="file"
					accept="image/*"
					onChange={onSelectCoverPhoto}
					style={{ display: "none" }}
				/>
				{coverPhotos[0] && (
					<TouchableOpacity
						style={{ alignSelf: "flex-start", marginLeft: 4 }}
						onPress={() =>
							document.getElementById("choose-cover-photo").click()
						}
					>
						<Button
							title={"Edit/Change"}
							style={{
								paddingVertical: 4,
								text: {
									fontSize: 12,
									fontWeight: 700
								}
							}}
							iconLeft={
								<FaEdit
									size={14}
									color={COLORS.primary.white}
									style={{ marginRight: 4 }}
								/>
							}
							onPress={() => {
								// setZoom(1)
								setShowPhotoModal("coverPhoto");
							}}
						/>
					</TouchableOpacity>
				)}
			</View>

			<View style={style.form_item_v}>
				<PrimaryText
					fontSize={14}
					fontWeight={700}
					style={style.textinput}
				>
					Causes*:
				</PrimaryText>
				<CausesSelect
					causes={details?.causes}
					selectedCauses={selectedCauses}
					setSelectedCauses={setSelectedCauses}
				/>
			</View>

			{!!showPhotoModal && (
				<FullModal
					title={"Upload Cover Photo"}
					subtitle={`Upload a minimum size of "1300 x 730px" for best results.`}
					setShowModal={setShowPhotoModal}
					// containerStyle={{height: height}}
					containerStyle={{ width: "100%", height: "100%" }}
					// style={{}}
				>
					<View style={{ flex: 1, paddingHorizontal: 36, paddingBottom: 24 }}>
						<View
							style={{
								width: "100%",
								aspectRatio: 16 / 9,
								borderRadius: 4,
								overflow: "hidden",
								maxHeight: 300
							}}
						>
							<Cropper
								image={croppedCoverPhoto}
								crop={crop}
								rotation={rotation}
								zoom={zoom}
								aspect={16 / 9}
								onCropChange={setCrop}
								onRotationChange={setRotation}
								onCropComplete={onCropCoverPhotoComplete}
								onZoomChange={setZoom}
							/>
						</View>

						<View
							style={{
								width: "80%",
								alignSelf: "center",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								marginTop: 20
							}}
						>
							<PrimaryText
								fontSize={12}
								fontWeight={700}
								style={{ color: COLORS.primary.darkGray, marginRight: 8 }}
							>
								Zoom
							</PrimaryText>
							<input
								type="range"
								value={zoom}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby="Zoom"
								onChange={(e) => {
									setZoom(e.target.value);
								}}
								className="zoom-range"
							/>
						</View>

						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								paddingHorizontal: 36,
								marginTop: 20
							}}
						>
							<Button
								title={"Change Photo"}
								onPress={() =>
									document
										.getElementById(
											showPhotoModal === "coverPhoto"
												? "choose-cover-photo"
												: "choose-profile-photo"
										)
										.click()
								}
								style={{
									backgroundColor: COLORS.primary.offWhite,
									borderColor: COLORS.primary.darkGray,
									borderWidth: 1,
									text: {
										color: COLORS.primary.darkGray
									}
								}}
							/>
							<Button
								title={"Confirm"}
								onPress={() => {
									showCroppedImage(showPhotoModal);
									setShowPhotoModal(false);
								}}
								// onPress={saveNewCampaign}
								// style={{
								//   opacity: saveCampaignDisabled ? 0.5 : 1,
								// }}
								// disabled={saveCampaignDisabled}
							/>
						</View>
					</View>
				</FullModal>
			)}
		</View>
	);
};

const styles = (darkMode) =>
	StyleSheet.create({
		container_v: {
			paddingHorizontal: 20,
			maxWidth: 500
		},
		form_item_v: {
			width: "100%",
			marginBottom: 12,
			// zIndex: 0,
			borderWidth: 0
		},
		form_item: {
			height: 50
		},
		verticalScroll: {
			flex: 1,

			alignContent: "center"
		},
		errorContainer: {
			backgroundColor: "#FEEFEF",
			height: 32,
			justifyContent: "flex-start",
			alignItems: "center",
			paddingHorizontal: 16,
			paddingVertical: 6,
			borderRadius: 8,
			flexDirection: "row",
			marginBottom: 12
		},
		errorMessage: {
			color: COLORS.primary.error,
			paddingLeft: 8
		},
		coverPhotos: {
			fontSize: 16,
			fontWeight: "600"
		},
		textinput: {
			paddingLeft: 10,
			color: darkMode ? COLORS.primary.white : COLORS.primary.darkGray
		},
		dateContainer: {
			marginHorizontal: 6,
			backgroundColor: COLORS.primary.white,
			alignItems: "center"
		},
		splitInputContainer: {
			flexDirection: "row",
			justifyContent: "space-between",
			zIndex: 997,
			marginBottom: 12
		},
		splitInput: { width: "48%" },
		cover_img_v: {
			width: 300,
			aspectRatio: 16 / 9,
			alignSelf: "center"
		},
		settingsRow: {
			flexDirection: "row",
			margin: 12,
			alignItems: "center"
		}
	});
