import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { CustomInput } from "components/Inputs/CustomInput";
import { Links } from "constants/Links";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "context/LoadingContext";
import { signUpWithEmail } from "services/auth-service";

export default function EnterPasscode() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const { email, password } = state;

  const [passcode, setPasscode] = useState("");
  const [passcodeError, setPasscodeError] = useState("");

  const onVerifyPasscode = () => {
    if (passcode?.length < 6)
      return setPasscodeError("Please enter a valid passcode.");

    if (!window.confirmationResult)
      return navigate(Links.VerifyPersonalAccount);

    setLoading(true);

    window.confirmationResult
      .confirm(passcode)
      .then((result) => {
        const { uid } = result.user;
        signUpWithEmail(email, password);
        navigate(Links.PersonalInformation, { state: { ...state, uid } });
      })
      .catch((err) => {
        console.log(err);
        setPasscodeError(err.message);
      })
      .finally((_) => setLoading(false));
  };

  const handleChange = (e) => {
    const { value } = e.target;

    if (value?.length < 6)
      setPasscodeError("Passcode must be of 6 characters.");
    else setPasscodeError();

    setPasscode(e.target.value);
  };

  return (
    <View style={styles.container}>
      <img src={require("assets/rayze.png")} width={77.64} />

      <PrimaryText fontSize={54} fontWeight="900">
        Create Personal Account
      </PrimaryText>

      <PrimaryText
        fontSize={32}
        style={{ color: COLORS.primary.gray, marginTop: 14 }}
      >
        Confirm Your Account
      </PrimaryText>

      <View style={{ width: 541, marginVertical: 69 }}>
        <CustomInput
          placeholder="Enter Passcode*"
          value={passcode}
          onChange={handleChange}
          errorMessage={passcodeError}
        />
      </View>

      <PrimaryButton title="Continue" onPress={onVerifyPasscode} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
