import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { COLORS } from "constants/Colors";
import { StyleSheet } from "react-native-web";
import moment from "moment";
import { Button } from "components/Common/Button";

export const BusinessDonationApprovalCard = ({ item, onApprove, onDeny }) => {
  const {
    userId,
    amount,
    date,
    recurring,
    organization = {},
    match = 0,
    corporateStatus = 0,
    anonymous = false,
    approved = corporateStatus === 1,
    denied = corporateStatus === 2,
  } = item;

  const [user, setUser] = useState({});

  useEffect(() => {
    if (anonymous) {
      setUser({
        firstName: "Anonymous",
        lastName: "",
        profilePic: "",
      });
      return;
    }
    getUser(userId, setUser);
  }, [userId]);

  let color = COLORS.green;
  switch (corporateStatus) {
    case 0:
      color = COLORS.primary.orange;
      break;
    case 1:
      color = COLORS.primary.green;
      break;
    case 2:
      color = COLORS.red;
      break;
    default:
      color = COLORS.orange;
      break;
  }

  return (
    <TableRow>
      <TableCell width={36}>
        <ProfilePic uri={user?.profilePic} size={36} />
      </TableCell>
      <TableCell style={{ textAlign: "left" }}>
        <PrimaryText fontSize={12} fontWeight={400}>
          {user?.firstName ? getUsersName(user) : ""}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={12} fontWeight={400}>
          {organization?.name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={12} fontWeight={400}>
          {moment(date.toDate()).format("MM/DD/YYYY")}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={12} fontWeight={400}>
          {recurring ? "recurring" : "one-time"}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={12} fontWeight={400}>
          {dollarFormatter.format(amount)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <Button
          style={{
            backgroundColor: approved ? COLORS.primary.green : "transparent",
            opacity: denied ? 0.5 : 1,
            borderColor: COLORS.primary.green,
            borderWidth: 1,
            text: {
              color: approved ? COLORS.primary.white : COLORS.primary.green,
              fontSize: 12,
              fontWeight: 700,
            },
          }}
          onPress={onApprove}
          title={approved ? "Approved" : "Approve"}
          disabled={false}
        />
      </TableCell>
      <TableCell>
        <Button
          style={{
            backgroundColor: denied ? COLORS.primary.error : "transparent",
            opacity: approved ? 0.5 : 1,
            borderColor: COLORS.primary.error,
            borderWidth: 1,
            text: {
              color: denied ? COLORS.primary.white : COLORS.primary.error,
              fontSize: 12,
              fontWeight: 700,
            },
          }}
          onPress={onDeny}
          title={denied ? "Denied" : "Deny"}
          disabled={corporateStatus === 1}
        />
      </TableCell>
    </TableRow>
  );
};

const styles = StyleSheet.create({
  approveButton: {
    backgroundColor: "transparent",
    borderColor: COLORS.primary.green,
    borderWidth: 1,
  },
});
