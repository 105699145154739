import { PrimaryText } from "components/Common/PrimaryText";
import React from "react";
import { StyleSheet, View } from "react-native";
import { COLORS } from "constants/Colors";
import { RiAlertFill } from "react-icons/ri";
import { MenuItem, Select } from "@mui/material";
import * as FiIcons from "react-icons/fi";

export const CustomPicker = (props) => {
	const {
		title,
		value,
		onChange,
		errorMessage,
		mandatory,
		options,
		placeholder,
		label: customLabel = null,
		backgroundColor = COLORS.primary.offWhite
	} = props;

	// console.log(options);

	const style = styles();

	return (
		<View style={[style.container, props?.style && { ...props.style }]}>
			{!!title && (
				<PrimaryText
					fontSize={14}
					fontWeight={700}
					style={style.inputTitle}
				>
					{title}
					{mandatory && (
						<PrimaryText
							fontSize={14}
							fontWeight={700}
							style={{ color: COLORS.primary.error }}
						>
							{"*"}
						</PrimaryText>
					)}
				</PrimaryText>
			)}

			<Select
				displayEmpty
				value={value ?? ""}
				placeholder={"Select..."}
				onChange={(e) => onChange?.(e.target.value)}
				fullWidth
				style={{
					borderRadius: 15,
					borderWidth: 0.5,
					height: 30,
					backgroundColor,
					borderColor: COLORS.primary.lightGray
				}}
				IconComponent={() => (
					<FiIcons.FiChevronDown
						size={24}
						style={{ marginRight: 5 }}
					/>
				)}
				renderValue={(value) => {
					let label =
						options?.find((x) => x.value === value)?.label ??
						(placeholder || "Select...");

					return (
						<PrimaryText
							fontSize={14}
							fontWeight={400}
							style={{
								fontSize: 14,
								color: COLORS.primary.darkGray,
								fontWeight: 400,
								fontFamily: "Montserrat"
							}}
							numberOfLines={1}
						>
							{customLabel ? customLabel : label}
						</PrimaryText>
					);
				}}
			>
				{options &&
					options.map((item) => {
						return (
							<MenuItem
								value={item?.value}
								key={item?.value}
							>
								<PrimaryText
									fontSize={12}
									fontWeight={400}
								>
									{item?.label}
								</PrimaryText>
							</MenuItem>
						);
					})}
			</Select>

			{errorMessage && (
				<View style={style.errorContainer}>
					<RiAlertFill
						width={16}
						height={16}
						fill={COLORS.primary.error}
						stroke={COLORS.primary.white}
					/>
					<PrimaryText
						fontSize={14}
						fontWeight={400}
						style={style.errorMessage}
					>
						{errorMessage}
					</PrimaryText>
				</View>
			)}
		</View>
	);
};

const styles = (multiline) =>
	StyleSheet.create({
		container: {
			flexGrow: 1,
			flexDirection: "column"
			// marginBottom: 12,
		},
		inputTitle: {
			paddingLeft: 10,
			marginBottom: 5,
			color: COLORS.primary.darkGray
		},
		errorContainer: {
			backgroundColor: COLORS.background.lightRed,
			justifyContent: "flex-start",
			alignItems: "center",
			paddingHorizontal: 16,
			paddingVertical: 8,
			borderRadius: 8,
			flexDirection: "row"
		},
		errorMessage: {
			color: COLORS.primary.error,
			paddingLeft: 8
		}
	});
