import React, { useEffect, useState } from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View } from "react-native";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@mui/material";
import { useDonations } from "context/DonationsContext";
import { getUser } from "services/user-service";
import { useUsers } from "context/UserContext";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { PrimaryText } from "components/Common/PrimaryText";
import { ActivityIndicator, TouchableOpacity } from "react-native-web";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { CustomInput } from "components/Inputs/CustomInput";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { Button } from "components/Common/Button";
import { CustomPicker } from "components/Inputs/CustomPicker";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Filter } from "assets/svgs";
import WalletActivityCard from "components/Cards/WalletActivityCard";
import { LuImport, LuTrophy } from "react-icons/lu";
import { FiHeart, FiUser, FiUsers } from "react-icons/fi";
import MuiBarChart from "components/Charts/MuiBarChart";
import DepositFundsModal from "components/Modals/DepositFundsModal";
import EmployeeGiftModal from "components/Modals/EmployeeGiftModal";
import EmployeeGiftConfirmationModal from "components/Modals/EmployeeGiftConfirmationModal";
import EmployeeSelectionModal from "components/Modals/EmployeeSelectionModal";
import { pushData } from "services/pushdata-service";
import { dollarFormatter, getDecimalFromNumber, getIntegerFromNumber } from "utils/helpers";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import { useCauses } from "context/CausesContext";
import { useOrganization } from "context/OrganizationContext";
import * as FaIcons from "react-icons/fa";

const options = [{ value: "recent", label: "Recent" }];

export const RayzeWallet = () => {
	const navigate = useNavigate();
	const {
		recentDonations,
		topCauses,
		rayzeAccountBalance, rayzeAccountTransactions,
		monthlyDeposits, monthlyWithdrawals,
		totalDonationsThisYear, totalDonationsThisMonth,
		thisYearsDepositsByMonth,
		thisYearsWithdrawalsByMonth,
		selectedMonth, setSelectedMonth
	} = useBusinessDonations();
	const { setSelectedUser } = useUsers();
	const { allCauses } = useCauses();
	const { selectedOrg, members, users, invitedMembers, userType } =
		useOrganization();

	const [selectedView, setSelectedView] = useState(options[0]?.value);
	
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [filtered, setFiltered] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const [showModal, setShowModal] = useState();

	//gift
	const [giftAmount, setGiftAmount] = useState(0);
	const [allEmployees, setAllEmployees] = useState(true);
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [giftFrequency, setGiftFrequency] = useState("One-Time");
	const [giftTotal, setGiftTotal] = useState(0);
	const [paymentMethod, setPaymentMethod] = useState(null);

	useEffect(() => {
		let temp = [...rayzeAccountTransactions];
		if (search) {
			temp = temp.filter(
				(item) =>
					item.amount.toString().includes(search.toLowerCase()) ||
					item.user?.firstName.toLowerCase().includes(search.toLowerCase()) ||
					item.user?.lastName.toLowerCase().includes(search.toLowerCase()) ||
					item.description.toLowerCase().includes(search.toLowerCase())
			);
		}
		setFiltered(temp);
	}, [search, recentDonations, rayzeAccountTransactions]);

	function userSelected(donation) {
		let userId = donation?.userId;
		if (!userId) return;
		getUser(userId, onGotUser);
	}

	function onGotUser(user) {
		setSelectedUser(user);
		navigate(Links.DonorDetails);
	}

	const currentTableData = () => {
		const firstPageIndex = currentPage * rowsPerPage;
		const lastPageIndex = firstPageIndex + rowsPerPage;
		return filtered.slice(firstPageIndex, lastPageIndex);
	};

	const thisMonthName = selectedMonth.toLocaleString("default", { month: "long" });
	const thisYearName = selectedMonth.getFullYear();
	const isThisYearSelected = selectedMonth.getFullYear() === new Date().getFullYear();
	const isThisMonthSelected = isThisYearSelected && selectedMonth.getMonth() === new Date().getMonth();

	if (loading) {
		return (
			<View
				style={[styles.container, { paddingTop: "80%", alignItems: "center" }]}
			>
				<ActivityIndicator
					animating={loading}
					size={"large"}
					style={styles.activity}
					color={COLORS.primary.blue}
				/>
			</View>
		);
	}

	return (
		<View style={styles.container}>
			<PrimaryText
				fontSize={32}
				fontWeight={900}
			>
				Rayze Wallet
			</PrimaryText>

			<Box
				display="flex"
				flexDirection="row"
				alignItems="flex-start"
				mt={64}
				ml={10}
			>
				<Box>
					<PrimaryText
						fontSize={24}
						color={COLORS.primary.neutral_500}
					>
						Rayze Account Balance
					</PrimaryText>

					<Box
						display="flex"
						flexDirection="row"
						mt={4}
					>
						<PrimaryText
							fontSize={36}
							fontWeight={900}
							color={COLORS.primary.lightBlue}
							style={{ alignSelf: "flex-start" }}
						>
							$
						</PrimaryText>
						<PrimaryText
							fontSize={64}
							fontWeight={900}
							color={COLORS.primary.lightBlue}
							style={{ lineHeight: 54 }}
						>
							{getIntegerFromNumber(rayzeAccountBalance)}
						</PrimaryText>
						<PrimaryText
							fontSize={36}
							fontWeight={900}
							color={COLORS.primary.lightBlue}
							style={{ alignSelf: "flex-end" }}
						>
							{getDecimalFromNumber(rayzeAccountBalance)}
						</PrimaryText>
					</Box>
				</Box>

				<Button
					title="Deposit Funds"
					style={{ marginLeft: 70 }}
					iconLeft={
						<LuImport
							color="white"
							size={20}
						/>
					}
					onPress={() => setShowModal("deposit")}
				/>

				<PrimaryButton
					title="Gift Funds to Employees"
					variant="outlined"
					style={{
						height: 33,
						paddingHorizontal: 24,
						marginLeft: 32
					}}
					icon={<FiUser size={20} />}
					titleStyle={{ marginLeft: 8 }}
					onPress={() => setShowModal("employeeGift")}
				/>
			</Box>

			<Box
				display="flex"
				flexDirection="row"
				alignItems="flex-end"
				mb={24}
			>
				<Box>
					<Box
						display="flex"
						flexDirection="row"
						mt={12}
						ml={30}
						mb={24}
					>
						<PrimaryText fontSize={18}>$</PrimaryText>
						<PrimaryText
							fontSize={32}
							style={{ lineHeight: 26 }}
						>
							{totalDonationsThisYear}
						</PrimaryText>
						<PrimaryText
							fontSize={20}
							color={COLORS.primary.neutral_500}
							style={{ alignSelf: "flex-end", marginLeft: 20 }}
						>
							Tax Write-off
						</PrimaryText>
						<View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 12 }}>
							<TouchableOpacity
								onPress={() => {
									//set selected year to previous year
									setSelectedMonth(new Date(selectedMonth.setFullYear(selectedMonth.getFullYear() - 1)));
								}}
							>
								<FaIcons.FaChevronLeft size={16} color={COLORS.primary.lightBlue} />
							</TouchableOpacity>

							<PrimaryText
								fontSize={20}
								fontWeight={900}
								style={{ marginHorizontal: 4 }}
								color={COLORS.primary.lightBlue}
							>
								{thisYearName}
							</PrimaryText>

							<TouchableOpacity
								disabled={isThisYearSelected}
								onPress={() => {
									//set selected year to previous year
									setSelectedMonth(new Date(selectedMonth.setFullYear(selectedMonth.getFullYear() + 1)));
								}}
								style={{ opacity: isThisYearSelected ? 0.4 : 1 }}
							>
								<FaIcons.FaChevronRight
									size={16}
									color={COLORS.primary.lightBlue}
								/>
							</TouchableOpacity>
						</View>
					</Box>

					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						gap={24}
					>
						<MonthlyStatCard
							title="Monthly Recurring Deposits"
							amount={0}
							type="deposit"
						/>
						<MonthlyStatCard
							title="Monthly Recurring Withdrawals"
							amount={0}
							type="withdrawal"
						/>
					</Box>
				</Box>

				<MuiBarChart
					thisYearsDepositsByMonth={thisYearsDepositsByMonth}
					thisYearsWithdrawalsByMonth={thisYearsWithdrawalsByMonth}
				/>
			</Box>

			{/* Monthly Report */}
			<View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 12 }}>
				<PrimaryText
					fontSize={24}
					fontWeight={900}
					color={COLORS.primary.darkBlue}
				>
					Monthly Report 
				</PrimaryText>
				<TouchableOpacity
					onPress={() => {
						//set selected month to previous month
						setSelectedMonth(new Date(selectedMonth.setMonth(selectedMonth.getMonth() - 1)));
					}}
					style={{ marginLeft: 18 }}
				>
					<FaIcons.FaChevronLeft size={16} color={COLORS.primary.lightBlue} />
				</TouchableOpacity>

				<PrimaryText
					fontSize={24}
					fontWeight={900}
					style={{ marginHorizontal: 4 }}
					color={COLORS.primary.lightBlue}
				>
					{thisMonthName + " " + thisYearName}
				</PrimaryText>

				<TouchableOpacity
					disabled={isThisMonthSelected}
					onPress={() => {
						//set selected month to next month
						setSelectedMonth(new Date(selectedMonth.setMonth(selectedMonth.getMonth() + 1)));
					}}
					style={{ opacity: isThisMonthSelected ? 0.4 : 1 }}
				>
					<FaIcons.FaChevronRight
						size={16}
						color={COLORS.primary.lightBlue}
					/>
				</TouchableOpacity>
			</View>

			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				mt={28}
				mb={40}
				gap={48}
			>
				<ReportCard
					icon={
						<LuImport
							size={36}
							color={COLORS.primary.darkBlue}
						/>
					}
					title="Deposit from Bank"
					value={dollarFormatter.format(monthlyDeposits)}
				/>

				<ReportCard
					icon={
						<FiUsers
							size={36}
							color={COLORS.primary.darkBlue}
						/>
					}
					title="Sent to Employees"
					value={dollarFormatter.format(Math.abs(monthlyWithdrawals))}
				/>

				<ReportCard
					icon={
						<FiHeart
							size={36}
							color={COLORS.primary.darkBlue}
						/>
					}
					title="Donated to Nonprofits"
					value={dollarFormatter.format(totalDonationsThisMonth)}
				/>

				<ReportCard
					icon={
						<LuTrophy
							size={36}
							color={COLORS.primary.darkBlue}
						/>
					}
					title="Top Cause"
					value={topCauses && topCauses[0] ? allCauses?.find(
						(x) => x.id === topCauses[0].causeId
					)?.name : "TBD"}
				/>
			</Box>

			<ShadowContainer style={styles.container}>
				<PrimaryText
					fontSize={32}
					fontWeight={900}
					style={styles.title}
				>
					Recent Activity
				</PrimaryText>
				<View style={styles.filtersrow}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							flexGrow: 1
						}}
					>
						<View style={styles.inputRow}>
							<CustomInput
								placeholder="Search"
								style={{ flexGrow: 1, maxWidth: 400 }}
								onChangeText={setSearch}
								value={search}
								backgroundColor={COLORS.primary.white}
							/>

							{/* <PrimaryText
								fontSize={14}
								fontWeight={900}
								style={{
									color: COLORS.primary.darkGray,
									marginHorizontal: 4,
									marginLeft: 24
								}}
							>
								Sort By
							</PrimaryText>
							<CustomPicker
								value={selectedView}
								onChange={(value) => setSelectedView(value)}
								options={options}
								style={{ maxWidth: 170, marginLeft: 3 }}
								backgroundColor={COLORS.primary.white}
							/>

							<PrimaryButton
								title="Filter"
								variant="outlined"
								fontSize={14}
								fontWeight={700}
								style={styles.filterBtn}
								icon={<Filter />}
							/> */}
						</View>
						{/* <Button title="Download Tax Receipt" /> */}
					</View>
				</View>

				<Table
					className="table"
					style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
				>
					<TableHead>
						<TableRow>
							<TableCell>DATE</TableCell>
							<TableCell>DESCRIPTION</TableCell>
							<TableCell>TYPE</TableCell>
							<TableCell>AMOUNT</TableCell>
							<TableCell>BALANCE</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{currentTableData() &&
							currentTableData().map((el, i) => {
								return (
									<WalletActivityCard
										item={el}
										key={el?.id || i}
										onPress={() => userSelected(el)}
									/>
								);
							})}
					</TableBody>
				</Table>

				<MuiTablePagination
					count={filtered?.length}
					rowsPerPage={rowsPerPage}
					page={currentPage}
					setCurrentPage={setCurrentPage}
					onRowsPerPageChange={(e) => {
						setRowsPerPage(e.target.value);
						setCurrentPage(0);
					}}
				/>
			</ShadowContainer>

			<DepositFundsModal
				isOpen={showModal === "deposit"}
				onClose={() => setShowModal(false)}
				rayzeAccountBalance={rayzeAccountBalance}
			/>

			<EmployeeGiftModal
				isOpen={showModal === "employeeGift"}
				onClose={() => setShowModal(false)}
				onNext={(e) =>
					setShowModal(
						e === "Individual Employees"
							? "employeeSelectionModal"
							: "employeeGiftConfirmation"
					)
				}
				rayzeAccountBalance={rayzeAccountBalance}
				users={users}
				giftAmount={giftAmount}
				setGiftAmount={setGiftAmount}
				giftFrequency={giftFrequency}
				setGiftFrequency={setGiftFrequency}
				giftTotal={giftTotal}
				setGiftTotal={setGiftTotal}
				numberOfEmployees={users.filter((x) => x.active).length}
				allEmployees={allEmployees}
				setAllEmployees={setAllEmployees}
				paymentMethod={paymentMethod}
				setPaymentMethod={setPaymentMethod}
			/>

			<EmployeeGiftConfirmationModal
				isOpen={showModal === "employeeGiftConfirmation"}
				onClose={() => setShowModal(false)}
				onBack={() => setShowModal("employeeGift")}
				rayzeAccountBalance={rayzeAccountBalance}
				giftTotal={giftTotal}
				giftAmount={giftAmount}
				giftFrequency={giftFrequency}
				allEmployees={allEmployees}
				selectedEmployees={selectedEmployees}
				numberOfEmployees={users.filter((x) => x.active).length}
				paymentMethod={paymentMethod}
				setPaymentMethod={setPaymentMethod}
			/>

			<EmployeeSelectionModal
				isOpen={showModal === "employeeSelectionModal"}
				onClose={() => setShowModal(false)}
				onBack={() => setShowModal("employeeGift")}
				onConfirm={() => setShowModal("employeeGiftConfirmation")}
				rayzeAccountBalance={rayzeAccountBalance}
				users={users}
				giftAmount={giftAmount}
				setGiftAmount={setGiftAmount}
				giftFrequency={giftFrequency}
				setGiftFrequency={setGiftFrequency}
				giftTotal={giftTotal}
				setGiftTotal={setGiftTotal}
				numberOfEmployees={users.filter((x) => x.active).length}
				allEmployees={allEmployees}
				setAllEmployees={setAllEmployees}
				selectedEmployees={selectedEmployees}
				setSelectedEmployees={setSelectedEmployees}
				paymentMethod={paymentMethod}
				setPaymentMethod={setPaymentMethod}
			/>
		</View>
	);
};

const ReportCard = ({ icon, title, value }) => (
	<Box
		width={200}
		height={240}
		borderRadius={3}
		boxShadow="5px 5px 15px 0px #80808026"
		bgcolor={COLORS.primary.neutral}
		display="flex"
		flexDirection="column"
		justifyContent="center"
		px={20}
	>
		{icon}
		<PrimaryText
			fontSize={17}
			fontWeight={400}
			style={{ marginTop: 8, marginBottom: 24 }}
		>
			{title}
		</PrimaryText>
		<PrimaryText
			fontSize={32}
			fontWeight={900}
		>
			{value}
		</PrimaryText>
	</Box>
);

const MonthlyStatCard = ({ title, amount, type }) => (
	<Box
		height={106}
		px={14}
		display="flex"
		flexDirection="column"
		justifyContent="center"
		alignItems="center"
		bgcolor={COLORS.primary.neutral}
		borderRadius={3}
		boxShadow="5px 5px 15px 0px #80808026"
	>
		<PrimaryText
			fontSize={16}
			color={COLORS.primary.neutral_500}
		>
			{title}
		</PrimaryText>

		<Box
			display="flex"
			flexDirection="row"
			mt={8}
		>
			<PrimaryText
				fontSize={40}
				fontWeight={900}
				color={type === "deposit" ? COLORS.primary.green : COLORS.primary.error}
				style={{
					alignSelf: "center",
					lineHeight: 34,
					marginRight: 4
				}}
			>
				{type === "deposit" ? "+" : "-"}
			</PrimaryText>
			<PrimaryText
				fontSize={20}
				fontWeight={900}
				color={type === "deposit" ? COLORS.primary.green : COLORS.primary.error}
				style={{ alignSelf: "flex-start" }}
			>
				$
			</PrimaryText>
			<PrimaryText
				fontSize={40}
				fontWeight={900}
				color={type === "deposit" ? COLORS.primary.green : COLORS.primary.error}
				style={{ lineHeight: 34 }}
			>
				{getIntegerFromNumber(amount)}
			</PrimaryText>
			<PrimaryText
				fontSize={20}
				fontWeight={900}
				color={type === "deposit" ? COLORS.primary.green : COLORS.primary.error}
				style={{ alignSelf: "flex-end" }}
			>
				{getDecimalFromNumber(amount) + "/mo"}
			</PrimaryText>
		</Box>
	</Box>
);

const styles = StyleSheet.create({
	activity: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.primary.white,
		position: "absolute",
		top: 0,
		bottom: 0,
		zIndex: 999
	},
	container: {
		// flex: 1,
		height: "100%",
		width: "100%",
		marginBottom: 30
	},
	headerContainer: {
		flexDirection: "row"
	},
	infoContainer: {
		backgroundColor: COLORS.primary.white,
		marginHorizontal: 12,
		borderRadius: 12,
		paddingHorizontal: 20,
		paddingVertical: 12,
		alignItems: "center",
		justifyContent: "center"
	},
	info: {
		alignItems: "center",
		justifyContent: "center",
		marginHorizontal: 24
	},
	title: {
		marginBottom: 12
	},
	URL: {
		fontSize: 20,
		fontWeight: 800,
		color: COLORS.primary.lightBlue
	},
	inputRow: {
		flexDirection: "row",
		alignItems: "center",
		flexGrow: 1
	},
	filtersrow: {
		zIndex: 999,
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
		marginVertical: 12
	},
	filterBtn: {
		height: 30,
		marginBottom: 0,
		gap: 8,
		width: 116,
		paddingHorizontal: 0,
		marginLeft: 20
	}
});

const DUMMY_DATA = [
	{
		name: "From Bank Acct xxxx1234",
		type: "Deposit",
		amount: 150.0,
		date: "08/24/24",
		balance: 580
	},
	{
		name: "To Bob Johnson from Rayze Account",
		type: "Withdrawal",
		amount: -75.0,
		date: "08/24/24",
		balance: 430
	},
	{
		name: "From Bank Acct xxxx1234",
		type: "Deposit",
		amount: 200.0,
		date: "08/23/24",
		balance: 505
	},
	{
		name: "To Diana Prince From Rayze Account",
		type: "Withdrawal",
		amount: -300.0,
		date: "08/22/24",
		balance: 305
	},
	{
		name: "From Bank Acct xxxx1234",
		type: "Deposit",
		amount: 500.0,
		date: "08/22/24",
		balance: 605
	},
	{
		name: "To Fiona Gallagher from Rayze Account",
		type: "Withdrawal",
		amount: -105,
		date: "08/21/24",
		balance: 75
	},
	{
		name: "From Bank Acct xxxx1234",
		type: "Deposit",
		amount: 100.0,
		date: "08/21/24",
		balance: 180
	},
	{
		name: "From Bank Acct xxxx1234",
		type: "Deposit",
		amount: 80.0,
		date: "08/19/24",
		balance: 80
	}
];
