import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { getUser } from "services/user-service";
import { getUsersName } from "utils/helpers";
import { StyleSheet, View } from "react-native-web";
import { useOrganization } from "context/OrganizationContext";

export const BusinessTopDonorCard = ({ item, index }) => {
  const { userId } = item;

  const { members } = useOrganization();

  const [user, setUser] = useState({});

  useEffect(() => {
    getUser(userId, setUser);
  }, [userId]);

  if (!user) return null

  return (
    <View style={styles.card}>
      <PrimaryText fontSize={20} fontWeight={400} style={{ width: 24 }}>
        {index + 1}.
      </PrimaryText>
      <View style={styles.pic}>
        <ProfilePic uri={user?.profilePic} size={36} />
      </View>
      <View style={{flex: 1}}>
        <PrimaryText
          fontSize={14}
          fontWeight={700}
          numberOfLines={1}
          style={{ flex: 1 }}
        >
          {user?.firstName ? getUsersName(user) : "t"}
        </PrimaryText>
        {/* <PrimaryText
          fontSize={12}
          fontWeight={200}
          numberOfLines={1}
          style={{ flex: 1 }}
        >
          {members?.find(x => x.id === userId)?.jobTitle}
        </PrimaryText> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  pic: {
    marginRight: 8,
    width: 42,
  },
});
