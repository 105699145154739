import { StyleSheet, ActivityIndicator } from "react-native";
import React from "react";
import { useLoading } from "context/LoadingContext";
import { COLORS } from "constants/Colors";

export default function Loading() {
  const { loading } = useLoading();

  if (!loading) return null;

  return (
    <ActivityIndicator
      animating={loading}
      size={"large"}
      style={[StyleSheet.absoluteFill, { zIndex: 100 }]}
      color={COLORS.primary.blue}
    />
  );
}
