import { Box } from "@mui/material";
import MuiButton from "components/Common/MuiButton";
import React from "react";

export default function SaveAndCancelBtns({
	onSave,
	onCancel,
	loading,
	saveProps = {},
	cancelProps = {},
	boxProps = {}
}) {
	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			mt={12}
			gap={20}
			{...boxProps}
		>
			<MuiButton
				title="Save"
				onClick={onSave}
				variant="contained"
				sx={{ pY: 6, fontSize: 14, fontWeight: 700 }}
				loading={loading}
				{...saveProps}
			/>
			<MuiButton
				onClick={onCancel}
				title="Cancel"
				variant="outlined"
				color="secondary"
				sx={{ pY: 6, fontSize: 14, fontWeight: 700 }}
				disabled={loading}
				{...cancelProps}
			/>
		</Box>
	);
}
