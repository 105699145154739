import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";

export const DonationCard = ({ item, onPress }) => {
  const { userId, amount, date, recurring = false, fee = 0, anonymous = false } = item;

  const [user, setUser] = useState(item?.user);

  useEffect(() => {
    if (!user) {
      // console.log('no user')
      userId && getUser(userId, setUser);
    }
  }, [userId]);

  // console.log(item?.user)

  return (
    <>
      {(!!userId && !user) ? null : (
        <TableRow hover onClick={anonymous ? () => {} : onPress}>
          <TableCell width={36}>
            <ProfilePic uri={user?.profilePic} userId={userId} size={36} />
          </TableCell>
          <TableCell style={{ textAlign: "left" }}>
            <PrimaryText fontSize={18} fontWeight={900}>
              {user ? getUsersName(user) : (anonymous ? "Anonymous" : item?.name)}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {anonymous ? "" : (item?.email || user?.email)}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {recurring ? "recurring" : "one-time"}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {dollarFormatter.format(amount-fee)}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {date.toDate().toDateString()}
            </PrimaryText>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
