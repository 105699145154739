import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { COLORS } from "constants/Colors";
import { Dimensions, ScrollView } from "react-native-web";
import { ModalHeader } from "./ModalHeader";

const { height } = Dimensions.get("window");

export function FullModal({
	children,
	setShowModal,
	title,
	minHeight,
	style = {},
	containerStyle = {},
	subtitle = null
}) {
	const [windowSize, setWindowSize] = useState([
		window.innerWidth,
		window.innerHeight
	]);

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize([window.innerWidth, window.innerHeight]);
		};

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	return (
		<View
			style={[
				styles.container,
				{ width: windowSize[0], height: windowSize[1] },
				containerStyle
			]}
		>
			<View style={[styles.body, style]}>
				<ModalHeader
					title={title}
					onClose={() => setShowModal(false)}
					subtitle={subtitle}
				/>
				<ScrollView
					style={{
						flex: 1,
						minWidth: 500,
						minHeight,
						backgroundColor: COLORS.primary.white
					}}
				>
					{children}
				</ScrollView>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		position: "fixed",
		zIndex: 999,
		flex: 1,
		// width,
		height: height,
		backgroundColor: "rgba(0,0,0,0.7)",
		alignItems: "center",
		justifyContent: "center",
		left: 0,
		top: 0
	},
	body: {
		maxHeight: height - 200,
		borderRadius: 12,
		backgroundColor: COLORS.primary.white,
		paddingVertical: 22,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		alignSelf: "center"
	}
});
