import * as React from "react";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";

const VisuallyHiddenInput = styled("input")({
	height: 1,
	width: 1,
	overflow: "hidden"
});

export default function MuiButton({
	title,
	sx,
	onPress,
	variant,
	color,
	type,
	onChange,
	accept,
	loading,
	disabled,
	...props
}) {
	return (
		<Button
			onClick={onPress}
			disableElevation
			component="label"
			disabled={loading || disabled}
			sx={{
				borderRadius: "20px",
				textTransform: "none",
				fontWeight: 900,
				fontSize: 16,
				fontFamily: "Montserrat",
				borderWidth: 1,
				paddingY: 3,
				paddingX: 20,
				"&.MuiButton-contained.Mui-disabled": {
					opacity: 0.5,
					bgcolor: `${[color || "primary"]}.main`,
					color: `${[color || "primary"]}.contrastText`
				},
				...sx
			}}
			{...{ variant, color, ...props }}
		>
			{loading ? "Loading..." : title}

			{type === "file" && (
				<VisuallyHiddenInput
					type="file"
					onChange={(e) => {
						const reader = new FileReader();
						reader.addEventListener("load", () => {
							const file = e.target.files[0];
							file.url = reader.result?.toString();
							onChange(file);
						});
						reader.readAsDataURL(e.target.files[0]);
					}}
					accept={accept || ".jpg, .png"}
				/>
			)}
		</Button>
	);
}
