import React from "react";
import Sidebar from "components/Sidebar/Sidebar";
import MainNavigator from "./MainNavigator";
import { DonationsProvider } from "context/DonationsContext";
import { EventsProvider } from "context/EventContext";
import { UserProvider } from "context/UserContext";
import { useOrganization } from "context/OrganizationContext";
import BusinessNavigator from "./BusinessNavigator";
import { BusinessDonationsProvider } from "context/Business/BusinessDonationsContext";
import { BusinessCampaignsProvider } from "context/Business/BusinessCampaignsContext";
import { BusinessNonprofitsProvider } from "context/Business/BusinessNonprofitsContext";
import { BusinessProvider } from "context/Business/BusinessContext";
import { FundraiserProvider } from "context/FundraiserContext";
import { CustomLinkProvider } from "context/CustomLinksContext";

export default function OrganizationNavigator() {
  const { selectedOrg } = useOrganization();

  return selectedOrg?.type === "nonprofit" || selectedOrg?.type === "community" ? (
    <DonationsProvider>
      <UserProvider>
        <EventsProvider>
          <FundraiserProvider>
            <CustomLinkProvider>
              {/* <Router> */}
              <Sidebar />
              <MainNavigator />
              {/* </Router> */}
            </CustomLinkProvider>
          </FundraiserProvider>
        </EventsProvider>
      </UserProvider>
    </DonationsProvider >
  ) : (
    <BusinessProvider>
      <BusinessDonationsProvider>
        <UserProvider>
          <BusinessCampaignsProvider>
            <BusinessNonprofitsProvider>
              {/* <Router> */}
              <Sidebar />
              {/* <SidebarTips /> */}
              <BusinessNavigator />

              {/* <Welcome /> */}
              {/* </Router> */}
            </BusinessNonprofitsProvider>
          </BusinessCampaignsProvider>
        </UserProvider>
      </BusinessDonationsProvider>
    </BusinessProvider>
  );
}
