import React from "react";
import { Stack, TableCell, TableRow } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { TouchableOpacity } from "react-native-web";
import { ProfilePic } from "components/Pics/ProfilePic";
import moment from "moment";
import { dollarFormatter } from "utils/helpers";

const colors = {
	Pending: COLORS.primary.error,
	Processing: COLORS.primary.accent_500,
	Completed: COLORS.primary.success_500
};

export default function WalletActivityCard(props) {
	const { item } = props;
	const { description, amount, type, date, balance = 0 } = item;

	const color = amount > 0 ? COLORS.primary.success_500 : COLORS.primary.error;

	return (
		<TableRow>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{moment(date.toDate()).format("MM/DD/YYYY")}
					{/* {date} */}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{description}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{/* {moment(latestDonation.date.toDate()).format("MM/DD/YYYY")} */}
					{type}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
					color={color}
				>
					{dollarFormatter.format(amount)}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}

				>
					{dollarFormatter.format(balance)}
				</PrimaryText>
			</TableCell>
		</TableRow>
	);
}
