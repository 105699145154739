import { Box, ButtonBase } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";

export default function MuiTab({ tabs, active, onClick }) {
	return (
		<>
			{tabs.map((tab, index) => (
				<ButtonBase
					key={index}
					sx={{
						ml: 40,
						boxShadow:
							active === tab ? "5px 5px 15px rgba(128, 128, 128, 0.15)" : "",
						borderRadius: "10px 10px 0 0",
						overflow: "hidden"
					}}
					onClick={() => onClick(tab)}
				>
					<Box
						pt={8}
						pb={10}
						px={25}
						bgcolor={
							active === tab
								? COLORS.primary.neutral
								: COLORS.primary.neutral_200
						}
					>
						<PrimaryText
							fontSize={24}
							fontWeight={900}
						>
							{tab}
						</PrimaryText>
					</Box>
				</ButtonBase>
			))}
		</>
	);
}
