import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
  Svg,
} from "@react-pdf/renderer";
import { COLORS } from "constants/Colors";
import { ImageBackground } from "react-native-web";

Font.register({
  family: "Montserrat",
  fonts: [
    { src: require("assets/fonts/Montserrat-Regular.ttf"), fontWeight: 400 },
    { src: require("assets/fonts/Montserrat-Bold.ttf"), fontWeight: 700 },
    { src: require("assets/fonts/Montserrat-ExtraBold.ttf"), fontWeight: 800 },
  ],
});
Font.registerHyphenationCallback((word) => [word]);

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: COLORS.primary.white,
    padding: 30,
    paddingVertical: 12,
    justifyContent: "space-between",
  },
  section: {
    marginVertical: 10,
    // padding: 10,
    // flexGrow: 1,
    textAlign: "center",
    alignItems: "center",
  },
  sectionLeft: {
    marginVertical: 10,
    // margin: 10,
    // padding: 10,
    // flexGrow: 1,
    textAlign: "left",
    alignItems: "flex-start",
  },
  name: {
    fontSize: 32,
    fontWeight: 800,
    fontFamily: "Montserrat",
  },
  website: {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  about: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  donate: {
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "Montserrat",
  },

  aboutBg: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "Montserrat",
    color: COLORS.primary.white,
  },

  nameGradient: {
    fontSize: 48,
    fontWeight: 800,
    fontFamily: "Montserrat",
    color: COLORS.primary.white,
  },
  websiteGradient: {
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "Montserrat",
    color: COLORS.primary.white,
  },
});

// Create Document Component
export const Flyer = (props) => {
  const { selectedFlyer, selectedOrg = {}, SvgDL } = props;
  if (!SvgDL) return null;

  const PDF = () => {
    switch (selectedFlyer) {
      case "simple":
        return (
          <Document>
            <Page size="A4" style={[styles.page, { paddingTop: 0 }]}>
              <View style={styles.section}>
                <Image
                  source={{ uri: selectedOrg?.coverPhoto }}
                  style={{
                    minWidth: 300,
                    height: undefined,
                    marginTop: 12,
                    aspectRatio: 16 / 9,
                    borderRadius: 4,
                  }}
                />
              </View>
              <View style={styles.section}>
                <Text
                  style={[
                    styles.name,
                    selectedOrg?.name?.length > 20 && { marginBottom: 12 },
                  ]}
                >
                  {selectedOrg?.name}
                </Text>
                {selectedOrg?.website && (
                  <Text style={styles.website}>{selectedOrg?.website}</Text>
                )}
              </View>

              <View style={styles.section}>
                <Text style={styles.about}>About</Text>
                <Text style={styles.about}>{selectedOrg?.about}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.donate}>Donate</Text>
                  <Svg 
                  width={175}
                   height={175} 
                   viewBox={"0 0 33 33"}
                    style={{marginTop: 12}}
                   >
                    {SvgDL}
                  </Svg>
                  {/* <Image
                    source={require("assets/icon.png")}
                    style={{
                      width: 75,
                      height: 75,
                      marginTop: 0,
                      position: "absolute",
                      top: 50,
                      right: 50,
                    }}
                  /> */}
              </View>
              <View style={styles.section}>
                <Text style={styles.about}>In partnership with</Text>
                <Image
                  source={require("assets/rayzeLogo.png")}
                  style={{ width: 75, height: undefined, marginTop: 12 }}
                />
              </View>
            </Page>
          </Document>
        );
      case "bg":
        return (
          <Document>
            <Page
              size="A4"
              style={[
                styles.page,
                { padding: 70, paddingVertical: 34, paddingTop: 36 },
              ]}
            >
              <Image
                source={require("assets/templates/flyerBg.png")}
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
              />
              <View
                style={[
                  styles.sectionLeft,
                  { marginBottom: 0, marginVertical: 0 },
                ]}
              >
                <Image
                  source={{ uri: selectedOrg?.coverPhoto }}
                  style={{
                    // alignSelf: 'center',
                    minWidth: 300,
                    // maxHeight: '80%',
                    marginTop: 12,
                    aspectRatio: 16 / 9,
                    borderRadius: 4,
                  }}
                />
              </View>
              <View style={styles.sectionLeft}>
                <Text style={[styles.name, { color: COLORS.primary.white }]}>
                  {selectedOrg?.name}
                </Text>
                {selectedOrg?.website && (
                  <Text
                    style={[
                      styles.website,
                      { color: COLORS.primary.white, fontWeight: 700 },
                    ]}
                  >
                    {selectedOrg?.website}
                  </Text>
                )}
              </View>

              <View style={styles.sectionLeft}>
                <Text style={styles.aboutBg}>About</Text>
                <Text style={styles.aboutBg}>{selectedOrg?.about}</Text>
              </View>
              <View style={styles.sectionLeft}>
                <Text style={[styles.donate, { color: COLORS.primary.white }]}>
                  Donate
                </Text>
                  <View
                    style={{
                      backgroundColor: COLORS.primary.white,
                      marginTop: 12,
                      padding: 12,
                    }}
                  >
                    <Svg width={160} height={160} viewBox={"0 0 33 33"}>
                      {SvgDL}
                    </Svg>
                  </View>
                  {/* <Image
                    source={require("assets/icon.png")}
                    style={{
                      width: 54,
                      height: 54,
                      marginTop: 0,
                      position: "absolute",
                      top: 65,
                      right: 65,
                    }}
                  /> */}
              </View>
              <View style={styles.section}>
                <Text
                  style={[
                    styles.about,
                    { color: COLORS.primary.white, fontWeight: 600 },
                  ]}
                >
                  In partnership with
                </Text>
                <Image
                  source={require("assets/rayzeLogoLight.png")}
                  style={{ width: 69, height: undefined, marginTop: 12 }}
                />
              </View>
            </Page>
          </Document>
        );
      case "gradient":
        return (
          <Document>
            <Page size="A4" style={styles.page}>
              <Image
                source={require("assets/templates/gradientBg.png")}
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
              />
              <View style={[styles.section, { marginTop: 0 }]}>
                <Image
                  source={{ uri: selectedOrg?.coverPhoto }}
                  style={{
                    minWidth: 300,
                    marginHorizontal: 24,
                    height: undefined,
                    marginTop: 12,
                    aspectRatio: 16 / 9,
                    borderRadius: 4,
                  }}
                />
              </View>
              <View style={styles.section}>
                <Text
                  style={[
                    styles.nameGradient,
                    selectedOrg?.name?.length > 20 && {
                      fontSize: 36,
                      marginBottom: 12,
                    },
                  ]}
                >
                  {selectedOrg?.name}
                </Text>
                {selectedOrg?.website && (
                  <Text style={styles.websiteGradient}>
                    {selectedOrg?.website}
                  </Text>
                )}
              </View>
              <View
                style={{
                  width: "70%",
                  height: 4,
                  backgroundColor: COLORS.primary.white,
                  alignSelf: "center",
                }}
              />

              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={[styles.sectionLeft, { flex: 3, marginRight: 24 }]}
                >
                  <Text style={[styles.websiteGradient, { marginBottom: 24 }]}>
                    About
                  </Text>
                  <Text style={[styles.aboutBg, { flex: 1 }]}>
                    {selectedOrg?.about}
                  </Text>
                </View>
                <View style={[styles.section, { flex: 2 }]}>
                  <Text style={styles.websiteGradient}>Donate</Text>
                  <View
                    style={{
                      backgroundColor: COLORS.primary.white,
                      marginTop: 12,
                      padding: 12,
                    }}
                  >
                    <Svg width={160} height={160} viewBox={"0 0 33 33"}>
                      {SvgDL}
                    </Svg>
                  </View>
                </View>
              </View>
              <View style={styles.sectionLeft}>
                <Text
                  style={[
                    styles.aboutBg,
                    {
                      color: COLORS.primary.white,
                      fontSize: 16,
                      fontWeight: 600,
                    },
                  ]}
                >
                  In partnership with
                </Text>
                <Image
                  source={require("assets/rayzeLogoHorizontalLight.png")}
                  style={{ width: 202, height: undefined, marginVertical: 12 }}
                />
              </View>
            </Page>
          </Document>
        );
      default:
        return (
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text>Section #3</Text>
              </View>
              <View style={styles.section}>
                <Text>Section #4</Text>
              </View>
            </Page>
          </Document>
        );
    }
  };

  return (
    // <PDFViewer style={{ width: 500, height: 800 }}>
    <PDF />
    // </PDFViewer>
  );
};
