import { StyleSheet, View } from "react-native";
import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { auth } from "utils/firebase";
import { useLoading } from "context/LoadingContext";

export default function VerifyPersonalAccount() {
	const { state } = useLocation();
	const navigate = useNavigate();
	const { setLoading } = useLoading();

	// console.log("VerifyPersonalAccount", state);

	const onSignIn = () => {
		if (!state.phone) {
			alert("Please enter a valid phone number.");
			navigate(Links.CreatePersonalAccount);
		}

		setLoading(true);

		const recaptcha = new firebase.auth.RecaptchaVerifier(
			"recaptcha__container",
			{
				size: "invisible"
			}
		);

		auth
			.signInWithPhoneNumber(state.countryCode + state.phone, recaptcha)
			.then((confirmationResult) => {
				window.confirmationResult = confirmationResult;
				navigate(Links.EnterPasscode, { state });
			})
			.catch((err) => {
				alert(err.message);
			})
			.finally((_) => {
				setLoading(false);
				recaptcha.clear();
			});
	};

	return (
		<View style={styles.container}>
			<img
				src={require("assets/rayze.png")}
				width={77.64}
			/>

			<PrimaryText
				fontSize={54}
				fontWeight="900"
			>
				Create Personal Account
			</PrimaryText>

			<PrimaryText
				fontSize={32}
				style={{ color: COLORS.primary.gray, marginTop: 14 }}
			>
				Confirm Your Account
			</PrimaryText>

			<div id="recaptcha__container" />

			{/* <PrimaryButton
        to={Links.EnterPasscode}
        title="Send Passcode By Email"
        style={{ marginTop: 115, width: 389 }}
      /> */}
			<PrimaryButton
				onPress={onSignIn}
				title="Send Passcode By Phone Number"
				style={{ marginTop: 33 }}
				variant="outlined"
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center"
	}
});
