import { Box } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { creditCardTypes } from "constants/Data";
import React from "react";
import { FaLandmark } from "react-icons/fa";
import { PiWarning } from "react-icons/pi";
import { TouchableOpacity } from "react-native-web";



export default function PaymentMethodPickerCard({
	onChange,
	insufficient,
	selectable,
	active,
	item
}) {
	const { card = null, us_bank_account = null } = item;
	const account = card ? card : us_bank_account;
	const { brand = "Bank Account", last4 } = account;
	let desc = "xxxx-xxxx-xxxx-" + last4;

	//find credit card with regex
	const cardType = creditCardTypes.find((x) => x.id === brand);
	const cardIcon = cardType ? cardType.icon : <FaLandmark size={20} />;


	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				gap={20}
				mt={11}
				mr={20}
			>
				<Box
					display="flex"
					flexDirection="row"
					bgcolor={
						insufficient
							? COLORS.primary.error_50
							: active
							? COLORS.primary.lightestBlue
							: COLORS.primary.white
					}
					border={`1px solid ${
						insufficient
							? COLORS.primary.error
							: active
							? COLORS.primary.lightBlue
							: COLORS.primary.neutral_400
					}`}
					borderRadius={3}
					width={308}
					boxSizing="border-box"
					py={active ? 20 : 9}
					pr={20}
					pl={24}
					justifyContent="space-between"
					sx={{ cursor: !active ? "pointer" : "default" }}
					onClick={() => !active && onChange()}
				>
					<Box
						display="flex"
						alignItems="center"
					>
						{cardIcon}

						<PrimaryText
							fontSize={14}
							fontWeight={700}
							style={{ marginLeft: 12, textTransform: "capitalize" }}
						>
							{brand}
						</PrimaryText>
					</Box>

					<PrimaryText
						fontSize={12}
						style={{ marginTop: 6 }}
					>
						{desc}
					</PrimaryText>
				</Box>

				{!selectable && (
					<TouchableOpacity onPress={onChange}>
						<PrimaryText
							fontSize={16}
							fontWeight={900}
							color={COLORS.primary.lightBlue}
						>
							Change
						</PrimaryText>
					</TouchableOpacity>
				)}
			</Box>

			{insufficient && (
				<Box
					display="flex"
					alignItems="center"
					gap={6}
					ml={6}
					mt={4}
				>
					<PiWarning
						color={COLORS.primary.error}
						size={20}
					/>
					<PrimaryText
						fontSize={12}
						color={COLORS.primary.error}
						style={{ marginBottom: -4, letterSpacing: 0.8 }}
					>
						Insufficient funds, choose a different account
					</PrimaryText>
				</Box>
			)}
		</>
	);
}
