import { StyleSheet, View, Linking } from "react-native";
import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import TextButton from "components/Buttons/TextButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "context/LoadingContext";
import { functions } from "utils/firebase";
import { TouchableOpacity } from "react-native-web";
import { useAuth } from "context/AuthContext";

const { gray, black, stripe } = COLORS.primary;

export default function AccountCreatedStep2() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setLoading } = useLoading();
  const { setOnboarding } = useAuth();

  const selectedOrg = { ...state };

  // console.log('selectedOrg', selectedOrg)

  const handleSetUpStripe = () => {
    if (!selectedOrg) return;

    setLoading(true);
    let data = { ...selectedOrg };
    data.redirect = "https://dev.org.rayzeapp.com/donations";
    const stringifiedOrg = JSON.stringify(data);

    functions
      .httpsCallable("getAccountLink")(stringifiedOrg)
      .then((response) => {
        // console.log("test", response)
        const { url: uri, statusCode } = response.data;
        if (uri && !statusCode) {
          window.location.href = uri;
        } else {
          console.log("Error getting account setup link: ", response);
        }
      })
      .catch((err) => {
        console.log("handleSetUpStripe Error: ", err);
        setLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      <img src={require("assets/rayze.png")} width={77.64} />

      <PrimaryText
        fontSize={54}
        fontWeight="900"
        style={{ textAlign: "center", marginTop: 50 }}
      >
        Account Created!!!
      </PrimaryText>

      <PrimaryText fontSize={32} style={{ color: gray, marginTop: 14 }}>
        You’re account is ready to go
      </PrimaryText>

      <View style={styles.line} />

      <PrimaryText
        fontSize={24}
        style={{
          color: gray,
          marginTop: 24,
          textAlign: "center",
        }}
      >
        In order to accept direct donations on Rayze there are two more quick
        steps.
        <br />
        (You can always do these later)
      </PrimaryText>

      {selectedOrg?.type !== "business" &&
        <PrimaryText
          fontSize={32}
          fontWeight={900}
          style={{ color: black, marginTop: 29 }}
        >
          Step 2: Connect Stripe Account
        </PrimaryText>
      }

      <TouchableOpacity style={styles.verifyView} onPress={handleSetUpStripe}>
        <View>
          <img src={require("assets/misc/stripe.png")} width={91} />

          <PrimaryText fontSize={12} style={{ color: stripe }}>
            Create/Connect Stripe Account
          </PrimaryText>
        </View>
        <img src={require("assets/misc/stripeNext.png")} width={41} />
      </TouchableOpacity>

      <PrimaryText
        fontSize={17}
        style={{ color: gray, marginTop: 80, textAlign: "center" }}
      >
        Stripe is our payment method so we can get
        <br />
        money to your organization as soon as it’s donated
        <br />
        through the app.
      </PrimaryText>

      <View style={styles.buttonRow}>
        {selectedOrg?.type !== "business" &&
          <PrimaryButton
            onPress={() => navigate(-1)}
            title="Back"
            variant="outlined"
          />
        }

        <PrimaryButton
          onPress={() => setOnboarding(false)}
          title="Skip"
          style={{ marginLeft: 48, backgroundColor: COLORS.primary.error }}
        />
      </View>

      <PrimaryText fontSize={17} style={{ color: black, marginTop: 48 }}>
        Questions? Contact us at{" "}
        <TextButton
          text="support@rayzeapp.com"
          onPress={() =>
            Linking.openURL("mailto:support@rayzeapp.com", "_blank").catch(
              (err) => alert(err.message)
            )
          }
        />
      </PrimaryText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: 500,
    height: 2,
    backgroundColor: gray,
    marginTop: 35,
  },
  verifyView: {
    width: 342,
    height: 96,
    borderRadius: 10,
    backgroundColor: COLORS.primary.white,
    marginTop: 50,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 3,
    borderColor: stripe,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 30,
  },
  buttonRow: { flexDirection: "row", alignItems: "center", marginTop: 68 },
});
