import React from "react";
import { StyleSheet } from "react-native";
import { COLORS } from "constants/Colors";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomSwitch = (props) => {
  const { value, onValueChange } = props;

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 34,
    height: 18,
    padding: 0,
    display: 'flex',

    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: COLORS.primary.offWhite,
          border: `1px solid ${COLORS.primary.lightGray}`
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: COLORS.primary.lightBlue,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 14,
      height: 14,
      borderRadius: 7,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
      backgroundColor: COLORS.primary.error,
    },
    '& .MuiSwitch-track': {
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor: COLORS.primary.offWhite,
      border: `1px solid ${COLORS.primary.lightGray}`,
      boxSizing: 'border-box',
    },
  }));

  return (
    <IOSSwitch
      sx={{ m: 1 }}
      defaultChecked
      checked={value}
      onChange={(event) => onValueChange(event.target.checked)}
    />
  );
};

const styles = () => StyleSheet.create({});
