import React from "react";
import { Stack, TableCell, TableRow } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { useNavigate } from "react-router-dom";
import { TouchableOpacity } from "react-native-web";
import { Links } from "constants/Links";
import { ProfilePic } from "components/Pics/ProfilePic";
import moment from "moment";
import { dollarFormatter } from "utils/helpers";

export default function ChampionCard(props) {
	const navigate = useNavigate();
	const { item } = props;
	const {name, email, donations, amount, userId} = item;
	//get latest donation by date
	const latestDonation = donations.sort((a, b) => new Date(b.date) - new Date(a.date))[0];

	return (
		<TableRow>
			<TableCell>
				<TouchableOpacity
				// onPress={() => navigate(Links.CustomLinkDetails)}
				>
					<Stack
						direction="row"
						alignItems="center"
					>
						<ProfilePic size={36} userId={userId} />
						<PrimaryText
							fontSize={20}
							fontWeight={900}
							style={{ marginLeft: 14 }}
						>
							{name}
						</PrimaryText>
					</Stack>
				</TouchableOpacity>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
					color={COLORS.primary.lightBlue}
					style={{ textDecoration: "underline" }}
				>
					{email}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{moment(latestDonation.date.toDate()).format("MM/DD/YYYY")}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{donations?.length}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{dollarFormatter.format(amount)}
				</PrimaryText>
			</TableCell>
		</TableRow>
	);
}
