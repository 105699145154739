import { storage } from "utils/firebase"

export const uploadOrgDoc = (orgId, file, onSuccess) => {
  // const orgRef = firestore.collection("organizations").doc(orgId)
  let promises = []
  let ref = storage.ref(`orgDocuments/${orgId}/${file?.name}`)
  promises.push(
    ref
      .put(file, { cacheControl: "max-age=31536000" })
      .catch((err) => {
        console.log("err", err)
      })
  )
  Promise.all(promises).then(() => {
    onSuccess()
  })
}

export const getOrgDocuments = async (orgId, setFiles) => {
  try {
    const storageRef = storage.ref(`orgDocuments/${orgId}`)
    const documentsList = await storageRef.listAll()
    const documents = []
    documentsList.items.forEach((itemRef) => {
      documents.push(itemRef)
    })

    let promises = documents.map(async (item) => {
      // const doc = await item.getDownloadURL()
      const docMetaData = await item.getMetadata()
      return docMetaData
    })
    Promise.all(promises).then((files) => {
      setFiles(files)
    })
  } catch (err) {
    console.log("Error getting org docs: ", err)
  }
}

export const deleteOrgDoc = (orgId, file, onSuccess) => {
  let ref = storage.ref(`orgDocuments/${orgId}/${file?.name}`)
  ref
    .delete()
    .then(() => {
      onSuccess()
    })
    .catch((e) => {
      console.log("error deleting org doc", e)
    })
}
