import { firestore, storage, auth } from "utils/firebase";

export const listenForBusinessCampaigns = (
  org,
  setOurCampaigns
) => {
  return firestore
    .collection("campaigns")
    .where('orgId', '==', org.id)
    .onSnapshot(
      (querySnapshot) => {
        let temp = []

        querySnapshot.forEach((doc) => {
          temp.push({
            id: doc.id,
            ...doc.data(),
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate.toDate(),
          })
        })
        // console.log('temp', temp)
        //sort by start date descending
        temp.sort((a, b) => {
          if (a.startDate < b.startDate) {
            return -1
          } else if (a.startDate > b.startDate) {
            return 1
          } else {
            return 0
          }
        })
        setOurCampaigns(temp)
        
      },
      (e) => {
        console.log("listenForBusinessCampaigns err", e)
      }
    )
}

export const createCampaign = (data, coverPhotos, onSuccess) => {
  delete data.coverPhoto;
  data.createdBy = auth.currentUser.uid;
  data.lowercaseName = data.name
  .toLowerCase()
  .replace(/[^a-zA-Z ]/g, "");
  firestore
    .collection("campaigns")
    .add(data)
    .then((doc) => {
      data.id = doc.id;

      coverPhotos.map((val, index) => {
        let ref = storage.ref(`campaignCoverPhotos/${doc.id}/${index}.jpg`);
        ref
          .put(val.blob, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            console.log("updateEvent uploaded cover photo!", result);
            const downloadURL = await ref.getDownloadURL();
            firestore
              .collection("campaigns")
              .doc(doc.id)
              .update({ coverPhoto: downloadURL })
              .then(() => {
                data.coverPhoto = downloadURL;
                onSuccess(data);
              });
          })
          .catch((err) => {
            onSuccess(data);
            console.log("err", err);
          });
      });
    })
    .catch((err) => {
      console.log("err", err);
      window.alert("Error", "An error has occured please try later.", [
        { text: "Ok", onPress: onSuccess },
      ]);
      onSuccess();
    });
};

export const updateCampaign = (campaignId, data, coverPhotos, onSuccess) => {
  // console.log("update event", campaignId, data)
  data.lowercaseName = data.name
  .toLowerCase()
  .replace(/[^a-zA-Z ]/g, "");
  data.updatedAt = new Date();
  firestore
    .collection("campaigns")
    .doc(campaignId)
    .update(data)
    .then(() => {
      coverPhotos.map((val, index) => {
        let ref = storage.ref(`campaignCoverPhotos/${campaignId}/${index}.jpg`);
        ref
          .put(val.blob, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            // console.log('updateEvent uploaded cover photo!', result)
            const downloadURL = await ref.getDownloadURL();
            firestore
              .collection("campaigns")
              .doc(campaignId)
              .update({ coverPhoto: downloadURL })
              .then(() => {
                data.coverPhoto = downloadURL;
                onSuccess(data);
                // Alert.alert("Success", "Update Successful", [
                //   { text: "Ok", onPress: () => onSuccess(data) },
                // ])
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
      if (coverPhotos?.length === 0) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      console.log("err", err);
      // Alert.alert("Error", "An error has occured please try later.", [
      //   { text: "Ok", onPress: () => onSuccess(data) },
      // ])
    });
};

export const archiveCampaign = (campaignId, archive, onSuccess) => {
  firestore
    .collection("campaigns")
    .doc(campaignId)
    .update({ archived: archive })
    .then(() => {
      onSuccess();
    })
    .catch((err) => {
      console.log("err", err);
      window.alert("Error", "An error has occured please try later.", [
        { text: "Ok", onPress: onSuccess },
      ]);
      onSuccess();
    });
}
