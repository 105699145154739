import { Checkbox } from "@mui/material";
import React from "react";
import {
	MdOutlineIndeterminateCheckBox,
	MdOutlineCheckBox,
	MdOutlineCheckBoxOutlineBlank
} from "react-icons/md";

export default function MuiCheckbox(props) {
	const { indeterminate } = props;
	return (
		<Checkbox
			color="darkBlue"
			indeterminate={indeterminate}
			indeterminateIcon={<MdOutlineIndeterminateCheckBox size={23} />}
			checkedIcon={<MdOutlineCheckBox size={23} />}
			icon={<MdOutlineCheckBoxOutlineBlank size={23} />}
			sx={{
				"&.MuiCheckbox-root": {
					color: "darkBlue.main"
				},
				"&.Mui-disabled": {
					color: "neutral.400"
				}
			}}
			{...props}
		/>
	);
}
