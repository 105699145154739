import React, { useRef, useState } from "react";
import { StyleSheet, View, TouchableOpacity, Image } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { Button } from "components/Common/Button";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import { Path, pdf } from "@react-pdf/renderer";
import { Flyer } from "components/Flyers/Flyers";
import { EventFlyers } from "./EventFlyers";
import { Box, Snackbar } from "@mui/material";
import MuiButton from "components/Common/MuiButton";
import { Edit, Files, QRCode } from "assets/svgs";
import { CustomInput } from "components/Inputs/CustomInput";
import { FiAlertCircle } from "react-icons/fi";
import {
	checkCustomEventUrls,
	saveCustomEventUrl
} from "services/events-service";
import MuiSnackbar from "components/Common/MuiSnackbar";

let customUrlTimer;

export function FlyersPicker(props) {
	const { selectedOrg, selectedEvent = null } = props;

	const url = `https://dev.event.rayzeapp.com/${selectedEvent?.id}`;

	const baseUrl = "https://dev.event.rayzeapp.com/";

	const [selectedFlyer, setSelectedFlyer] = useState("gradient");
	const [isEditingUrl, setIsEditingUrl] = useState(false);
	const [customUrl, setCustomUrl] = useState(selectedEvent?.customUrl || "");
	const [customUrlError, setCustomUrlError] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const qrRef = useRef();
	const svgRef = useRef();

	const downloadQRCode = () => {
		let canvas = qrRef.current.querySelector("canvas");
		let image = canvas.toDataURL("image/png");
		let anchor = document.createElement("a");
		anchor.href = image;
		anchor.download = `qr-code.png`;
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	};

	const rayzeLogo = require("assets/icon.png");

	//qr code
	const qrCode = (
		<QRCodeCanvas
			id="qrCode"
			value={url}
			size={300}
			bgColor={"white"}
			imageSettings={{
				src: rayzeLogo,
				x: undefined,
				y: undefined,
				height: 75,
				width: 75
			}}
			level="H"
		/>
	);

	//flyers
	const svgCode = (
		<QRCodeSVG
			id="qrSVG"
			value={url}
			size={212}
			bgColor={"white"}
		/>
	);

	const SvgDL = () => {
		const node = svgRef.current;
		if (!node) return;
		const find = document.getElementById("qrSVG");
		const children = find.children;
		return (
			<>
				<Path
					d={children[0].getAttribute("d")}
					fill="#ffffff"
					shape-rendering="crispEdges"
				/>
				<Path
					d={children[1].getAttribute("d")}
					fill="#000000"
					shape-rendering="crispEdges"
				/>
			</>
		);
	};

	function downloadFlyer() {
		if (selectedFlyer === "qrCode") {
			downloadQRCode();
			return;
		}
		pdf(
			selectedEvent ? (
				<EventFlyers
					selectedFlyer={selectedFlyer}
					selectedOrg={selectedOrg}
					selectedEvent={selectedEvent}
					SvgDL={<SvgDL />}
				/>
			) : (
				<Flyer
					selectedFlyer={selectedFlyer}
					selectedOrg={selectedOrg}
					SvgDL={<SvgDL />}
				/>
			)
		)
			.toBlob()
			.then((blob) => {
				window.open(URL.createObjectURL(blob));
			});
	}

	const copyLink = async () => {
		try {
			await navigator.clipboard.writeText(
				selectedEvent?.customUrl ? baseUrl + selectedEvent?.customUrl : url
			);
			setOpenSnackbar("Link copied.");
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	async function customUrlHandler(url) {
		//remove special characters except numbers and hyphen
		url = url.replace(/[^0-9a-z-]/gi, "");
		//remove multiple hyphens
		url = url.replace(/-{2,}/g, "-");
		//force lowercase
		url = url.toLowerCase();

		clearTimeout(customUrlTimer);
		setCustomUrl(url);
		setCustomUrlError(false);
		customUrlTimer = setTimeout(async () => {
			console.log("check");
			//search if the custom url is already taken
			const exists = await checkCustomEventUrls(url);
			if (exists) {
				setCustomUrlError(true);
				// alert(`Custom URL ${url} already taken`);
				// let substring = url.slice(0, -1);
				// setCustomUrl(substring)
				return;
			}
		}, 500);
	}

	async function onSaveCustomEventUrl() {
		const error = await saveCustomEventUrl(selectedEvent.id, customUrl);
		if (error) {
			alert("Error saving custom URL");
			return;
		} else {
			setIsEditingUrl(false);
		}
	}

	return (
		<>
			<div
				ref={svgRef}
				style={{ display: "none" }}
			>
				{svgCode}
			</div>
			<div
				ref={qrRef}
				style={{ display: "none" }}
			>
				{qrCode}
			</div>

			<View style={styles.container}>
				{/* <View
					style={{
						paddingLeft: 12,
						paddingVertical: 12
					}}
				>
					<PrimaryText
						fontSize={16}
						fontWeight={500}
						style={{
							textAlign: "center",
							marginRight: 12,
							marginBottom: 4
						}}
					>
						{selectedEvent ? "Event Flyers Templates" : "Org Flyers Templates"}
					</PrimaryText>
					<View
						style={{
							flexDirection: "row",
							height: 100
						}}
					>
						<TouchableOpacity
							style={[
								styles.flyerContainer,
								selectedFlyer === "gradient" && {
									opacity: 0.5
								}
							]}
							onPress={() => setSelectedFlyer("gradient")}
						>
							<Image
								source={require("assets/templates/org_template_gradient.png")}
								style={{
									width: 75,
									height: "100%",
									resizeMode: "contain"
								}}
							/>
							{selectedFlyer === "gradient" && (
								<>
									<Button
										title={"Download"}
										style={{
											position: "absolute",
											margin: "auto",
											bottom: 0,
											top: 0,
											left: 4,
											right: 4,
											height: 25,
											padding: 2,
											paddingHorizontal: 6,
											text: {
												fontSize: 14,
												fontWeight: 600
											}
										}}
										onPress={downloadFlyer}
									/>
								</>
							)}
						</TouchableOpacity>
						<TouchableOpacity
							style={[
								styles.flyerContainer,
								selectedFlyer === "simple" && {
									opacity: 0.5
								}
							]}
							onPress={() => setSelectedFlyer("simple")}
						>
							<Image
								source={require("assets/templates/org_template_simple.png")}
								style={{
									width: 75,
									height: "100%",
									resizeMode: "contain"
								}}
							/>
							{selectedFlyer == "simple" && (
								<Button
									title={"Download"}
									style={{
										position: "absolute",
										margin: "auto",
										bottom: 0,
										top: 0,
										left: 4,
										right: 4,
										height: 25,
										// width: 100,
										padding: 2,
										paddingHorizontal: 6,
										text: {
											fontSize: 14,
											fontWeight: 600
										}
									}}
									onPress={downloadFlyer}
								/>
							)}
						</TouchableOpacity>
						<TouchableOpacity
							style={[
								styles.flyerContainer,
								selectedFlyer === "bg" && { opacity: 0.5 }
							]}
							onPress={() => setSelectedFlyer("bg")}
						>
							<Image
								source={require("assets/templates/org_template_bg.png")}
								style={{
									width: 75,
									height: "100%",
									resizeMode: "contain"
								}}
							/>
							{selectedFlyer == "bg" && (
								<Button
									title={"Download"}
									style={{
										position: "absolute",
										margin: "auto",
										bottom: 0,
										top: 0,
										left: 4,
										right: 4,
										height: 25,
										// width: 100,
										padding: 2,
										paddingHorizontal: 6,
										text: {
											fontSize: 14,
											fontWeight: 600
										}
									}}
									onPress={downloadFlyer}
								/>
							)}
						</TouchableOpacity>

						<View
							style={{
								borderRadius: 12,
								paddingLeft: 12,
								marginRight: 12
							}}
						>
							<PrimaryText
								fontSize={16}
								fontWeight={800}
								style={{
									textAlign: "center",
									marginRight: 12,
									marginBottom: 4
								}}
							>
								QR Code
							</PrimaryText>
							<TouchableOpacity
								style={[
									styles.flyerContainer,
									{ height: 100, justifyContent: "center" },
									selectedFlyer === "qrCode" && {
										opacity: 0.5
									}
								]}
								onPress={() => setSelectedFlyer("qrCode")}
							>
								<div style={{ display: "flex" }}>
									<QRCodeCanvas
										id="qrCode2"
										value={url}
										size={75}
										bgColor={"white"}
									/>
								</div>
								{selectedFlyer === "qrCode" && (
									<Button
										title={"Download"}
										style={{
											position: "absolute",
											margin: "auto",
											bottom: 0,
											top: 0,
											left: 4,
											right: 4,
											height: 25,
											padding: 2,
											paddingHorizontal: 6,
											text: {
												fontSize: 14,
												fontWeight: 600
											}
										}}
										onPress={downloadFlyer}
									/>
								)}
							</TouchableOpacity>
						</View>
					</View>
				</View> */}

				{/* <View style={styles.line} /> */}

				{/* Link  */}
				<Box
					display="flex"
					flexDirection="column"
					alignItems="flex-start"
				>
					<PrimaryText
						fontSize={16}
						fontWeight={500}
					>
						{selectedEvent ? "Event URL and QR Code" : "Direct Donation Link"}
					</PrimaryText>

					<Box
						display="flex"
						alignItems="center"
						mt={10}
					>
						<PrimaryText
							fontSize={14}
							fontWeight={400}
						>
							{isEditingUrl
								? baseUrl
								: selectedEvent?.customUrl
								? baseUrl + selectedEvent?.customUrl
								: url}
						</PrimaryText>

						{isEditingUrl && (
							<>
								<View>
									<CustomInput
										style={{ marginLeft: 4, maxWidth: 150 }}
										borderColor={customUrlError && COLORS.primary.error}
										value={customUrl}
										onChangeText={customUrlHandler}
									/>
									{customUrlError && (
										<View
											style={{
												flexDirection: "row",
												marginTop: 36,
												position: "absolute"
											}}
										>
											<FiAlertCircle
												width={16}
												height={16}
												fill={COLORS.text.white}
												stroke={COLORS.text.error}
												style={{ marginRight: 4, marginLeft: 4 }}
											/>
											<PrimaryText
												fontSize={14}
												fontWeight={400}
												color={COLORS.primary.error}
											>
												Custom URL already taken
											</PrimaryText>
										</View>
									)}
								</View>

								<MuiButton
									sx={muiStyles.save}
									title="Save"
									variant="contained"
									onPress={onSaveCustomEventUrl}
									disabled={customUrlError}
								/>

								<MuiButton
									sx={muiStyles.cancel}
									title="Cancel"
									variant="outlined"
									color="secondary"
									onPress={() => setIsEditingUrl(false)}
								/>
							</>
						)}

						{!isEditingUrl && (
							<MuiButton
								title="Edit"
								variant="contained"
								startIcon={
									<Edit
										fill={COLORS.primary.white}
										width={12}
									/>
								}
								sx={muiStyles.edit}
								onPress={() => setIsEditingUrl(true)}
							/>
						)}
					</Box>

					<MuiButton
						title="Copy Link"
						variant="outlined"
						color="secondary"
						startIcon={
							<Files
								fill={COLORS.primary.darkBlue}
								width={16}
								style={{ opacity: isEditingUrl ? 0.5 : 1 }}
							/>
						}
						sx={muiStyles.copyLink}
						onPress={copyLink}
						disabled={isEditingUrl}
					/>

					<MuiButton
						title="Download QR Code"
						variant="contained"
						startIcon={
							<QRCode
								fill={COLORS.primary.white}
								width={16}
							/>
						}
						sx={{ height: 29, fontSize: 14, fontWeight: 700 }}
						onPress={downloadQRCode}
						disabled={isEditingUrl}
					/>
				</Box>
			</View>

			<MuiSnackbar
				open={openSnackbar}
				onClose={() => setOpenSnackbar(false)}
			/>
		</>
	);
}

const muiStyles = {
	copyLink: {
		height: 29,
		fontSize: 14,
		fontWeight: 700,
		marginRight: 20,
		marginTop: 20,
		marginBottom: 13
	},
	edit: {
		height: 23,
		fontSize: 12,
		marginLeft: 10
	},
	cancel: {
		height: 23,
		fontSize: 12,
		fontWeight: 700
	},
	save: {
		height: 23,
		fontSize: 12,
		fontWeight: 700,
		marginLeft: 12,
		marginRight: 12
	}
};

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		paddingRight: 50
	},
	flyerContainer: {
		backgroundColor: COLORS.primary.lightestBlue,
		borderWidth: 1,
		borderColor: "rgba(29, 161, 242, 0.4)",
		borderRadius: 5,
		padding: 4,
		paddingHorizontal: 12,
		marginRight: 12
	},
	line: {
		width: 2,
		height: "100%",
		backgroundColor: COLORS.primary.neutral_400,
		marginHorizontal: 24
	}
});
