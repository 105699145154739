import { StyleSheet, View, Dimensions, TouchableOpacity } from "react-native";
import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import MuiButton from "components/Common/MuiButton";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { COMMUNITY_TIPS, NONPROFIT_TIPS, TIPS } from "constants/Data";
import { useNavigate } from "react-router-dom";
import { updateMemberInfo } from "services/user-service";
import { useOrganization } from "context/OrganizationContext";

const { width, height } = Dimensions.get("window");

export default function SidebarTips({ children, index, path, onNext, show }) {
	const navigate = useNavigate();
	const { selectedOrg, currentMember } = useOrganization();

	if (!show || currentMember?.hideTips) return children;

	const { title, description, hideIcon, buttonTitle, nextPath } =
		selectedOrg?.type === "community"
			? COMMUNITY_TIPS[path]
			: selectedOrg?.type === "nonprofit"
			? NONPROFIT_TIPS[path]
			: TIPS[path] || {};

	const hideTips = () =>
		updateMemberInfo(selectedOrg, currentMember, { hideTips: true }, () => {});

	return (
		<View>
			{children}

			<TouchableOpacity onPress={hideTips} style={styles.overlay} />

			<View style={styles.container}>
				<View style={[styles.wrapper, { top: index < 7 ? -45 : -185 }]}>
					<View style={[styles.triangle, { top: index < 7 ? "25%" : "75%"}]} />

					<View style={styles.centered}>
						<PrimaryText
							fontSize={14}
							fontWeight={700}
							style={{ color: COLORS.primary.neutral_400 }}
						>
							{index + 1}/
							{selectedOrg?.type === "nonprofit"
								? Object.keys(NONPROFIT_TIPS).length
								: selectedOrg?.type === "community"
								? Object.keys(COMMUNITY_TIPS).length
								: "6"}
						</PrimaryText>

						<PrimaryText
							fontSize={24}
							fontWeight={900}
							style={{ color: COLORS.primary.neutral_800, marginTop: 5 }}
						>
							{title}
						</PrimaryText>

						<PrimaryText
							fontSize={16}
							style={{
								color: COLORS.primary.neutral_800,
								marginTop: 11,
								marginHorizontal: 20,
								lineHeight: 24
							}}
						>
							{description}
						</PrimaryText>

						<View style={{ height: 15 }} />

						<View style={styles.buttonRow}>
							<TouchableOpacity onPress={hideTips}>
								<PrimaryText
									fontSize={16}
									fontWeight={700}
									style={{
										color: COLORS.primary.neutral_400
									}}
								>
									Hide Tips
								</PrimaryText>
							</TouchableOpacity>

							<MuiButton
								title={buttonTitle || "Next"}
								sx={{ width: 110 }}
								variant="contained"
								endIcon={hideIcon ? null : <KeyboardArrowRightRoundedIcon />}
								onPress={() => {
									navigate(nextPath);
									onNext?.();

									if (buttonTitle === "Finish") hideTips();
								}}
							/>
						</View>
					</View>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	overlay: {
		width: width - 280,
		height,
		backgroundColor: "rgba(0,0,0, 0.25)",
		position: "fixed",
		top: 0,
		left: 280,
		zIndex: 950
	},
	container: {
		position: "fixed",
		left: 340,
		zIndex: 999
	},
	wrapper: {
		top: -45,
		shadowColor: "#000",
		shadowOpacity: 0.15,
		shadowRadius: 15,
		shadowOffset: {
			width: 5,
			height: 5
		},
		borderRadius: 15,
		backgroundColor: "#fff"
	},
	centered: {
		width: 370,
		minHeight: 245,
		backgroundColor: COLORS.background.white,
		borderRadius: 15,
		paddingHorizontal: 25,
		paddingVertical: 30,
		opacity: 1
	},
	triangle: {
		position: "absolute",
		top: "25%",
		left: -30,
		backgroundColor: "#fff",
		clipPath: `polygon(100% 0%, 100% 100%, 0% 50%)`,
		width: 32,
		height: 32
	},
	buttonRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: "auto"
	}
});
