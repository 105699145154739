import { StyleSheet } from "react-native";
import React from "react";
import { TouchableOpacity } from "react-native-web";
import { PrimaryText } from "components/Common/PrimaryText";
import { useNavigate } from "react-router-dom";
import { COLORS } from "constants/Colors";

export default function TextButton({ onPress, to, text, textStyle }) {
  const navigate = useNavigate();

  return (
    <TouchableOpacity
      onPress={() => {
        if (typeof onPress === "function") onPress();
        else if (typeof to === "string") navigate(to);
      }}
    >
      <PrimaryText
        fontSize={14}
        style={[{ color: COLORS.primary.lightBlue }, textStyle]}
      >
        {text}
      </PrimaryText>
    </TouchableOpacity>
  );
}
