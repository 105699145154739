import { StyleSheet, TouchableOpacity } from "react-native";
import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { useNavigate } from "react-router-dom";

export default function PrimaryButton({
  title,
  onPress,
  to,
  variant,
  disabled,
  style,
  titleStyle,
  icon,
  fontSize = 16,
  fontWeight = 900,
}) {
  const navigate = useNavigate();

  return (
    <TouchableOpacity
      onPress={() => {
        onPress?.();
        if (to) navigate(to);
      }}
      style={[
        styles.button,
        variant === "outlined"
          ? styles.outlined
          : variant === "link"
          ? styles.link
          : styles.container,
        style,
        disabled && { opacity: 0.5 },
      ]}
      disabled={disabled}
    >
      {icon}
      <PrimaryText
        fontSize={fontSize}
        fontWeight={fontWeight}
        style={[
          variant === "outlined"
            ? styles.outlinedTitle
            : variant === "link"
            ? styles.linkTitle
            : styles.containedTitle,
          titleStyle,
        ]}
      >
        {title}
      </PrimaryText>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 50,
    height: 44,
    borderRadius: 22,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
    flexDirection: "row",
  },
  container: {
    backgroundColor: COLORS.primary.lightBlue,
  },
  link: {
    backgroundColor: "transparent",
  },
  outlined: {
    borderWidth: 1,
    borderColor: COLORS.primary.darkGray,
  },
  containedTitle: {
    color: COLORS.primary.white,
  },
  outlinedTitle: {
    color: COLORS.primary.darkGray,
  },
  linkTitle: {
    color: COLORS.primary.error,
  },
});
