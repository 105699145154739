import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { DONATION_STATUSES } from "constants/Data";
import { COLORS } from "constants/Colors";
import * as FiIcons from "react-icons/fi";
import { StyleSheet, TouchableOpacity } from "react-native-web";
import moment from "moment";
import LinearProgressWithLabel from "components/Progress/LinearProgressWithLabel";

export const BusinessCampaignCard = ({ item, onPress, onArchive }) => {
  const {
    name,
    causes = [],
    backers = 0,
    goal = 0,
    raised = 0,
    goalType,
    startDate,
    endDate,
    status,
  } = item;

  let currentStatus = "Coming Soon";
  let color = COLORS.lightBlue;
  if (endDate < new Date()) {
    color = COLORS.primary.error;
    currentStatus = "Ended";
  } else if (startDate < new Date()) {
    color = COLORS.primary.green;
    currentStatus = "Live";
  }

  const raisedPercentage = Math.round((raised / goal) * 100);

  return (
    <TableRow hover onClick={onPress}>
      {/* <TableCell width={36}>
        <ProfilePic uri={user?.profilePic} size={36} />
      </TableCell> */}
      <TableCell style={{ textAlign: "left" }}>
        <PrimaryText fontSize={18} fontWeight={900}>
          {name}
        </PrimaryText>
      </TableCell>
      {/* <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          causes
        </PrimaryText>
      </TableCell> */}
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {backers}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <LinearProgressWithLabel
          value={raisedPercentage}
          fontSize={16}
          leftText={"Raised:"}
          leftValue={dollarFormatter.format(raised).slice(0, -3)}
          leftTextStyle={{ color: COLORS.primary.darkBlue }}
          leftValueStyle={{ color: COLORS.primary.lightBlue, fontWeight: 900 }}
          rightText={"Goal:"}
          rightValue={dollarFormatter.format(goal).slice(0, -3)}
          rightTextStyle={{ color: COLORS.primary.darkBlue }}
          rightValueStyle={{ color: COLORS.primary.gray, fontWeight: 900 }}
          height={15}
        />
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {moment(startDate).format("MM/DD") +
            " - " +
            moment(endDate).format("MM/DD")}
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500} style={{ color }}>
          {currentStatus}
        </PrimaryText>
      </TableCell>
      {true && (
        <>
          {/* <TableCell>
            <TouchableOpacity style={styles.button}>
              <FiIcons.FiEdit size={24} color={COLORS.primary.gray} />
            </TouchableOpacity>
          </TableCell> */}

          <TableCell>
            <TouchableOpacity style={styles.button} onPress={onArchive}>
              <FiIcons.FiArchive size={24} color={COLORS.primary.error} />
            </TouchableOpacity>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 4,
  },
});
