import React from "react"
import { StyleSheet, View } from "react-native"
import { COLORS } from "constants/Colors"
import { PrimaryText } from "components/Common/PrimaryText"
import { ArrowBackIos } from "@mui/icons-material"
import { TouchableOpacity } from "react-native-web"
import { FaPencilAlt } from "react-icons/fa"

export function NavHeader(props) {
  const { title, onBack, onEdit } = props
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <TouchableOpacity onPress={onBack}>
          <ArrowBackIos />
        </TouchableOpacity>
        <PrimaryText fontSize={24} fontWeight={700} style={styles.header}>
          {title}
        </PrimaryText>
      </View>
      {onEdit && (
        <TouchableOpacity onPress={onEdit}>
          <FaPencilAlt size={20} color={COLORS.primary.lightBlue} />
        </TouchableOpacity>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
})
