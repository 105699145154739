import React from "react";
import { PrimaryText } from "./PrimaryText";
import { COLORS } from "constants/Colors";
import { BREAKPOINTS } from "utils/breakpoints";
import { useWindowDimensions } from "react-native-web";

export default function TermsAndPrivacyText({ style }) {
  const { width } = useWindowDimensions();

  return (
    <PrimaryText
      fontSize={14}
      style={{
        color: COLORS.primary.neutral_500,
        marginTop: 20,
        textAlign: "center",
        position: width > BREAKPOINTS["sm"] ? "relative" : "absolute",
        bottom: width > BREAKPOINTS["sm"] ? null : 50,
        paddingBottom: 50,
        maxWidth: 360,
        ...style,
      }}
    >
      By signing up you accept the{" "}
      <PrimaryText style={{ color: COLORS.primary.blue }}>
        Terms of Service
      </PrimaryText>{" "}
      and{" "}
      <PrimaryText style={{ color: COLORS.primary.blue }}>
        Privacy Policy
      </PrimaryText>
      .
    </PrimaryText>
  );
}
