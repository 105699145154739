import { Box, ButtonBase } from "@mui/material";
import {
	DinersClub,
	Discover,
	JCB,
	Mastercard,
	PayPal,
	Politics,
	Visa
} from "assets/svgs";
import PaymentMethodPickerCard from "components/Cards/PaymentMethodPickerCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";
import { LuCoins, LuPlusCircle } from "react-icons/lu";
import { dollarFormatter } from "utils/helpers";

const ICONS = {
	rayze: (
		<LuCoins
			color={COLORS.primary.darkBlue}
			size={24}
		/>
	),
	bank: (
		<Politics
			fill={COLORS.primary.darkBlue}
			width={26}
			height={26}
		/>
	),
	mastercard: (
		<Mastercard
			width={26}
			height={26}
		/>
	),
	visa: (
		<Visa
			width={26}
			height={26}
		/>
	),
	dinersclub: (
		<DinersClub
			fill={COLORS.primary.darkBlue}
			width={26}
			height={26}
		/>
	),
	discover: (
		<Discover
			fill={COLORS.primary.darkBlue}
			width={26}
			height={26}
		/>
	),
	jcb: (
		<JCB
			width={26}
			height={26}
		/>
	),
	paypal: (
		<PayPal
			width={26}
			height={26}
		/>
	)
};

const RAYZE_AMOUNT = 10_801;


export default function PaymentMethodPicker({
	onChange,
	insufficient,
	paymentMethod,
	title,
	boxProps,
	required, rayzeAccountBalance,
	paymentMethods,
	selectedPaymentMethod, setSelectedPaymentMethod
}) {

	const PAYMENT_METHODS = [
		{
			icon: ICONS.rayze,
			title: "Rayze Account",
			desc: `Balance: ${dollarFormatter.format(rayzeAccountBalance)}`
		},
		{
			icon: ICONS.bank,
			title: "Bank Account",
			desc: "xxxx-xxxx-xxxx-1111"
		}
	];

	return (
		<Box {...boxProps}>
			<PrimaryText
				fontSize={16}
				fontWeight={900}
			>
				{title || "Payment Method"}
				{required && <span style={{ color: COLORS.primary.error }}>*</span>}
			</PrimaryText>
			<Box ml={20}>
				{/* {!!paymentMethod && (
					<PaymentMethodPickerCard
						{...paymentMethod}
						insufficient={insufficient}
						selectable={false}
						active={true}
						onChange={() => onChange?.()}
					/>
				)} */}
				{paymentMethods?.length &&
					paymentMethods.map((item, index) => (
						<PaymentMethodPickerCard
							key={index}
							item={item}
							selectable={true}
							active={item.id === selectedPaymentMethod?.id}
							onChange={() => onChange?.(item)}
						/>
					))}

				{/* {!paymentMethod &&
					PAYMENT_METHODS.map((item, index) => (
						<PaymentMethodPickerCard
							key={index}
							{...item}
							insufficient={insufficient}
							selectable={true}
							onChange={() => {
								onChange?.(item);
							}}
							rayzeAccountBalance={rayzeAccountBalance}
						/>
					))} */}

				{!paymentMethod && (
					<ButtonBase
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							alignSelf: "flex-start",
							gap: 8,
							mt: 18
						}}
						disableRipple
					>
						<LuPlusCircle
							color={COLORS.primary.lightBlue}
							size={20}
						/>
						<PrimaryText
							fontSize={16}
							fontWeight={900}
							color={COLORS.primary.lightBlue}
						>
							Add New
						</PrimaryText>
					</ButtonBase>
				)}
			</Box>
		</Box>
	);
}
