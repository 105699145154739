import { Box } from "@mui/material";
import { MapPin, Pointer } from "assets/svgs";
import { PrimaryText } from "components/Common/PrimaryText";
import TypeCard from "./TypeCard";
import { COLORS } from "constants/Colors";
import React from "react";
import MuiTextInput from "components/Common/MuiTextInput";
import UploadCoverPhoto from "./UploadCoverPhoto";
import GooglePlaceInput from "components/Common/GooglePlaceInput";

const OPTIONS = {
	InPerson: "In-Person",
	Virtual: "Virtual"
};

export default function DetailsAndLocation({
	details,
	handleChange,
	errors,
	handleEventChange
}) {
	return (
		<Box px={40}>
			<PrimaryText
				fontSize={16}
				fontWeight={900}
			>
				Event Type<PrimaryText color={COLORS.primary.error}>*</PrimaryText>
			</PrimaryText>

			<TypeCard
				title={OPTIONS.InPerson}
				description="Examples: A specific date and time, recurring needs, or ongoing opportunities"
				icon={<MapPin fill={COLORS.primary.darkBlue} />}
				value={details?.mode}
				onClick={(e) => handleChange("mode", e)}
			/>
			<TypeCard
				title={OPTIONS.Virtual}
				description="Examples: Online tutoring, virtual meetup, or instructional opportunity"
				icon={<Pointer fill={COLORS.primary.darkBlue} />}
				value={details?.mode}
				onClick={(e) => handleChange("mode", e)}
			/>

			{details?.mode === OPTIONS.InPerson && (
				<GooglePlaceInput
					title="Location"
					required={true}
					onChange={({ latLng, address }) => {
						handleChange("address", address);
						handleChange("latLng", latLng);
					}}
					selectedAddress={details?.address}
					errorMessage={errors?.address}
				/>
			)}

			{details?.mode === OPTIONS.Virtual && (
				<>
					<MuiTextInput
						label="Virtual Meeting Information?"
						placeholder="Enter Meeting URL"
						required={true}
						value={details?.meetingURL}
						name="meetingURL"
						onChange={handleEventChange}
						errorMessage={errors?.meetingURL}
					/>

					<MuiTextInput
						placeholder="Enter Meeting Passcode (not required)"
						boxProps={{ mt: 8 }}
						value={details?.passcode}
						name="passcode"
						onChange={handleEventChange}
						errorMessage={errors?.passcode}
					/>
				</>
			)}

			<MuiTextInput
				label="Event Name"
				placeholder="Enter Event Name"
				required={true}
				value={details?.name}
				name="name"
				onChange={handleEventChange}
				errorMessage={errors?.name}
			/>

			<MuiTextInput
				label="Description"
				placeholder="Describe your event in 500 characters or less "
				required={true}
				multiline={true}
				value={details?.about}
				name="about"
				onChange={handleEventChange}
				errorMessage={errors?.about}
			/>

			{console.log(details?.coverPhoto)}

			<UploadCoverPhoto
				onChange={(e) => handleChange("coverPhoto", e)}
				coverPhoto={details?.coverPhoto}
				errorMessage={errors?.coverPhoto}
			/>
		</Box>
	);
}
