import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	IconButton,
	Menu,
	MenuItem
} from "@mui/material";
import { ChevronDown, More } from "assets/svgs";
import ErrorMessage from "components/Common/ErrorMessage";
import MuiTextInput from "components/Common/MuiTextInput";
import { PrimaryText } from "components/Common/PrimaryText";
import MuiSelect from "components/Inputs/MuiSelect";
import MuiTimePicker from "components/Inputs/MuiTimePicker";
import { COLORS } from "constants/Colors";
import moment from "moment";
import React, { useState } from "react";

export default function ShiftCard({
	expanded,
	onToggleExpansion,
	shiftNo,
	handleChange,
	shifts,
	minStartTime,
	maxEndTime,
	errorMessage,
	onDelete
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (e) => {
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
	};
	const handleClose = (e) => {
		e.stopPropagation();
		setAnchorEl(null);
	};

	const isExpand = expanded && shiftNo && expanded === shiftNo;
	const shift = shifts?.find((shift) => shift?.shiftNo === shiftNo);

	const onChange = (name, value) => {
		const updatedShift = { ...shift };
		updatedShift[name] = value;

		if (name === "startTime") updatedShift["endTime"] = null;

		const shiftIndex = shifts.findIndex((shift) => shift?.shiftNo === shiftNo);
		let updatedShifts = [...shifts];
		updatedShifts[shiftIndex] = updatedShift;

		handleChange("shifts", updatedShifts);
	};

	console.log('end time', shift?.endTime)

	return (
		<>
			<Accordion
				sx={{
					bgcolor: COLORS.primary.neutral,
					borderRadius: "12px !important",
					mt: 12,
					overflow: "hidden"
				}}
				variant="outlined"
				expanded={isExpand}
				onChange={(_, isExpanded) =>
					onToggleExpansion?.(isExpanded ? shiftNo : null)
				}
			>
				<AccordionSummary
					expandIcon={<ChevronDown fill={COLORS.primary.darkBlue} />}
					sx={{
						pl: 24,
						pr: 16,
						height: 51,
						borderWidth: 0,
						m: 0,
						"& > .Mui-expanded": {
							my: 0
						},
						"&.Mui-expanded": {
							minHeight: 0
						}
					}}
				>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-between"
						width="100%"
					>
						<PrimaryText
							fontSize={14}
							fontWeight={900}
						>
							Shift # {shiftNo}
							{!isExpand ? (
								<PrimaryText
									fontWeight={300}
									style={{ marginLeft: 20 }}
								>
									{shift?.name || "Name"} | {shift?.spots || "Spots"} |{" "}
									{moment(shift?.startTime).isValid()
										? moment(shift?.startTime).format("hh:mm A")
										: "Start Time"}{" "}
									-{" "}
									{moment(shift?.endTime).isValid()
										? moment(shift?.endTime).format("hh:mm A")
										: "End Time"}
								</PrimaryText>
							) : null}
						</PrimaryText>
					</Box>

					<IconButton
						sx={{ mr: 8 }}
						onClick={handleClick}
					>
						<More fill={COLORS.primary.darkBlue} />
					</IconButton>

					<Menu
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						<MenuItem
							onClick={() => {
								onDelete?.();
								handleClose();
							}}
						>
							Delete
						</MenuItem>
					</Menu>
				</AccordionSummary>

				<AccordionDetails sx={{ px: 24, pb: 24 }}>
					<Box
						display="flex"
						alignItems="flex-end"
						gap={12}
					>
						<MuiTextInput
							labelFontSize={14}
							labelFontWeight={600}
							labelStyle={{ marginLeft: 6 }}
							inputStyle={{ marginTop: 0 }}
							boxProps={{ mt: 0, width: 190 }}
							label="Name"
							placeholder="Enter Shift Name"
							value={shift?.name}
							onChange={(e) => onChange("name", e.target.value)}
						/>

						<MuiSelect
							label="Spots"
							options={Array.from({ length: 100 }, (_, i) => i + 1)}
							onSelect={(spot) => onChange("spots", spot)}
							value={shift?.spots}
						/>

						<Box
							display="flex"
							alignItems="flex-end"
							gap={2}
						>
							<MuiTimePicker
								label="Time"
								labelFontSize={14}
								labelFontWeight={600}
								labelStyle={{ marginLeft: 6, marginBottom: 0 }}
								boxProps={{ width: 120 }}
								onChange={(startTime) => {
									onChange("startTime", startTime);
								}}
								value={shift?.startTime}
								minTime={minStartTime}
								maxTime={maxEndTime}
							/>
							<MuiTimePicker
								labelFontSize={14}
								labelFontWeight={600}
								labelStyle={{ marginLeft: 6, marginBottom: 0 }}
								boxProps={{ width: 120 }}
								onChange={(endTime) => onChange("endTime", endTime)}
								value={shift?.endTime}
								minTime={
									moment(shift?.startTime).isValid()
										? moment(shift?.startTime).add(5, "m")
										: minStartTime
								}
								maxTime={maxEndTime}
							/>
						</Box>
					</Box>

					<MuiTextInput
						labelFontSize={14}
						labelFontWeight={600}
						labelStyle={{ marginLeft: 6 }}
						inputStyle={{ marginTop: 0, fontSize: 12, height: 55 }}
						label="Description"
						placeholder="Describe what the volunteer will be doing in 500 characters or less"
						multiline={true}
						rows={2}
						onChange={(e) => onChange("description", e.target.value)}
						value={shift?.description}
					/>
				</AccordionDetails>
			</Accordion>

			<ErrorMessage
				errorMessage={errorMessage}
				marginTop={5}
			/>
		</>
	);
}
