import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { CustomInput } from "components/Inputs/CustomInput";
import { Links } from "constants/Links";
import TextButton from "components/Buttons/TextButton";
import {
	validateConfirmPassword,
	validateEmail,
	validatePassword,
	validatePhone
} from "utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import {
	checkEmailSignInMethods,
	checkPhoneSignInMethods,
	signInWithPhoneNumber
} from "services/auth-service";
import { useLoading } from "context/LoadingContext";

export default function CreatePersonalAccount() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { setLoading } = useLoading();

	const [inputs, setInputs] = useState({
		email: state?.email || "",
		phone: state?.phone || "",
		password: state?.password || "",
		confirmPassword: state?.confirmPassword || ""
	});

	const { email, phone, password, confirmPassword } = inputs;

	const [countryCode, setCountryCode] = useState("+1");

	const [errors, setErrors] = useState({});

	const onContinue = () => {
		let hasError = false;

		Object.values(errors).forEach((value) => {
			if (value) hasError = true;
		});

		Object.entries(inputs).forEach(([key, value]) => {
			if (!value) {
				setErrors((prev) => ({ ...prev, [key]: "This field is required." }));
				hasError = true;
			}
		});

		if (hasError) return;

		signUpPressed();
	};

	const handleChange = (key, e) => {
		const value = e.target.value;

		setErrors((prev) => ({ ...prev, [key]: null }));

		if (key === "email" && !validateEmail(value))
			handleErrors("email", "Please enter a valid email.");
		else if (key === "phone" && !validatePhone(value))
			handleErrors("phone", "Please enter a valid phone number.");
		else if (key === "password" && !validatePassword(value))
			handleErrors("password", "Password should contain at least 6 characters, one number and one capital letter.");
		else if (key === "password" && value.toLowerCase().includes("password"))
			handleErrors("password", "Password should not contain the word 'password'.");
		else if (
			key === "confirmPassword" &&
			!validateConfirmPassword(inputs.password, value)
		)
			handleErrors("confirmPassword", "Your passwords doesn't match.");

		setInputs((prev) => ({ ...prev, [key]: value }));
	};

	const handleErrors = (key, value) =>
		setErrors((prev) => ({ ...prev, [key]: value }));

	//** START EMAIL FLOW */
	//1. check if email exists
	const signUpPressed = () => {
		checkEmailSignInMethods(email, onEmailCheckSuccess, (err) =>
			handleErrors("email", err)
		);
	};

	//2. no email exists, check if phone exists
	function onEmailCheckSuccess() {
		setLoading(true);
		checkPhoneSignInMethods(countryCode + phone, onPhoneCheckSuccess, (err) =>
			handleErrors("phone", err)
		);
	}

	//3. no phone exists, sign in with phone
	function onPhoneCheckSuccess() {
		setLoading(false);
		// recaptchaVerifierInvisible()
		navigate(Links.VerifyPersonalAccount, {
			state: { ...inputs, countryCode }
		});
		// setPhoneVerificationModal(true)
	}

	return (
		<View style={styles.container}>
			<img
				src={require("assets/rayze.png")}
				width={77.64}
			/>

			<PrimaryText
				fontSize={54}
				fontWeight="900"
			>
				Create Personal Account
			</PrimaryText>

			<PrimaryText
				fontSize={32}
				style={{ color: COLORS.primary.gray, marginTop: 14 }}
			>
				Enter Your Login Information
			</PrimaryText>

			<View style={{ width: 541, marginTop: 59 }}>
				<CustomInput
					placeholder="enter phone number"
					onChange={(e) => handleChange("phone", e)}
					value={inputs?.phone}
					errorMessage={errors?.phone}
					textContentType="telephoneNumber"
					name="phone"
					title="Phone Number"
					mandatory
				/>

				<CustomInput
					style={{ marginVertical: 20 }}
					placeholder="enter email address"
					onChange={(e) => handleChange("email", e)}
					value={inputs?.email}
					errorMessage={errors?.email}
					textContentType="emailAddress"
					name="email"
					title="Email Address"
					mandatory
				/>

				<View style={styles.line} />

				<CustomInput
					style={{ marginTop: 20, marginBottom: 8 }}
					placeholder="enter password"
					onChange={(e) => handleChange("password", e)}
					value={inputs?.password}
					errorMessage={errors?.password}
					secure={true}
					textContentType="password"
					name="password"
					title="Password"
					mandatory
				/>

				<CustomInput
					placeholder="re-enter password"
					onChange={(e) => handleChange("confirmPassword", e)}
					value={inputs?.confirmPassword}
					errorMessage={errors?.confirmPassword}
					secure={true}
					textContentType="password"
					name="confirmPassword"
					// title="Confirm Password"
					// mandatory
				/>
			</View>

			<PrimaryButton
				onPress={onContinue}
				title="Continue"
				style={{ marginVertical: 40 }}
				id="sign-in-button"
			/>

			<PrimaryText
				fontSize={14}
				style={{ color: COLORS.primary.gray, textAlign: "center" }}
			>
				By signing up you accept the{" "}
				<TextButton
					text="Terms of Service "
					onPress={() => window.open("https://rayzeapp.com/terms/", "_blank")}
				/>
				and
				<br />
				<TextButton
					text="Privacy Policy"
					onPress={() => window.open("https://rayzeapp.com/privacy/", "_blank")}
				/>
				.
			</PrimaryText>

			<PrimaryText
				fontSize={14}
				style={{ color: COLORS.primary.gray, marginTop: 49 }}
			>
				Already have an organization account?{" "}
				<TextButton
					text="Sign In"
					to={Links.Landing}
				/>
			</PrimaryText>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center"
	},
	line: {
		height: 1,
		width: 143,
		backgroundColor: "#737373",
		alignSelf: "center"
	}
});
