import React from "react";
import {
	FormControl,
	FormControlLabel,
	RadioGroup,
	Radio
} from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";

export default function MuiRadioGroup({
	options,
	value,
	label,
	containerStyle,
	onChange,
	...props
}) {
	return (
		<FormControl sx={{ mt: 20, ...containerStyle }}>
			{label ? (
				<PrimaryText
					fontSize={18}
					fontWeight={900}
					sx={{ mb: 10 }}
				>
					{label}
				</PrimaryText>
			) : null}
			<RadioGroup
				row
				onChange={(e) => onChange?.(e.target.value)}
			>
				{options?.map((item, index) => (
					<FormControlLabel
						key={index}
						componentsProps={{
							typography: {
								fontSize: 17,
								fontWeight: 900
							}
						}}
						control={
							<Radio
								color="primary"
								size="small"
							/>
						}
						value={item}
						label={item}
						sx={{ mr: 17 }}
						checked={value === item}
						{...props}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
}
