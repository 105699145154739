import { Box } from "@mui/material";
import Misc from "assets/misc";
import MuiEditButton from "components/Common/MuiEditButton";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";

export default function EditablePic({
	imageSrc,
	onChange,
	label,
	square,
	boxProps,
	freeform
}) {
	return (
		<Box
			display="flex"
			flexDirection="column"
			{...boxProps}
		>
			{!!label && (
				<PrimaryText
					fontSize={17}
					fontWeight={600}
					style={{ marginBottom: 6 }}
				>
					{label}
				</PrimaryText>
			)}

			{/* Cover */}
			<Box
				borderRadius="12px"
				border={0.5}
				borderColor={COLORS.primary.neutral_400}
				overflow="hidden"
				width={freeform ? null : square ? 174 : 310}
				height={174}
				maxWidth={500}
			>
				<img
					src={imageSrc || (square ? Misc.emptyPicSquare : Misc.emptyPicRect)}
					alt="cover"
					width="100%"
					height="100%"
					style={{
						objectFit: "contain"
					}}
				/>
			</Box>

			{/* Edit/Change Button */}
			<MuiEditButton onChange={onChange} />
		</Box>
	);
}
