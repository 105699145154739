import React, { useState, createContext, useEffect, useContext } from "react";

export const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    
  }, []);

  return (
    <StripeContext.Provider
      value={{
        stripe,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export const useStripe = () => useContext(StripeContext);
