import { Box, MenuItem, Select } from "@mui/material";
import { ChevronDown } from "assets/svgs";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";

export default function MuiSelect({
	label,
	options,
	onSelect,
	value,
	horizontal,
	labelStyle = {},
	sx
}) {
	return (
		<Box sx={horizontal ? { display: "flex", alignItems: "center" } : {}}>
			<PrimaryText
				fontSize={14}
				fontWeight={600}
				style={horizontal ? { marginRight: 10 } : {}}
				{...labelStyle}
			>
				{label}
			</PrimaryText>
			<Select
				onChange={(e) => onSelect(e.target.value)}
				value={value || ""}
				IconComponent={() => (
					<ChevronDown
						fill={COLORS.primary.darkBlue}
						fontSize={14}
						style={{
							marginRight: 14,
							width: 34
						}}
					/>
				)}
				sx={{
					borderRadius: 4,
					height: 30,
					fontSize: 14,
					bgcolor: COLORS.primary.white,
					"& > .MuiSelect-select": {
						display: "flex",
						flexShrink: 1,
						pr: "0 !important",
						pl: 13
					},
					...sx
				}}
			>
				{options?.map((option, index) => (
					<MenuItem
						key={option + "-" + index}
						value={option}
					>
						{option}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
}
