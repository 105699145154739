import React, { useEffect, useState } from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View, ScrollView, TouchableOpacity } from "react-native";
import { EventsCard } from "components/Cards/EventsCard";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@mui/material";
import { useEvents } from "context/EventContext";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FiIcons from "react-icons/fi";
import { ConfirmModal } from "components/Modals/Confirmation";
import { FullModal } from "components/Modals/FullModal";
import { ModalHeader } from "components/Modals/ModalHeader";
import { useAuth } from "context/AuthContext";
import { useOrganization } from "context/OrganizationContext";
import { AddEventInfoForm } from "components/Events/AddEventInfoForm";
import { ActivityIndicator } from "react-native-web";
import { createEvent, deleteEvent } from "services/events-service";
import { Button } from "components/Common/Button";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { EventCreationModal } from "components/Modals/EventCreationModal";

const geofire = require("geofire-common");

export const Events = () => {
	const { selectedOrg, userType, currentMember } = useOrganization();
	const { upcomingEvents, pastEvents } = useEvents();
	const { currentUser } = useAuth();

	let nowPlusHour = new Date();
	nowPlusHour.setHours(nowPlusHour.getHours() + 1);

	const defaultDetails = {
		about: "",
		active: true,

		address: {
			line1: "",
			city: "",
			state: "",
			zipCode: ""
		},
		createdAt: new Date(),
		updatedAt: new Date(),
		causes: [],
		checkedIn: [],
		creator: currentUser.id,
		date: new Date(),
		endTime: nowPlusHour,
		latLng: "",
		meetingInfo: "",
		mode: "",
		name: "",
		orgId: selectedOrg?.id,
		orgName: selectedOrg?.name,
		orgType: selectedOrg?.type,
		startTime: new Date(),
		type: "event"
	};

	const [showNewEventModal, setShowNewEventModal] = useState(false);
	const [emailError, setEmailError] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const [details, setDetails] = useState(defaultDetails);
	const [selectedCauses, setSelectedCauses] = useState([]);
	const [newEventCoverPhotos, setNewEventCoverPhotos] = useState([]);

	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const [search, setSearch] = useState("");
	const [filtered, setFiltered] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);

	useEffect(() => {
		let temp = [...upcomingEvents, ...pastEvents];
		if (search) {
			temp = temp.filter(
				(item) =>
					item.name.toLowerCase().includes(search.toLowerCase()) ||
					item.address.city.toLowerCase().includes(search.toLowerCase()) ||
					item.address.state.toLowerCase().includes(search.toLowerCase())
			);
		}
		setFiltered(temp);
	}, [search, upcomingEvents, pastEvents]);

	useEffect(() => {
		details.mode &&
		(details.mode === "In-Person"
			? details.address.line1 &&
			  details.address.city &&
			  details.address.state &&
			  details.address.zipCode &&
			  details.latLng
			: details.meetingInfo) &&
		details.name !== "" &&
		details.about !== "" &&
		details.endTime > details.startTime &&
		selectedCauses?.length > 0 &&
		newEventCoverPhotos.length > 0
			? setDisabled(false)
			: setDisabled(true);
	}, [details, newEventCoverPhotos, selectedCauses]);

	useEffect(() => {
		let date = details.date;
		let eventDate = date.getDate();
		let eventMonth = date.getMonth();

		details.startTime.setDate(eventDate);
		details.endTime.setDate(eventDate);
		details.startTime.setMonth(eventMonth);
		details.endTime.setMonth(eventMonth);
	}, [details]);

	useEffect(() => {
		if (details.mode === "Virtual") {
			setDetails({
				...details,
				address: selectedOrg?.address,
				latLng: selectedOrg?.latLng
			});
		}
	}, [details?.mode]);

	const saveEvent = () => {
		setLoading(true);

		details.causes = selectedCauses;
		details.lowercaseName = details.name
			.toLowerCase()
			.replace(/[^a-zA-Z ]/g, "");
		details.skills = [];
		const { lat, lng } = details.latLng;
		const hash = geofire.geohashForLocation([lat, lng]);
		details.hash = hash;
		details.orgType = selectedOrg.type;
		details.orgName = selectedOrg.name;
		details.address.city = details.address.city.toLowerCase();
		details.address.state = details.address.state.toLowerCase();

		if (selectedOrg.type === "nonprofit") details.trendingScore = 0;

		createEvent(details, newEventCoverPhotos, onSuccess);
	};

	function onSuccess(event) {
		setLoading(false);
		setShowNewEventModal(false);
		setDetails(defaultDetails);
	}

	function onDeleteEvent() {
		setLoading(true);
		deleteEvent(showConfirmModal, onSuccessDelete);
	}

	function onSuccessDelete() {
		setShowConfirmModal(null);
		setLoading(false);
	}

	const currentTableData = () => {
		const firstPageIndex = currentPage * rowsPerPage;
		const lastPageIndex = firstPageIndex + rowsPerPage;
		return filtered.slice(firstPageIndex, lastPageIndex);
	};

	return (
		<ShadowContainer style={styles.container}>
			<PrimaryText
				fontSize={32}
				fontWeight={900}
				style={styles.title}
			>
				Events
			</PrimaryText>
			{currentMember?.postNewOpportunities && (
				<Button
					title={"Create New Event"}
					onPress={() => setShowNewEventModal(true)}
					iconRight={
						<FiIcons.FiCalendar
							size={16}
							color={COLORS.primary.white}
						/>
					}
					style={{ marginBottom: 12 }}
				/>
			)}

			<Table
				className="table"
				style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
			>
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell style={{ textAlign: "left" }}>Event Name</TableCell>
						<TableCell>Date</TableCell>
						<TableCell>Location</TableCell>
						<TableCell>Going</TableCell>
						<TableCell>Interested</TableCell>
						{userType === "Leader" && <TableCell></TableCell>}
					</TableRow>
				</TableHead>

				<TableBody>
					{currentTableData() &&
						currentTableData().map((el, i) => {
							return (
								<EventsCard
									item={el}
									key={i}
									deleteEvent={
										userType === "Leader" ? () => setShowConfirmModal(el) : null
									}
								/>
							);
						})}
				</TableBody>
			</Table>

			<MuiTablePagination
				count={filtered?.length}
				rowsPerPage={rowsPerPage}
				page={currentPage}
				setCurrentPage={setCurrentPage}
				onRowsPerPageChange={(e) => {
					setRowsPerPage(e.target.value);
					setCurrentPage(0);
				}}
			/>

			<EventCreationModal
				open={showNewEventModal}
				onClose={() => setShowNewEventModal(false)}
			/>

			{/** NEW EVENT */}
			{/* {showNewEventModal && (
				<FullModal
					title={"New Event"}
					setShowModal={setShowNewEventModal}
				>
					<AddEventInfoForm
						details={details}
						setDetails={setDetails}
						coverPhotos={newEventCoverPhotos}
						setCoverPhotos={setNewEventCoverPhotos}
						address={!details?.virtual}
						selectedCauses={selectedCauses}
						setSelectedCauses={setSelectedCauses}
					/>

					<TouchableOpacity
						style={[
							styles.button,
							disabled && { opacity: 0.4 },
							{ alignSelf: "center" }
						]}
						onPress={() => saveEvent()}
						disabled={disabled}
					>
						<PrimaryText
							fontSize={16}
							fontWeight={800}
							style={{ color: COLORS.primary.white }}
						>
							Create Event
						</PrimaryText>
					</TouchableOpacity>
				</FullModal>
			)} */}

			{!!showConfirmModal && (
				<ConfirmModal
					title={"Delete Event"}
					message={"Are you sure?"}
					onConfirm={onDeleteEvent}
					onCancel={() => setShowConfirmModal(false)}
				/>
			)}

			{loading && (
				<ActivityIndicator
					animating={loading}
					size={"large"}
					style={styles.activity}
					color={COLORS.primary.lightBlue}
				/>
			)}
		</ShadowContainer>
	);
};

const styles = StyleSheet.create({
	activity: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.primary.white,
		position: "absolute",
		top: 0,
		bottom: 0,
		zIndex: 999
	},
	container: {
		width: "100%",
		flex: 1
	},
	button: {
		flexDirection: "row",
		backgroundColor: COLORS.primary.lightBlue,
		// height: 30,
		maxWidth: 200,
		justifyContent: "center",
		alignItems: "center",
		paddingHorizontal: 20,
		paddingVertical: 6.5,
		marginBottom: 12,
		borderRadius: 20
	},
	headerContainer: {
		flexDirection: "row"
	},
	infoContainer: {
		backgroundColor: COLORS.primary.white,
		marginHorizontal: 12,
		borderRadius: 12,
		paddingHorizontal: 20,
		paddingVertical: 12,
		alignItems: "center",
		justifyContent: "center"
	},
	info: {
		alignItems: "center",
		justifyContent: "center",
		marginHorizontal: 24
	},
	title: {
		marginBottom: 12
	}
});
