import React, { useState, createContext, useEffect, useContext } from "react"
import { getOurCauses } from "services/causes-service"
import { getEventDonations, getEvents } from "services/events-service"
import { useCauses } from "./CausesContext"
import { useOrganization } from "./OrganizationContext"

export const EventsContext = createContext()

export const EventsProvider = ({ children }) => {
  const { selectedOrg } = useOrganization()
  const { allCauses } = useCauses()

  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [pastEvents, setPastEvents] = useState([])

  const [selectedEvent, setSelectedEvent] = useState(null)
  const [eventDonations, setEventDonations] = useState([])
  const [causes, setCauses] = useState([])

  useEffect(() => {
    if (!selectedOrg) return
    const sub = getEvents(selectedOrg, setUpcomingEvents, setPastEvents)

    return () => {
      sub && sub()
    }

  }, [selectedOrg])

  useEffect(() => {
    if (!selectedEvent) return
    getOurCauses(selectedEvent, allCauses, setCauses)
    const sub = getEventDonations(selectedEvent, setEventDonations)

    return () => {
      sub && sub()
    }
  }, [selectedEvent])

  useEffect(() => {
    if (!selectedEvent) return;
    const find = upcomingEvents.find((event) => event.id === selectedEvent.id)
    if (find) {
      setSelectedEvent(find)
    } else {
      const find = pastEvents.find((event) => event.id === selectedEvent.id)
      if (find) {
        setSelectedEvent(find)
      }
    }
  }, [upcomingEvents, pastEvents])

  return (
    <EventsContext.Provider
      value={{
        upcomingEvents,
        setUpcomingEvents,
        pastEvents,
        setPastEvents,
        selectedEvent,
        setSelectedEvent,
        causes,
        eventDonations, setEventDonations
      }}
    >
      {children}
    </EventsContext.Provider>
  )
}

export const useEvents = () => useContext(EventsContext)
