import React, { useState, createContext, useEffect, useContext } from "react";
import { useOrganization } from "../OrganizationContext";
import { getStripeCustomerAndPaymentMethods } from "services/stripe-service";
import { updateOrganization } from "services/organizations-service";

export const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const { selectedOrg } = useOrganization();

  const [stripeCustomer, setStripeCustomer] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);

  useEffect(() => {
    setStripeCustomer(null);
    setPaymentMethods([]);
    if (!selectedOrg) return;
    getData()
  }, [selectedOrg]);

  async function getData() {
    if (!selectedOrg?.stripeCustomerId) return;
    const res = await getStripeCustomerAndPaymentMethods(selectedOrg.stripeCustomerId)
    // console.log('testing', res)
    if (!res) return;
    setStripeCustomer(res?.customer ? res?.customer : null);
    // console.log('?', res?.paymentMethods)
    const paymentMethods = res?.paymentMethods?.data ? res?.paymentMethods?.data : [];
    const findIndex = paymentMethods.findIndex(
      (x) => x.id === res?.customer?.invoice_settings?.default_payment_method
    );
    if (findIndex > -1) {
      paymentMethods[findIndex].isDefault = true;
      setDefaultPaymentMethod(paymentMethods[findIndex]);
    }

    setPaymentMethods(paymentMethods);

    updateOrganization(selectedOrg, { stripeComplete: paymentMethods.length > 0 }, [], [], () => { })

  }

  function fetchPaymentMethods() {
    getData();
  }

  return (
    <BusinessContext.Provider
      value={{
        stripeCustomer,
        setStripeCustomer,
        paymentMethods,
        setPaymentMethods,
        getData,
        fetchPaymentMethods,
        defaultPaymentMethod
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

export const useBusiness = () => useContext(BusinessContext);
