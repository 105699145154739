import { Box } from "@mui/material";
import ErrorMessage from "components/Common/ErrorMessage";
import MuiButton from "components/Common/MuiButton";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React, { useRef, useState } from "react";
import ReactCrop, {
	centerCrop,
	convertToPixelCrop,
	makeAspectCrop
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ASPECT_RATIO = 16 / 9;

export default function UploadCoverPhoto({
	coverPhoto,
	onChange,
	errorMessage
}) {
	const imgRef = useRef(null);
	const canvaRef = useRef(null);

	const [imageFile, setImageFile] = useState({ url: coverPhoto });
	const [crop, setCrop] = useState({ x: 0, y: 0 });

	const onLoadImage = (e) => {
		const { width, height } = e.currentTarget;

		const crop = makeAspectCrop(
			{
				unit: "%",
				width: 100
			},
			ASPECT_RATIO,
			width,
			height
		);

		const centeredCrop = centerCrop(crop, width, height);

		setCrop(centeredCrop);

		onCropCompleted(centeredCrop);
	};

	// Make the cropped image ready to upload on Firestore
	const onCropCompleted = async (crop) => {
		const image = imgRef.current;
		const canvas = canvaRef.current;

		image.crossOrigin = "anonymous";

		const ctx = canvas.getContext("2d");

		if (!ctx) {
			throw new Error("No 2d context");
		}

		if (crop.unit === "%")
			crop = convertToPixelCrop(crop, image.width, image.height);

		canvas.width = crop.width;
		canvas.height = crop.height;

		ctx.save();

		// Move the crop origin to the canvas origin (0,0)
		ctx.translate(-crop.x, -crop.y);

		ctx.drawImage(
			image,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
			0,
			0,
			image.width,
			image.height
		);

		ctx.restore();

		console.log({ canvas });

		const dataUrl = await canvas.toDataURL("image/jpeg", 1.0);
		const croppedImage = imageFile;
		croppedImage.dataUrl = dataUrl;
		onChange?.(croppedImage);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			mt={20}
		>
			<PrimaryText
				fontSize={16}
				fontWeight={900}
				color={COLORS.primary.darkBlue}
			>
				Cover Photo <span style={{ color: COLORS.primary.error }}>*</span>
			</PrimaryText>

			<PrimaryText
				fontSize={12}
				fontWeight={300}
				color={COLORS.primary.neutral_800}
				style={{ marginTop: 12, marginBottom: 8 }}
			>
				We recommend a 1200x900 px image. PNG and JPG are supported.
			</PrimaryText>

			<MuiButton
				title="Upload Cover Image"
				variant="contained"
				sx={{
					height: 30,
					fontSize: 14,
					fontWeight: 700,
					width: 190,
					paddingX: 0
				}}
				type="file"
				onChange={setImageFile}
			/>

			{imageFile?.url && (
				<>
					<ReactCrop
						crop={crop}
						onChange={(_, percentCrop) => setCrop(percentCrop)}
						aspect={ASPECT_RATIO}
						minHeight={100}
						style={{ marginTop: 20 }}
						onComplete={(pixelCrop) => onCropCompleted(pixelCrop)}
					>
						<img
							src={imageFile.url}
							alt="Event Cover"
							onLoad={onLoadImage}
							width="100%"
							ref={imgRef}
						/>
					</ReactCrop>
					<div>
						<canvas
							ref={canvaRef}
							style={{
								display: "none"
							}}
						/>
					</div>
				</>
			)}

			<ErrorMessage
				errorMessage={errorMessage}
				marginTop={12}
			/>
		</Box>
	);
}
