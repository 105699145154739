import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  Box,
  IconButton,
} from "@mui/material";
import { View } from "react-native-web";
import { COLORS } from "constants/Colors";
import { Button } from "components/Common/Button";
import { FaTrash, FaTrashAlt } from "react-icons/fa";
import { FiTrash, FiTrash2 } from "react-icons/fi";

export function EditPaymentOptionModal(props) {
  const { 
    isVisible, 
    setIsVisible, 
    title,
    onDefault,
    onRemove,
    isDefault
  } = props;



  return (
    <Modal open={isVisible} >
      <Box sx={style.modalBox}>
        <PrimaryText fontSize={16} fontWeight={400}>
          {title}
        </PrimaryText>

        <IconButton
          sx={style.close}
          onClick={() => setIsVisible(false)}
        >
          <img src={require("assets/misc/close.png")} />
        </IconButton>


        <View style={{flex: 1, width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
          <Button
            title={isDefault ? "Already Default Payment Method" : "Make Default"}
            style={{
              width: '100%',
              marginVertical: 18,
              marginTop: 30,
              text: {
                fontSize: 12,
                fontWeight: 700,
                color: COLORS.primary.white
              },
              opacity: isDefault ? 0.5 : 1,
            }}
            onPress={onDefault}
            disabled={isDefault}
          />
          <Button
            title={"Remove Payment Method"}
            style={{
              backgroundColor: COLORS.primary.transparent,
              width: '100%',
              marginBottom: 32,
              borderColor: COLORS.primary.error,
              borderWidth: 1,
              // paddingVertical: 4,
              text: {
                fontSize: 12,
                fontWeight: 700,
                color: COLORS.primary.error
              },
              // opacity: donateDisabled ? 0.5 : 1,
            }}
          onPress={onRemove}
          iconRight={<FiTrash2 size={20} color={COLORS.primary.error} />}
          // disabled={donateDisabled}
          />
        </View>

      </Box>
    </Modal >
  );
}

const style = {
  modalBox: {
    minWidth: "20vw",
    maxWidth: 400,
    // minHeight: "20vh",
    bgcolor: "offwhite.main",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "25px",
    pt: 35,
    paddingX: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 32,
  },
  tabBox: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: 20,
  },
  tabs: {
    borderBottom: 1,
    borderColor: "gray.lighter",
    width: "100%",
    // marginTop: 30,
  },
  tabItem: ({ width, isActive }) => ({
    textTransform: "none",
    fontSize: 16,
    fontWeight: isActive ? 700 : 400,
    fontFamily: "Montserrat",
    width,
    "&:hover": {
      backgroundColor: "neutral.200",
    },
  }),
  close: { position: "absolute", right: 56, top: 23 },
  cardContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  editBtn: { paddingBottom: 20, marginLeft: 12 },
};
