import { COLORS } from "constants/Colors"
import React from "react"
import { StyleSheet, View } from "react-native"

export function ShadowContainer(props) {
  const { style = {}, children } = props
  return (
    <View style={[styles.container, style]}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.primary.offWhite,
    padding: 20,
    borderRadius: 12,
    
    shadowColor: "rgb(128,128,128)",
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.15,
    shadowRadius: 15,
  },
})
