import { POSITIONS, ROLES } from "constants/Data";

export const unique = (arr) => {
	return [...new Map(arr.map((v) => [v?.id, v])).values()];
};

export const validateUrl = (string) => {
	var res = string.match(
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
	);
	if (res !== null) return null;
	else return "Enter a valid url";
};

export const getTimeDifference = (dateTime, end) => {
	if (!dateTime) {
		return { value: -1, suffix: "" };
	}
	var date = dateTime.getTime();
	let diff = date - end.getTime();
	var msec = diff;

	let percentageDifference = (diff / date) * 100;
	if (msec < 0) {
		return { value: 0, suffix: "" };
	}
	var years = Math.floor(msec / 1000 / 60 / (60 * 24 * 365));
	msec -= years * 1000 * 60 * 60 * 24 * 365;
	var days = Math.floor(msec / 1000 / 60 / (60 * 24));
	msec -= days * 1000 * 60 * 60 * 24;
	var hh = Math.floor(msec / 1000 / 60 / 60);
	msec -= hh * 1000 * 60 * 60;
	var mm = Math.floor(msec / 1000 / 60);
	msec -= mm * 1000 * 60;
	var ss = Math.floor(msec / 1000);
	msec -= ss * 1000;

	if (years > 0) {
		return { value: years, suffix: "y", percentageDifference };
	} else if (days > 0) {
		return { value: days, suffix: "d", percentageDifference };
	} else if (hh > 0) {
		return { value: hh, suffix: "h", percentageDifference };
	} else if (mm > 0) {
		return { value: mm, suffix: "m", percentageDifference };
	}

	return { value: 0, suffix: "m", percentageDifference: 100 };
};

export const getUsersName = (user) => {
	return user?.firstName + " " + user?.lastName;
};

export function validateEmail(email) {
	var re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export const validatePhone = (phone) => {
	const regex = /^\+?[1-9][0-9]{7,14}$/;
	return regex.test(phone);
};

export const validatePassword = (password) => {
	//check if password has at least 6 characters and contains at least one number and one capital letter
	
	let valid = ((password?.length >= 6) && /[A-Z]/.test(password) && /[0-9]/.test(password));
	return valid
	
}

export const validateConfirmPassword = (password, confirmPassword) =>
	password?.length >= 6 &&
	confirmPassword?.length >= 6 &&
	password === confirmPassword;

export function validateEmailDoesntExist(email, members) {
	return !!members.find((x) => x.email === email);
}

export var dollarFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD"
});

export const getRole = (user) => {
	const found = ROLES.find((x) => x.value === user?.role);
	if (!found) return { label: "" };
	return {
		value: found.value,
		label: found.label
	};
};

export const getPosition = (user) => {
	if (!user?.position) return { label: "" };
	const found = POSITIONS.find((x) => x.value === user?.position);
	return {
		value: found.value,
		label: found.label
	};
};

export const getOrg = (orgs, selectedOrg) => {
	const found = orgs.find((x) => x.value === selectedOrg?.id);
	return { ...found };
};

export const getCityStateZip = (item) => {
	let { city, state, zipCode } = item?.address || {};
	return capitalize(city) + ", " + capitalize(state) + ", " + zipCode;
};

export const getFullLocation = (item) => {
	let { city, state, zipCode, line1 } = item?.address || {};

	return `${line1 ? capitalize(line1) + ", " : ""}${capitalize(
		city
	)}, ${capitalize(state)} ${zipCode || ""}`;
};

function capitalize(text) {
	if (!text) return text;
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export function getMimeTypeFromBase64(base64URL) {
	return base64URL.substring("data:".length, base64URL.indexOf(";"));
}

export const canReceiveDonations = (org) => {
	return ((org?.verified || org?.cot) && org?.stripeComplete)
};

export const getIntegerFromNumber = (number) => {
	return Math.trunc(number).toLocaleString();
}

export const getDecimalFromNumber = (number) => {
	return ("." + number.toFixed(2).toString().split(".")[1])
}
